var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { class: { "page-compact": _vm.crud.pageOptions.compact } },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c(
            "el-button-group",
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.addRow }
                },
                [_c("i", { staticClass: "el-icon-plus" }), _vm._v(" 新增")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "d2-crud-x",
        _vm._g(
          _vm._b(
            {
              ref: "d2Crud",
              attrs: { data: _vm.data },
              scopedSlots: _vm._u([
                {
                  key: "avatarSlot",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-image",
                        {
                          staticStyle: { height: "50px", width: "50px" },
                          attrs: {
                            src: _vm._f("qiniu")(scope.row.avatar),
                            fit: "contain",
                            "preview-src-list": [
                              _vm.convertHttp(scope.row.avatar)
                            ]
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                height: "50px",
                                width: "50px",
                                "background-color": "#f5f7fa",
                                "font-size": "11px",
                                color: "#b5b5b5",
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "center",
                                padding: "0 1px 0 1px"
                              },
                              attrs: { slot: "error" },
                              slot: "error"
                            },
                            [_vm._v(" 加载失败 ")]
                          )
                        ]
                      )
                    ]
                  }
                }
              ])
            },
            "d2-crud-x",
            _vm._crudProps,
            false
          ),
          _vm._crudListeners
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }