var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("点个赞设置")]),
      _c("div", { staticClass: "title" }, [_vm._v("下单有效时长设置")]),
      _c(
        "div",
        { staticClass: "row-box" },
        [
          _c("span", { staticClass: "label" }, [
            _c("span", { staticClass: "flag" }, [_vm._v("*")]),
            _vm._v("下单有效时长:")
          ]),
          _c("el-input", {
            staticClass: "input-self",
            attrs: {
              maxlength: "6",
              placeholder: "输入集赞成功后可下单时长（小时）"
            },
            on: {
              input: function($event) {
                return _vm.numberInput($event, "give_like_buy_expire_hours")
              }
            },
            model: {
              value: _vm.params.give_like_buy_expire_hours,
              callback: function($$v) {
                _vm.$set(_vm.params, "give_like_buy_expire_hours", $$v)
              },
              expression: "params.give_like_buy_expire_hours"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "title" }, [_vm._v("点个赞资格次数获取设置")]),
      _c(
        "div",
        { staticClass: "row-box" },
        [
          _c("span", { staticClass: "label" }, [
            _c("span", { staticClass: "flag" }, [_vm._v("*")]),
            _vm._v("注册平台用户:")
          ]),
          _c("el-input", {
            staticClass: "input-self",
            attrs: { placeholder: "输入赠送点赞次数", maxlength: "6" },
            on: {
              input: function($event) {
                return _vm.numberInput($event, "give_like_user_register_count")
              }
            },
            model: {
              value: _vm.params.give_like_user_register_count,
              callback: function($$v) {
                _vm.$set(_vm.params, "give_like_user_register_count", $$v)
              },
              expression: "params.give_like_user_register_count"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row-box" },
        [
          _c("span", { staticClass: "label" }, [
            _c("span", { staticClass: "flag" }, [_vm._v("*")]),
            _vm._v("拉新用户:")
          ]),
          _c("el-input", {
            staticClass: "input-self",
            attrs: { placeholder: "输入赠送点赞次数", maxlength: "6" },
            on: {
              input: function($event) {
                return _vm.numberInput($event, "give_like_user_pull_new_count")
              }
            },
            model: {
              value: _vm.params.give_like_user_pull_new_count,
              callback: function($$v) {
                _vm.$set(_vm.params, "give_like_user_pull_new_count", $$v)
              },
              expression: "params.give_like_user_pull_new_count"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row-box" },
        [
          _c("span", { staticClass: "label" }, [
            _c("span", { staticClass: "flag" }, [_vm._v("*")]),
            _vm._v("商城购物:")
          ]),
          _c("el-input", {
            staticClass: "input-self",
            attrs: { placeholder: "输入赠送点赞次数", maxlength: "6" },
            on: {
              input: function($event) {
                return _vm.numberInput($event, "give_like_mall_buy_count")
              }
            },
            model: {
              value: _vm.params.give_like_mall_buy_count,
              callback: function($$v) {
                _vm.$set(_vm.params, "give_like_mall_buy_count", $$v)
              },
              expression: "params.give_like_mall_buy_count"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row-box" },
        [
          _c("span", { staticClass: "label" }, [
            _c("span", { staticClass: "flag" }, [_vm._v("*")]),
            _vm._v("每日登陆送:")
          ]),
          _c("el-input", {
            staticClass: "input-self",
            attrs: { placeholder: "输入赠送点赞次数", maxlength: "6" },
            on: {
              input: function($event) {
                return _vm.numberInput($event, "give_like_daily_login_count")
              }
            },
            model: {
              value: _vm.params.give_like_daily_login_count,
              callback: function($$v) {
                _vm.$set(_vm.params, "give_like_daily_login_count", $$v)
              },
              expression: "params.give_like_daily_login_count"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "title" }, [_vm._v("提升等级赠送资格次数设置")]),
      _c(
        "div",
        { staticClass: "row-box" },
        [
          _c("span", { staticClass: "label" }, [
            _c("span", { staticClass: "flag" }, [_vm._v("*")]),
            _vm._v("升级到七星:")
          ]),
          _c("el-input", {
            staticClass: "input-self",
            attrs: { placeholder: "输入赠送点赞次数", maxlength: "6" },
            on: {
              input: function($event) {
                return _vm.numberInput($event, "give_like_up_mall_4_count")
              }
            },
            model: {
              value: _vm.params.give_like_up_mall_4_count,
              callback: function($$v) {
                _vm.$set(_vm.params, "give_like_up_mall_4_count", $$v)
              },
              expression: "params.give_like_up_mall_4_count"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row-box" },
        [
          _c("span", { staticClass: "label" }, [
            _c("span", { staticClass: "flag" }, [_vm._v("*")]),
            _vm._v("升级到五星:")
          ]),
          _c("el-input", {
            staticClass: "input-self",
            attrs: { maxlength: "6", placeholder: "输入赠送点赞次数" },
            on: {
              input: function($event) {
                return _vm.numberInput($event, "give_like_up_mall_3_count")
              }
            },
            model: {
              value: _vm.params.give_like_up_mall_3_count,
              callback: function($$v) {
                _vm.$set(_vm.params, "give_like_up_mall_3_count", $$v)
              },
              expression: "params.give_like_up_mall_3_count"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row-box" },
        [
          _c("span", { staticClass: "label" }, [
            _c("span", { staticClass: "flag" }, [_vm._v("*")]),
            _vm._v("升级到三星:")
          ]),
          _c("el-input", {
            staticClass: "input-self",
            attrs: { placeholder: "输入赠送点赞次数", maxlength: "6" },
            on: {
              input: function($event) {
                return _vm.numberInput($event, "give_like_up_mall_2_count")
              }
            },
            model: {
              value: _vm.params.give_like_up_mall_2_count,
              callback: function($$v) {
                _vm.$set(_vm.params, "give_like_up_mall_2_count", $$v)
              },
              expression: "params.give_like_up_mall_2_count"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row-box" },
        [
          _c("span", { staticClass: "label" }, [
            _c("span", { staticClass: "flag" }, [_vm._v("*")]),
            _vm._v("升级到会员:")
          ]),
          _c("el-input", {
            staticClass: "input-self",
            attrs: { placeholder: "输入赠送点赞次数", maxlength: "6" },
            on: {
              input: function($event) {
                return _vm.numberInput($event, "give_like_up_mall_1_count")
              }
            },
            model: {
              value: _vm.params.give_like_up_mall_1_count,
              callback: function($$v) {
                _vm.$set(_vm.params, "give_like_up_mall_1_count", $$v)
              },
              expression: "params.give_like_up_mall_1_count"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "title" }, [_vm._v("点个赞全局设置")]),
      _c(
        "div",
        { staticClass: "row-box" },
        [
          _c(
            "span",
            { staticClass: "label", staticStyle: { width: "156px" } },
            [
              _c("span", { staticClass: "flag" }, [_vm._v("*")]),
              _vm._v("点赞赠送优惠券礼包:")
            ]
          ),
          _c(
            "el-select",
            {
              staticClass: "input-self",
              attrs: { placeholder: "请选择" },
              model: {
                value: _vm.params.give_like_gift_id,
                callback: function($$v) {
                  _vm.$set(_vm.params, "give_like_gift_id", $$v)
                },
                expression: "params.give_like_gift_id"
              }
            },
            _vm._l(_vm.giftList, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.title, value: item.id }
              })
            }),
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "row-box" }, [
        _c("span", { staticClass: "label" }, [
          _c("span", { staticClass: "flag" }, [_vm._v("*")]),
          _vm._v("集赞减免:")
        ]),
        _c(
          "div",
          { staticStyle: { width: "600px" } },
          [
            _c(
              "el-table",
              {
                staticClass: "margin-top-20",
                staticStyle: {
                  "border-left": "1px solid #EBEEF5",
                  margin: "0 30px"
                },
                attrs: {
                  data: _vm.levelReduceData,
                  border: "",
                  "header-cell-style": { textAlign: "center" },
                  "cell-style": { textAlign: "center" }
                }
              },
              _vm._l(_vm.columnArr, function(val, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: { prop: val.prop, label: val.label },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scopes) {
                          return [
                            _c(
                              "div",
                              { staticClass: "table-input-box" },
                              [
                                _c("el-input", {
                                  attrs: { maxlength: "2", size: "small" },
                                  on: {
                                    input: function($event) {
                                      return _vm.tableNumberInput(
                                        $event,
                                        scopes
                                      )
                                    }
                                  },
                                  model: {
                                    value: scopes.row[val.prop],
                                    callback: function($$v) {
                                      _vm.$set(scopes.row, val.prop, $$v)
                                    },
                                    expression: "scopes.row[val.prop]"
                                  }
                                }),
                                _c("span", [_vm._v("%")])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              }),
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "row-box" },
        [
          _c("span", { staticClass: "label" }, [
            _c("span", { staticClass: "flag" }, [_vm._v("*")]),
            _vm._v("分享标题:")
          ]),
          _c("el-input", {
            staticClass: "input-self",
            attrs: {
              maxlength: "20",
              "show-word-limit": "",
              placeholder: "请输入分享标题（20字以内）",
              clearable: ""
            },
            model: {
              value: _vm.params.give_like_share_title,
              callback: function($$v) {
                _vm.$set(_vm.params, "give_like_share_title", $$v)
              },
              expression: "params.give_like_share_title"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row-box" },
        [
          _c("span", { staticClass: "label" }, [
            _c("span", { staticClass: "flag" }, [_vm._v("*")]),
            _vm._v("分享简介:")
          ]),
          _c("el-input", {
            staticClass: "input-self",
            attrs: {
              clearable: "",
              rows: 5,
              type: "textarea",
              placeholder: "请输入分享简介（50字以内）",
              maxlength: "50",
              "show-word-limit": ""
            },
            model: {
              value: _vm.params.give_like_share_brief,
              callback: function($$v) {
                _vm.$set(_vm.params, "give_like_share_brief", $$v)
              },
              expression: "params.give_like_share_brief"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row-box" },
        [
          _c("span", { staticClass: "label" }, [
            _c("span", { staticClass: "flag" }, [_vm._v("*")]),
            _vm._v("分享图:")
          ]),
          !_vm.params.give_like_share_image
            ? _c(
                "el-upload",
                {
                  staticClass: "upload-btn",
                  attrs: {
                    action: _vm.QINIUURL,
                    data: _vm.dataToken,
                    "show-file-list": false,
                    "on-success": _vm.uploadProImgSuccess,
                    "before-upload": _vm.beforeUpload
                  }
                },
                [_c("i", { staticClass: "el-icon-plus color-666" })]
              )
            : _c(
                "div",
                { staticClass: "image-box" },
                [
                  _c("el-image", {
                    staticClass: "image-self",
                    attrs: {
                      src: _vm._f("qiniu")(_vm.params.give_like_share_image),
                      fit: "contain"
                    }
                  }),
                  _c("i", {
                    staticClass: "el-icon-error color-666 del-icon",
                    on: { click: _vm.delImage }
                  })
                ],
                1
              )
        ],
        1
      ),
      _c(
        "el-button",
        {
          staticClass: "margin-left-140",
          attrs: { type: "primary" },
          on: { click: _vm.save }
        },
        [_vm._v("保存")]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }