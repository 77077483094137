import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { LIMIT_STATUS } from '../dict';
export default {
  data: function data() {
    var _this = this;

    var checkNum = function checkNum(rule, value, callback) {
      if (value && value >= 1000000) {
        return callback(new Error('规格数量最多不能超过100万'));
      } else {
        callback();
      }
    };

    var checkDepositMore = function checkDepositMore(rule, value, callback) {
      if (value && value >= 1000000) {
        return callback(new Error('保证金最多不能超过100万'));
      } else {
        callback();
      }
    };

    var checkSkuNum = function checkSkuNum(rule, value, callback) {
      if (value && _this.editForm.store && Number(_this.editForm.store) < Number(value)) {
        return callback(new Error('规格数量不能大于库存数量'));
      } else {
        callback();
      }
    };

    var checkStore = function checkStore(rule, value, callback) {
      if (value && _this.editForm.number && Number(_this.editForm.number) > Number(value)) {
        return callback(new Error('库存数量不能小于规格数量'));
      } else {
        callback();
      }
    };

    var checkDeposit = function checkDeposit(rule, value, callback) {
      if (Number(value) === 0) {
        return callback(new Error('需要保证金的情况下保证金不能为0'));
      } else {
        callback();
      }
    };

    return {
      dialogVisible: false,
      dataArr: [],
      permissionArr: LIMIT_STATUS,
      levelStatus: [{
        value: 1,
        label: '高等级'
      }, {
        value: 0,
        label: '低等级'
      }],
      editForm: {
        id: null,
        name: '',
        number: null,
        is_deposit: true,
        deposit: null,
        store: null,
        isCollapse: false,
        is_high: 1,
        high_profit: 0,
        profit: 0,
        renewal_count: null,
        permission: [],
        sku_permission_text: [],
        shelf: 1
      },
      editIndex: null,
      moneyDisabled: false,
      isAdd: true,
      checkDepositObj: {
        validator: checkDeposit,
        trigger: 'blur'
      },
      rules: {
        name: [{
          required: true,
          message: '请输入规格名称',
          trigger: 'blur'
        }, {
          min: 1,
          max: 12,
          message: '长度在 1 到 12 个字符',
          trigger: 'blur'
        }],
        number: [{
          required: true,
          message: '请输入规格数量',
          trigger: 'blur'
        }, {
          validator: checkNum,
          trigger: 'blur'
        }, {
          validator: checkSkuNum,
          trigger: 'blur'
        }],
        deposit: [{
          required: true,
          message: '请输入保证金',
          trigger: 'blur'
        }, {
          validator: checkDeposit,
          trigger: 'blur'
        }, {
          validator: checkDepositMore,
          trigger: 'blur'
        }],
        store: [{
          required: true,
          message: '请输入总库存',
          trigger: 'blur'
        }, {
          validator: checkStore,
          trigger: 'blur'
        }],
        is_high: [{
          required: true,
          message: '请选择规格等级',
          trigger: 'change'
        }],
        renewal_count: [{
          required: true,
          message: '请输入续期数量',
          trigger: 'blur'
        }],
        permission: [{
          required: true,
          message: '请选择规格权限',
          trigger: 'change'
        }]
      }
    };
  },
  props: {
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    canEdit: {
      type: Boolean,
      default: true
    }
  },
  watch: {},
  methods: {
    toUp: function toUp(e, index) {
      console.log(e); // this.$confirm(`当前规格处于${val === 0 ? '上架' : '下架'}状态，确定要${val === 0 ? '下架' : '上架'}吗？`, '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {

      this.dataArr[index].shelf = e;
      this.$emit('updateUp', index, e); //   this.$message({
      //     type: 'success',
      //     message: '操作成功!'
      //   })
      // }).catch(() => {
      // })
    },
    edit: function edit(item, index) {
      this.editForm = JSON.parse(JSON.stringify(item));
      this.editIndex = index;
      this.isAdd = false;
      this.dialogVisible = true;
      this.moneyDisabled = !this.editForm.is_deposit;
    },
    del: function del(index) {
      var _this2 = this;

      this.$confirm('确定删除此规格吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.dataArr.splice(index, 1);

        _this2.$emit('getSkuArr', _this2.dataArr);

        _this2.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(function () {});
    },
    addSku: function addSku(type) {
      if (type === 2) {
        this.$message('发布成功后是不能进行规格添加操作的~');
        return;
      }

      this.isAdd = true;
      this.dialogVisible = true;
    },
    switchChange: function switchChange(val) {
      if (!val) {
        this.moneyDisabled = true;
        this.editForm.deposit = '免保证金';
        delete this.rules.deposit;
        this.rules.deposit = [];
      } else {
        this.moneyDisabled = false;
        this.editForm.deposit = null;
        this.rules.deposit = [{
          required: true,
          message: '请输入保证金',
          trigger: 'blur'
        }, this.checkDepositObj];
      }
    },
    submitForm: function submitForm(formName) {
      var _this3 = this;

      this.$refs[formName].validate(function (valid) {
        var obj = JSON.parse(JSON.stringify(_this3.editForm));

        if (valid) {
          obj.sku_permission_text = [];
          obj.permission.forEach(function (el) {
            var item = _this3.permissionArr.find(function (val) {
              return val.id === el;
            });

            if (item) {
              obj.sku_permission_text.push(item.name);
            }
          }); // console.log(obj)

          if (_this3.isAdd) {
            _this3.dataArr.push(obj);
          } else {
            _this3.dataArr[_this3.editIndex] = obj;
          }

          var arr = JSON.parse(JSON.stringify(_this3.dataArr));
          arr.forEach(function (val) {
            if (!val.is_deposit) {
              val.deposit = 0;
              val.is_deposit = 0;
            } else {
              val.is_deposit = 1;
            }

            var keys = Object.keys(val);
            if (keys.includes('isCollapse')) delete val.isCollapse;
            if (keys.includes('sku_permission_text')) delete val.sku_permission_text; // if (keys.includes('isDeposit')) delete val.isDeposit

            if (keys.includes('id') && _this3.canEdit) delete val.id; // console.log(val)

            if (!_this3.canEdit && !val.id) val.id = 0; // console.log(val)
          }); // console.log(arr)

          _this3.$emit('getSkuArr', arr);

          _this3.$message({
            type: 'success',
            message: '操作成功!'
          });

          _this3.dialogVisible = false;
        } else {
          return false;
        }
      });
    },
    close: function close() {
      this.editForm = {
        name: '',
        number: null,
        is_deposit: true,
        deposit: null,
        store: null,
        isCollapse: false,
        is_high: 1,
        profit: 0,
        high_profit: 0,
        permission: [],
        sku_permission_text: [],
        shelf: 1
      };
      this.moneyDisabled = false;
      this.rules.deposit = [{
        required: true,
        message: '请输入保证金',
        trigger: 'blur'
      }, this.checkDepositObj];
    },
    toCollapse: function toCollapse(index) {
      var item = this.dataArr[index];
      item.isCollapse = !item.isCollapse;
      this.dataArr.splice(index, item);
    },
    depositInput: function depositInput(e) {
      if (/^(0|[0-9][0-9]*|(^([0-9][0-9]*)\.)|(^([0-9][0-9]*)\.[0-9]{1,2}))?$/.test(e)) {
        this.editForm.deposit = e >= 1 ? e.replace(/\b(0+)/gi, '') : e;
      } else {
        this.editForm.deposit = '';
      }
    },
    numberInput: function numberInput(e) {
      if (/[^\d]/g.test(e)) {
        this.editForm.number = '';
      } else {
        this.editForm.number = e;
      }
    },
    storeInput: function storeInput(e) {
      if (/[^\d]/g.test(e)) {
        this.editForm.store = '';
      } else {
        this.editForm.store = e;
      }
    },
    renewalNumInput: function renewalNumInput(e) {
      if (/[^\d]/g.test(e)) {
        this.editForm.renewal_count = '';
      } else {
        this.editForm.renewal_count = e;
      }
    },
    open: function open() {
      if (!this.canEdit) {
        this.rules.deposit = [];
      }
    }
  },
  mounted: function mounted() {
    var _this4 = this;

    // console.log('执行了mounted', this.canEdit, this.value)
    if (!this.value || !this.value.length) return;
    var jsonArr = JSON.parse(JSON.stringify(this.value));
    this.dataArr = jsonArr.map(function (val) {
      var _val$permission;

      val.isCollapse = true;
      val.is_deposit = val.is_deposit !== 0;
      val.sku_permission_text = [];

      if ((_val$permission = val.permission) !== null && _val$permission !== void 0 && _val$permission.length) {
        val.permission.forEach(function (el) {
          var item = _this4.permissionArr.find(function (val) {
            return val.id === el;
          });

          if (item) {
            val.sku_permission_text.push(item.name);
          }
        });
      } else {
        val.permission = [];
      }

      return val;
    }); // console.log('this.dataArr', this.dataArr)
  }
};