import { request } from '@/api/service';
var prefix = '/svc/marketing-svc';
export default {
  getCouponList: function getCouponList(params) {
    return request({
      url: "".concat(prefix, "/admin/v1/regress/coupon"),
      method: 'get',
      params: params
    });
  },
  addCoupon: function addCoupon(data) {
    return request({
      url: "".concat(prefix, "/admin/v1/regress/coupon"),
      method: 'post',
      data: data
    });
  },
  deleteCoupon: function deleteCoupon(id) {
    return request({
      url: "".concat(prefix, "/admin/v1/regress/coupon?id=").concat(id),
      method: 'delete'
    });
  },
  updateCoupon: function updateCoupon(id, data) {
    return request({
      url: "".concat(prefix, "/admin/v1/regress/coupon?id=").concat(id),
      method: 'put',
      data: data
    });
  },
  updateCouponStatus: function updateCouponStatus(data) {
    return request({
      url: "".concat(prefix, "/admin/v1/regress/coupon"),
      method: 'put',
      data: data
    });
  },
  // 获取商品分类
  getGoodsCategoryList: function getGoodsCategoryList(params) {
    return request({
      url: "".concat(prefix, "/admin/v1/regress/goods-category/list"),
      method: 'get',
      params: params
    });
  },
  // 获取优惠券分类
  getCouponCategoryList: function getCouponCategoryList(params) {
    return request({
      url: "".concat(prefix, "/admin/v1/regress/coupon-category"),
      method: 'get',
      params: params
    });
  },
  // 搜索商品列表
  getGoodsList: function getGoodsList(params) {
    return request({
      url: "".concat(prefix, "/admin/v1/goods/search"),
      method: 'get',
      params: params
    });
  },
  getAllSpecialClass: function getAllSpecialClass() {
    return request({
      url: '/svc/marketing-svc/admin/v1/regress/coupon-page/all',
      method: 'get'
    });
  }
};