import util from '@/libs/util';
export var crudOptions = function crudOptions(vm) {
  return {
    options: {
      height: '100%',
      // 表格高度100%,使用toolbar必须设置
      rowKey: 'id',
      stripe: true
    },
    viewOptions: {
      disabled: false
    },
    rowHandle: false,
    columns: [{
      title: '用户ID',
      key: 'user_id',
      width: '100',
      type: 'el-input',
      align: 'center',
      search: {
        disabled: false
      }
    }, {
      title: '活动ID',
      key: 'id',
      width: '100',
      align: 'center',
      type: 'el-input',
      search: {
        disabled: false
      }
    }, {
      title: '活动名称',
      key: 'title',
      type: 'el-input',
      align: 'center',
      search: {
        disabled: false
      }
    }, {
      title: '活动时间',
      key: 'activity_time',
      type: 'datetimerange',
      width: 300,
      align: 'center',
      search: {
        width: 400,
        disabled: true
      },
      valueBuilder: function valueBuilder(row, key) {
        row.activity_time = [row.start_at, row.end_at];
      }
    }, {
      title: '回归礼包领取时间',
      key: 'gift_get_time',
      type: 'datetime',
      align: 'center',
      search: {
        disabled: true
      },
      valueBuilder: function valueBuilder(row, key) {
        row.gift_get_time = row.created_at;
      }
    }, {
      title: '邀请人ID',
      key: 'invite_user_id',
      type: 'el-input',
      width: '100',
      align: 'center',
      search: {
        disabled: false
      }
    }, {
      title: '创建时间',
      key: 'created_at',
      type: 'datetimerange',
      align: 'center',
      search: {
        width: 400,
        disabled: false,
        component: {
          props: {
            'time-arrow-control': true,
            'default-time': ['00:00:00', '00:00:00'] // 'picker-options': { shortcuts: ShortCUTS }

          }
        }
      },
      valueResolve: function valueResolve(row, key) {
        var _row$created_at;

        if (((_row$created_at = row.created_at) === null || _row$created_at === void 0 ? void 0 : _row$created_at.length) > 1) {
          row.created_start = util.formatDate(row.created_at[0]);
          row.created_end = util.formatDate(row.created_at[1]);
        }
      }
    }]
  };
};