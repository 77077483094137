// 状态字典
export var DICT_STATUS = [{
  value: 1,
  label: '启用'
}, {
  value: 0,
  label: '禁用'
}];
export var DICT_TYPE = [{
  value: 1,
  label: '上架'
}, {
  value: 0,
  label: '下架'
}];