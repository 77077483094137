import { request } from '@/api/service';
export default {
  // 运营状态获取素材分类列表
  GetRunCategoryList: function GetRunCategoryList(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/pink-circle-category/category',
      method: 'get'
    });
  },
  // 运营状态数据获取
  GetRunStatusData: function GetRunStatusData(params) {
    return request({
      url: '/svc/marketing-svc/admin/v1/state-diagram/index',
      method: 'get',
      params: params
    });
  }
};