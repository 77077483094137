var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "商品集赞列表",
            visible: _vm.isShowDialog,
            center: true,
            width: "80%"
          },
          on: {
            "update:visible": function($event) {
              _vm.isShowDialog = $event
            },
            close: _vm.close
          }
        },
        [
          _c(
            "div",
            { staticClass: "button-box" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    icon: "el-icon-upload2"
                  },
                  on: { click: _vm.exportData }
                },
                [_vm._v("导出")]
              )
            ],
            1
          ),
          _c("d2-crud-x", {
            attrs: {
              columns: _vm.columnArr,
              data: _vm.data,
              loading: _vm.loading,
              pagination: _vm.pagination,
              options: _vm.options,
              rowHandle: _vm.rowHandle
            },
            on: {
              "custom-detail": _vm.toLookDetail,
              "pagination-current-change": _vm.paginationCurrentChange
            }
          })
        ],
        1
      ),
      _c("detailDialog", {
        attrs: {
          detailInfo: _vm.detailInfo,
          detailDialogShow: _vm.isShowDetailDialog
        },
        on: { closeDetailDialog: _vm.closeDetailDialog }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }