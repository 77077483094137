var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { class: { "page-compact": _vm.crud.pageOptions.compact } },
    [
      _c(
        "d2-crud-x",
        _vm._g(
          _vm._b(
            {
              ref: "d2Crud",
              scopedSlots: _vm._u([
                {
                  key: "attributeSlot",
                  fn: function(scope) {
                    return _vm._l(scope.row["attribute"], function(val, index) {
                      return _c(
                        "span",
                        {
                          key: index,
                          staticClass: "attribute-tag",
                          class: {
                            "danger-tag": val.is_high === 1,
                            "info-tag": val.is_high === 0
                          }
                        },
                        [_vm._v(_vm._s(val.name))]
                      )
                    })
                  }
                },
                {
                  key: "created_atSlot",
                  fn: function(scope) {
                    return [
                      _c("span", [_vm._v(_vm._s(scope.row["created_at"]))])
                    ]
                  }
                },
                {
                  key: "attributeFormSlot",
                  fn: function(scope) {
                    return [
                      _c(
                        "div",
                        [
                          _c("sku-setting", {
                            attrs: {
                              value: scope.row.attribute,
                              canEdit: scope.mode !== "edit"
                            },
                            on: {
                              updateUp: _vm.updateUp,
                              getSkuArr: _vm.getSkuArr
                            }
                          })
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "goodsFormSlot",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-form",
                        { ref: "form", attrs: { model: scope.form } },
                        _vm._l(scope.form.goods, function(item, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "every-form-item",
                                  attrs: {
                                    rules: {
                                      required: true,
                                      message: "请输入商品ID",
                                      trigger: "blur"
                                    },
                                    prop: "goods." + index + ".goods_id"
                                  }
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "d2-mb-5",
                                      attrs: {
                                        disabled:
                                          scope.mode === "edit" &&
                                          !item.is_edit,
                                        placeholder: "输入商品ID(除名媛外)"
                                      },
                                      on: {
                                        blur: function($event) {
                                          return _vm.goodInputBlur(
                                            item.goods_id,
                                            index
                                          )
                                        }
                                      },
                                      model: {
                                        value: item.goods_id,
                                        callback: function($$v) {
                                          _vm.$set(item, "goods_id", $$v)
                                        },
                                        expression: "item.goods_id"
                                      }
                                    },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          slot: "append",
                                          disabled:
                                            scope.mode === "edit" &&
                                            !item.is_edit,
                                          icon: "el-icon-remove-outline"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.removePro(index)
                                          }
                                        },
                                        slot: "append"
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("div", { staticStyle: { color: "#606266" } }, [
                                _vm._v(
                                  "商品名称：" +
                                    _vm._s(
                                      scope.form["goods"][index].name || "暂无"
                                    )
                                )
                              ]),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "every-form-item",
                                  attrs: {
                                    label: "高等级返利:",
                                    rules:
                                      item.goods_id && item.name
                                        ? {
                                            required: true,
                                            message: "请输入高等级返利",
                                            trigger: "blur"
                                          }
                                        : {},
                                    prop: "goods." + index + ".high_profit"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "profit-input",
                                    attrs: {
                                      maxlength: "6",
                                      size: "small",
                                      placeholder: "输入高等级返利"
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.profitInput(
                                          $event,
                                          index,
                                          "high_profit"
                                        )
                                      }
                                    },
                                    model: {
                                      value: item.high_profit,
                                      callback: function($$v) {
                                        _vm.$set(item, "high_profit", $$v)
                                      },
                                      expression: "item.high_profit"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "every-form-item",
                                  attrs: {
                                    label: "低等级返利:",
                                    rules:
                                      item.goods_id && item.name
                                        ? {
                                            required: true,
                                            message: "请输入低等级返利",
                                            trigger: "blur"
                                          }
                                        : {},
                                    prop: "goods." + index + ".profit"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "profit-input",
                                    attrs: {
                                      maxlength: "6",
                                      size: "small",
                                      placeholder: "输入低等级返利"
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.profitInput(
                                          $event,
                                          index,
                                          "profit"
                                        )
                                      }
                                    },
                                    model: {
                                      value: item.profit,
                                      callback: function($$v) {
                                        _vm.$set(item, "profit", $$v)
                                      },
                                      expression: "item.profit"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "every-form-item",
                                  staticStyle: { "margin-bottom": "20px" },
                                  attrs: {
                                    label: "低等级差额返利:",
                                    rules:
                                      item.goods_id && item.name
                                        ? {
                                            required: true,
                                            message: "请输入低等级差额返利",
                                            trigger: "blur"
                                          }
                                        : {},
                                    prop: "goods." + index + ".diff_profit"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "profit-input",
                                    attrs: {
                                      maxlength: "6",
                                      size: "small",
                                      placeholder: "输入低等级差额返利"
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.profitInput(
                                          $event,
                                          index,
                                          "diff_profit"
                                        )
                                      }
                                    },
                                    model: {
                                      value: item.diff_profit,
                                      callback: function($$v) {
                                        _vm.$set(item, "diff_profit", $$v)
                                      },
                                      expression: "item.diff_profit"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "every-form-item",
                                  attrs: {
                                    label: "是否扣减库存:",
                                    prop:
                                      "goods." + index + ".is_deduct_inventory"
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "#303133",
                                        "margin-right": "10px"
                                      }
                                    },
                                    [_vm._v("否")]
                                  ),
                                  _c("el-switch", {
                                    attrs: { "active-color": "#67c23a" },
                                    model: {
                                      value: item.is_deduct_inventory,
                                      callback: function($$v) {
                                        _vm.$set(
                                          item,
                                          "is_deduct_inventory",
                                          $$v
                                        )
                                      },
                                      expression: "item.is_deduct_inventory"
                                    }
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "#303133",
                                        "margin-left": "10px"
                                      }
                                    },
                                    [_vm._v("是")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "every-form-item",
                                  attrs: {
                                    label: "上下架:",
                                    prop: "goods." + index + ".shelf"
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "#303133",
                                        "margin-right": "10px"
                                      }
                                    },
                                    [_vm._v("否")]
                                  ),
                                  _c("el-switch", {
                                    attrs: { "active-color": "#67c23a" },
                                    model: {
                                      value: item.shelf,
                                      callback: function($$v) {
                                        _vm.$set(item, "shelf", $$v)
                                      },
                                      expression: "item.shelf"
                                    }
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "#303133",
                                        "margin-left": "10px"
                                      }
                                    },
                                    [_vm._v("是")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      scope.form.goods.length
                        ? _c("div", { staticClass: "tip-text" }, [
                            _vm._v("注意：高等级返利要大于低等级返利")
                          ])
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.addPro }
                        },
                        [_vm._v("添加商品+")]
                      )
                    ]
                  }
                },
                {
                  key: "is_merchant_upFormSlot",
                  fn: function(scope) {
                    return [
                      _c("el-switch", {
                        attrs: { "active-text": "是", "inactive-text": "否" },
                        model: {
                          value: scope.form.is_merchant_up,
                          callback: function($$v) {
                            _vm.$set(scope.form, "is_merchant_up", $$v)
                          },
                          expression: "scope.form.is_merchant_up"
                        }
                      }),
                      scope.form.is_merchant_up
                        ? _c(
                            "div",
                            [
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.merchant_up_type,
                                        callback: function($$v) {
                                          _vm.merchant_up_type = $$v
                                        },
                                        expression: "merchant_up_type"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "type-label" },
                                        [_vm._v("会员升级类型：")]
                                      ),
                                      _c("el-radio", { attrs: { label: 0 } }, [
                                        _vm._v("累计")
                                      ]),
                                      _c("el-radio", { attrs: { label: 1 } }, [
                                        _vm._v("一次性")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._l(_vm.gradeList, function(item, index) {
                                return _c(
                                  "div",
                                  { key: index },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        on: {
                                          change: function($event) {
                                            return _vm.checkboxChange(
                                              $event,
                                              index
                                            )
                                          }
                                        },
                                        model: {
                                          value: item.checked,
                                          callback: function($$v) {
                                            _vm.$set(item, "checked", $$v)
                                          },
                                          expression: "item.checked"
                                        }
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    ),
                                    _c("el-input", {
                                      staticClass: "upgrade-input",
                                      attrs: {
                                        size: "small",
                                        disabled: !item.checked,
                                        placeholder: "输入升级金额"
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.upgradeInput($event, index)
                                        }
                                      },
                                      model: {
                                        value: item.price,
                                        callback: function($$v) {
                                          _vm.$set(item, "price", $$v)
                                        },
                                        expression: "item.price"
                                      }
                                    })
                                  ],
                                  1
                                )
                              }),
                              _c("div", { staticClass: "tip-text" }, [
                                _vm._v("注意：高级别金额要大于低级别金额")
                              ])
                            ],
                            2
                          )
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "expandSlot",
                  fn: function(scope) {
                    return _vm._l(scope.row.goods, function(item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "expand-content" },
                        [
                          _vm._v("(" + _vm._s(index + 1) + ") "),
                          _c("span", [
                            _vm._v("商品ID: " + _vm._s(item.goods_id))
                          ]),
                          _c("span", { staticClass: "pro-name" }, [
                            _vm._v("商品名称：" + _vm._s(item.name))
                          ])
                        ]
                      )
                    })
                  }
                }
              ])
            },
            "d2-crud-x",
            _vm._crudProps,
            false
          ),
          _vm._crudListeners
        ),
        [
          _c(
            "div",
            { attrs: { slot: "header" }, slot: "header" },
            [
              _c("crud-search", {
                ref: "search",
                attrs: { options: _vm.crud.searchOptions },
                on: { submit: _vm.handleSearch }
              }),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.addRow }
                },
                [_c("i", { staticClass: "el-icon-plus" }), _vm._v(" 新增")]
              ),
              _c("crud-toolbar", {
                attrs: {
                  compact: _vm.crud.pageOptions.compact,
                  columns: _vm.crud.columns
                },
                on: {
                  "update:compact": function($event) {
                    return _vm.$set(_vm.crud.pageOptions, "compact", $event)
                  },
                  refresh: function($event) {
                    return _vm.doRefresh()
                  },
                  "columns-filter-changed": _vm.handleColumnsFilterChanged
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }