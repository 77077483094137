import { request } from '@/api/service';
var prefix = '/svc/marketing-svc';
export default {
  getGoodsCategoryList: function getGoodsCategoryList(params) {
    return request({
      url: "".concat(prefix, "/admin/v1/regress/goods-category/list"),
      method: 'get',
      params: params
    });
  },
  addGoodsCategory: function addGoodsCategory(data) {
    return request({
      url: "".concat(prefix, "/admin/v1/regress/goods-category/store"),
      method: 'post',
      data: data
    });
  },
  updateGoodsCategory: function updateGoodsCategory(data) {
    return request({
      url: "".concat(prefix, "/admin/v1/regress/goods-category/store"),
      method: 'post',
      data: data
    });
  },
  updateGoodsCategoryStatus: function updateGoodsCategoryStatus(data) {
    return request({
      url: "".concat(prefix, "/admin/v1/regress/goods-category/set-status"),
      method: 'post',
      data: data
    });
  },
  deleteGoodsCategory: function deleteGoodsCategory(id) {
    return request({
      url: "".concat(prefix, "/admin/v1/regress/goods-category/delete?id=").concat(id),
      method: 'delete'
    });
  }
};