var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("兑换中心设置")]),
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.params, rules: _vm.rules } },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "exchange_rate",
                "label-position": "right",
                "label-width": "235px",
                label: "流量兑换比例（热度1：X流量）："
              }
            },
            [
              _c("span", { staticStyle: { "letter-spacing": "20px" } }, [
                _vm._v("1:")
              ]),
              _c("el-input", {
                staticClass: "input-self-300",
                model: {
                  value: _vm.params.exchange_rate,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "exchange_rate", $$v)
                  },
                  expression: "params.exchange_rate"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "new_hot_val",
                "label-position": "right",
                "label-width": "235px",
                label: "拉新送热度值："
              }
            },
            [
              _c("el-input", {
                staticClass: "input-self-350",
                model: {
                  value: _vm.params.new_hot_val,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "new_hot_val", $$v)
                  },
                  expression: "params.new_hot_val"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-button",
        {
          staticClass: "margin-left",
          attrs: { type: "primary" },
          on: { click: _vm.save }
        },
        [_vm._v("保存")]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }