//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { crudOptions } from './crud';
import { d2CrudPlus } from 'd2-crud-plus';
import api from './api';
import StatusTag from '@/components/status-tag/status-tag.vue';
export default {
  mixins: [d2CrudPlus.crud],
  methods: {
    getCrudOptions: function getCrudOptions() {
      return crudOptions(StatusTag, this);
    },
    pageRequest: function pageRequest(query) {
      return api.getCouponCategoryList(query);
    },
    addRequest: function addRequest(data) {
      return api.addCouponCategory(data);
    },
    updateRequest: function updateRequest(data) {
      return api.updateCouponCategory(data);
    },
    delRequest: function delRequest(row) {
      return api.deleteCouponCategory(row.id);
    }
  }
};