import { request } from '@/api/service';
export default {
  // 选择器数据
  getSumList: function getSumList(params) {
    return request({
      url: '',
      method: 'get',
      params: params
    });
  },
  // 图标数据
  getSumData: function getSumData(params) {
    return request({
      url: '',
      method: 'get',
      params: params
    });
  }
};