import { request } from '@/api/service';
var prefix = '/svc/marketing-svc';
export default {
  getGoodsList: function getGoodsList(params) {
    return request({
      url: "".concat(prefix, "/admin/v1/regress/goods/list"),
      method: 'get',
      params: params
    });
  },
  addGoods: function addGoods(data) {
    return request({
      url: "".concat(prefix, "/admin/v1/regress/goods/store"),
      method: 'post',
      data: data
    });
  },
  updateGoods: function updateGoods(data) {
    return request({
      url: "".concat(prefix, "/admin/v1/regress/goods/store"),
      method: 'post',
      data: data
    });
  },
  deleteGoods: function deleteGoods(id) {
    return request({
      url: "".concat(prefix, "/admin/v1/regress/goods/delete?id=").concat(id),
      method: 'delete'
    });
  },
  updateGoodsStatus: function updateGoodsStatus(data) {
    return request({
      url: "".concat(prefix, "/admin/v1/regress/goods/set-status"),
      method: 'post',
      data: data
    });
  },
  // 根据商品ID获取商品详细
  getGoodsDetail: function getGoodsDetail(id) {
    return request({
      url: "".concat(prefix, "/admin/v1/goods/show?goods_id=").concat(id),
      method: 'get'
    });
  }
};