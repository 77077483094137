var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pre-model-box" }, [
    _c("div", { staticClass: "title color-333 border-dashed font-bold" }, [
      _vm._v("爱粉圈素材预览发布")
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShowOnePart,
            expression: "isShowOnePart"
          }
        ],
        staticClass:
          "every-temple-part border-dashed flex align-center justify-between padding-lr-8 padding-tb-10"
      },
      [
        _c(
          "div",
          { staticClass: "flex align-center" },
          [
            !_vm.issuerInfo.avatar
              ? _c(
                  "el-image",
                  {
                    staticClass: "avatar-box",
                    attrs: { fit: "cover", size: "medium", src: "" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "image-slot",
                        attrs: { slot: "error" },
                        slot: "error"
                      },
                      [_c("i", { staticClass: "el-icon-picture-outline" })]
                    )
                  ]
                )
              : _vm._e(),
            _vm.issuerInfo.avatar
              ? _c(
                  "el-image",
                  {
                    staticClass: "avatar-box",
                    attrs: {
                      fit: "cover",
                      size: "medium",
                      src: _vm._f("qiniu")(_vm.issuerInfo.avatar)
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "image-slot",
                        attrs: { slot: "error" },
                        slot: "error"
                      },
                      [_c("i", { staticClass: "el-icon-picture-outline" })]
                    )
                  ]
                )
              : _vm._e(),
            _c("div", { staticClass: "flex flex-direction-column ml-9" }, [
              _c("span", { staticClass: "font-size-9 color-333" }, [
                _vm._v(_vm._s(_vm.issuerInfo.name))
              ]),
              _c("span", { staticClass: "font-size-8 color-999 mt-3" }, [
                _vm._v("刚刚")
              ])
            ])
          ],
          1
        ),
        _c("div", { staticClass: "font-size-8 color-999" }, [
          _c("i", {
            staticClass: "el-icon-share",
            staticStyle: { "font-size": "16px" }
          }),
          _vm._v(" " + _vm._s(_vm.showVirtualNum) + " ")
        ]),
        _c(
          "div",
          {
            staticClass: "right-del-btn",
            on: {
              click: function($event) {
                return _vm.delTemplate(1)
              }
            }
          },
          [_c("i", { staticClass: "el-icon-circle-close right-del-icon" })]
        )
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.content,
            expression: "content"
          }
        ],
        staticClass:
          "every-temple-part text-box border-dashed padding-lr-8 padding-tb-10 font-size-9 color-333"
      },
      [
        _c("div", { domProps: { innerHTML: _vm._s(_vm.showText) } }),
        _c(
          "div",
          {
            staticClass: "right-del-btn",
            on: {
              click: function($event) {
                return _vm.delTemplate(2)
              }
            }
          },
          [_c("i", { staticClass: "el-icon-circle-close right-del-icon" })]
        )
      ]
    ),
    _vm.type === "image" &&
    _vm.handelProImgList.length &&
    _vm.handelProImgList.length > 1
      ? _c(
          "div",
          {
            staticClass:
              "every-temple-part border-dashed padding-lr-8 padding-tb-10"
          },
          [
            _c(
              "div",
              {
                staticClass: "flex flex-wrap",
                class: { "nine-img-box": _vm.handelProImgList.length !== 4 }
              },
              _vm._l(_vm.handelProImgList, function(item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "pro-img-box" },
                  [
                    _c("el-image", {
                      staticClass: "pro-img",
                      attrs: { src: _vm._f("qiniu")(item.url), fit: "contain" }
                    }),
                    _c("i", {
                      staticClass: "el-icon-error del-icon cursor",
                      on: {
                        click: function($event) {
                          return _vm.delProImg(index)
                        }
                      }
                    })
                  ],
                  1
                )
              }),
              0
            ),
            _c(
              "div",
              {
                staticClass: "right-del-btn",
                on: {
                  click: function($event) {
                    return _vm.delTemplate(3)
                  }
                }
              },
              [_c("i", { staticClass: "el-icon-circle-close right-del-icon" })]
            )
          ]
        )
      : _vm._e(),
    _vm.type === "image" &&
    _vm.handelProImgList.length &&
    _vm.handelProImgList.length === 1
      ? _c(
          "div",
          {
            staticClass:
              "every-temple-part border-dashed padding-lr-8 padding-tb-10"
          },
          [
            _c(
              "div",
              { staticClass: "one-pro-img-box" },
              [
                _c("el-image", {
                  staticClass: "one-pro-img",
                  attrs: {
                    src: _vm._f("qiniu")(_vm.handelProImgList[0].url),
                    fit: "contain"
                  }
                }),
                _c("i", {
                  staticClass: "el-icon-error del-icon cursor",
                  on: {
                    click: function($event) {
                      return _vm.delProImg(_vm.index)
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "right-del-btn",
                on: {
                  click: function($event) {
                    return _vm.delTemplate(3)
                  }
                }
              },
              [_c("i", { staticClass: "el-icon-circle-close right-del-icon" })]
            )
          ]
        )
      : _vm._e(),
    _vm.type === "video" && _vm.handelProImgList.length
      ? _c(
          "div",
          {
            staticClass:
              "every-temple-part border-dashed padding-lr-8 padding-tb-10"
          },
          [
            _c(
              "video",
              {
                staticClass: "video",
                attrs: {
                  src: _vm._f("qiniu")(
                    _vm.handelProImgList[0].url,
                    750,
                    "video"
                  ),
                  controls: "controls"
                }
              },
              [_vm._v(" 您的浏览器不支持视频播放 ")]
            ),
            _c(
              "div",
              {
                staticClass: "right-del-btn",
                on: {
                  click: function($event) {
                    return _vm.delTemplate(3)
                  }
                }
              },
              [_c("i", { staticClass: "el-icon-circle-close right-del-icon" })]
            )
          ]
        )
      : _vm._e(),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShowFourPart,
            expression: "isShowFourPart"
          }
        ],
        staticClass:
          "every-temple-part border-dashed padding-lr-8 padding-tb-10"
      },
      [
        _c(
          "div",
          { staticClass: "show-pro-box flex" },
          [
            _c("el-image", {
              staticClass: "bottom-pro-img",
              attrs: {
                src: _vm._f("qiniu")(_vm.showProInfo.image),
                fit: "contain"
              }
            }),
            _c(
              "div",
              {
                staticClass:
                  "pro-right-info flex justify-between flex-direction-column"
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "color-333 font-size-10 hidden-ellipsis mb-5"
                  },
                  [_vm._v(_vm._s(_vm.showProInfo.name))]
                ),
                _c("div", { staticClass: "pro-price-part flex" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex align-center justify-center flex-direction-column padding-lr-6"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "font-size-15 color-red font-bold" },
                        [_vm._v("¥" + _vm._s(_vm.showProInfo.vip_price))]
                      ),
                      _c(
                        "div",
                        { staticClass: "font-size-14 color-red font-bold" },
                        [_vm._v("会员价")]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex-sub pro-white-part flex justify-between"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex align-center justify-center flex-direction-column"
                        },
                        [
                          _c("div", { staticClass: "font-size-15 color-999" }, [
                            _vm._v("¥" + _vm._s(_vm.showProInfo.price))
                          ]),
                          _c("div", { staticClass: "font-size-10 color-999" }, [
                            _vm._v("零售价")
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "btn-box flex align-center justify-center flex-direction-column"
                        },
                        [
                          _c("div", { staticClass: "font-size-10 color-999" }, [
                            _vm._v(
                              _vm._s(
                                _vm.showProInfo.text +
                                  _vm.showProInfo.less_price
                              ) + "元"
                            )
                          ]),
                          _c("div", { staticClass: "look-detail-btn mt-3" }, [
                            _vm._v("查看详情")
                          ])
                        ]
                      )
                    ]
                  )
                ])
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "right-del-btn",
            on: {
              click: function($event) {
                return _vm.delTemplate(4)
              }
            }
          },
          [_c("i", { staticClass: "el-icon-circle-close right-del-icon" })]
        )
      ]
    ),
    _vm.isShowEmptyTip
      ? _c("div", { staticClass: "empty color-999 font-size-9" }, [
          _vm._v("右边添加数据进行预览")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }