import { request } from '@/api/service';
export default {
  /**
   * 商品分类列表 接口
   * http://doc.vodeshop.com/web/#/34?page_id=2877
   * @param {*} data
   */
  getClassifyList: function getClassifyList(params) {
    return request({
      url: '/svc/marketing-svc/admin/v1/give-like-goods-category/index',
      method: 'get',
      params: params
    });
  },

  /**
   * 创建商品分类 接口
   * http://doc.vodeshop.com/web/#/34?page_id=2732
   * @param {*} data
   */
  addClassify: function addClassify(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/give-like-goods-category/create',
      method: 'post',
      data: data
    });
  },

  /**
   * 编辑商品分类 接口
   * http://doc.vodeshop.com/web/#/34?page_id=2880
   * @param {*} data
   */
  editClassify: function editClassify(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/give-like-goods-category/update',
      method: 'put',
      data: data
    });
  },

  /**
   * 编辑商品分类状态 接口
   * http://doc.vodeshop.com/web/#/34?page_id=2880
   * @param {*} data
   */
  editClassifyStatus: function editClassifyStatus(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/give-like-goods-category/enable',
      method: 'put',
      data: data
    });
  },

  /**
   * 删除众筹商品
   * http://doc.vodeshop.com/web/#/34?page_id=2882
   * @param {*} data
   */
  deleteClassify: function deleteClassify(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/give-like-goods-category/delete',
      method: 'delete',
      data: data
    });
  }
};