// 状态字典
export var THUMB_STATUS = [{
  value: 0,
  label: '集赞中'
}, {
  value: 1,
  label: '集赞成功'
}, {
  value: 2,
  label: '集赞失败'
}, {
  value: 3,
  label: '集赞取消'
}, {
  value: 4,
  label: '集赞失效'
}, {
  value: 5,
  label: '下单成功'
}];