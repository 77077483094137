var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-form",
        {
          attrs: {
            "label-position": "left",
            rules: _vm.formRules,
            model: _vm.formData,
            "label-width": "110px",
            size: "mini"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "分类名称", prop: "categoryName" } },
            [
              _c("el-input", {
                attrs: {
                  type: "text",
                  placeholder: "请输入6个字符以内的分类名称"
                },
                model: {
                  value: _vm.formData.categoryName,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "categoryName", $$v)
                  },
                  expression: "formData.categoryName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "分类排序", prop: "categorySort" } },
            [
              _c("el-input", {
                attrs: { type: "text", placeholder: "数字越大，排序越前" },
                model: {
                  value: _vm.formData.categorySort,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "categorySort", _vm._n($$v))
                  },
                  expression: "formData.categorySort"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "子分类" } },
            [
              _c("el-switch", {
                staticStyle: { "margin-right": "10px" },
                attrs: { "active-color": "#13ce66" },
                on: { change: _vm.removeAllChildren },
                model: {
                  value: _vm.formData.isChildCategory,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "isChildCategory", $$v)
                  },
                  expression: "formData.isChildCategory"
                }
              }),
              _vm.formData.isChildCategory
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.addChild }
                    },
                    [_vm._v("添加子分类项")]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._l(_vm.formData.dynamicChildren, function(item, index) {
            return _c(
              "div",
              { key: index },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "子类" + (index + 1) + "名称",
                      prop: "dynamicChildren." + index + ".childName",
                      rules: [
                        {
                          required: true,
                          message: "子类名称不能为空",
                          trigger: "blur"
                        },
                        {
                          min: 1,
                          max: 6,
                          message: "名称长度在6个字符内",
                          trigger: "blur"
                        }
                      ]
                    }
                  },
                  [
                    _c("el-input", {
                      attrs: { type: "text", placeholder: "请输入子类名称" },
                      model: {
                        value: item.childName,
                        callback: function($$v) {
                          _vm.$set(item, "childName", $$v)
                        },
                        expression: "item.childName"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "子分类" + (index + 1) + "排序",
                      prop: "dynamicChildren." + index + ".childSort",
                      rules: [
                        {
                          required: true,
                          message: "子类排序不能为空",
                          trigger: "blur"
                        },
                        {
                          type: "number",
                          message: "子类排序只能为数字",
                          trigger: "blur"
                        }
                      ]
                    }
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        type: "text",
                        placeholder: "数字越大，排序越前"
                      },
                      model: {
                        value: item.childSort,
                        callback: function($$v) {
                          _vm.$set(item, "childSort", _vm._n($$v))
                        },
                        expression: "item.childSort"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.removeChild(item, index)
                          }
                        }
                      },
                      [_vm._v("删除该子类")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          }),
          _c(
            "el-form-item",
            { attrs: { label: "权限模板", prop: "categorySort" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择权限模板" },
                  model: {
                    value: _vm.formData.authTemplate,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "authTemplate", $$v)
                    },
                    expression: "formData.authTemplate"
                  }
                },
                _vm._l(_vm.authList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label: item.title,
                      value: item.id,
                      disabled: item.status === 0 ? false : true
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "启用状态" } },
            [
              _c("el-switch", {
                staticStyle: { "margin-right": "10px" },
                attrs: { "active-color": "#13ce66" },
                model: {
                  value: _vm.formData.is_enable,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "is_enable", $$v)
                  },
                  expression: "formData.is_enable"
                }
              })
            ],
            1
          )
        ],
        2
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "success", size: "mini" },
              on: { click: _vm.submit }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }