var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "商家点个赞操作详细",
        visible: _vm.isShowDetailDialog,
        center: true,
        width: "70%"
      },
      on: {
        "update:visible": function($event) {
          _vm.isShowDetailDialog = $event
        },
        close: _vm.close
      }
    },
    [
      _c("div", { staticClass: "content-box" }, [
        _c("div", { staticClass: "title" }, [_vm._v("智慧店老板信息")]),
        _c(
          "div",
          { staticStyle: { width: "60%" } },
          [
            _c("tableTemple", {
              attrs: {
                tableData: _vm.detailInfo.userInfo || [],
                columnArr: _vm.columnUser
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "thumb-info-box" }, [
          _c(
            "div",
            [
              _c("div", { staticClass: "big-title" }, [_vm._v("修改前")]),
              _c("div", { staticClass: "title" }, [_vm._v("上架商品信息")]),
              _c("div", [
                _c("span", [_vm._v("商品名称：")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      (_vm.detailInfo.give_like_goods &&
                        _vm.detailInfo.give_like_goods.goods &&
                        _vm.detailInfo.give_like_goods.goods.title) ||
                        "暂无"
                    )
                  )
                ])
              ]),
              _c("tableTemple", {
                attrs: {
                  tableData:
                    (_vm.detailInfo.before_params &&
                      _vm.detailInfo.before_params.give_like_goods_sku) ||
                    [],
                  columnArr: _vm.columnGoods
                }
              }),
              _c("div", [
                _c("span", [_vm._v("购买方式：")]),
                _c("span", [_vm._v("任意金额可购买")])
              ]),
              _c("div", [
                _c("span", [_vm._v("活动时间：")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.detailInfo.before_params &&
                        _vm.detailInfo.before_params.start_at
                    ) +
                      "到" +
                      _vm._s(
                        _vm.detailInfo.before_params &&
                          _vm.detailInfo.before_params.end_at
                      )
                  )
                ])
              ]),
              _c("div", [
                _c("span", [_vm._v("集赞时长：")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.detailInfo.before_params &&
                        _vm.detailInfo.before_params.like_hour
                    ) + "小时"
                  )
                ])
              ])
            ],
            1
          ),
          _c(
            "div",
            [
              _c("div", { staticClass: "big-title" }, [_vm._v("修改后")]),
              _c("div", { staticClass: "title" }, [_vm._v("上架商品信息")]),
              _c("div", [
                _c("span", [_vm._v("商品名称：")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      (_vm.detailInfo.give_like_goods &&
                        _vm.detailInfo.give_like_goods.goods &&
                        _vm.detailInfo.give_like_goods.goods.title) ||
                        "暂无"
                    )
                  )
                ])
              ]),
              _c("tableTemple", {
                attrs: {
                  tableData:
                    (_vm.detailInfo.after_params &&
                      _vm.detailInfo.after_params.give_like_goods_sku) ||
                    [],
                  columnArr: _vm.columnGoods
                }
              }),
              _c("div", [
                _c("span", [_vm._v("购买方式：")]),
                _c("span", [_vm._v("任意金额可购买")])
              ]),
              _c("div", [
                _c("span", [_vm._v("活动时间：")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.detailInfo.after_params &&
                        _vm.detailInfo.after_params.start_at
                    ) +
                      "到" +
                      _vm._s(
                        _vm.detailInfo.after_params &&
                          _vm.detailInfo.after_params.end_at
                      )
                  )
                ])
              ]),
              _c("div", [
                _c("span", [_vm._v("集赞时长：")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.detailInfo.after_params &&
                        _vm.detailInfo.after_params.like_hour
                    ) + "小时"
                  )
                ])
              ])
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }