var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          staticStyle: { "border-left": "1px solid #EBEEF5" },
          style: { color: _vm.isShow ? "#C0C4CC" : "" },
          attrs: {
            data: _vm.value,
            border: "",
            "header-row-style": { color: _vm.isShow ? "#C0C4CC" : "" }
          }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "name", label: "规格名称", width: "250" }
          }),
          _c("el-table-column", {
            attrs: { prop: "number", label: "规格数量" }
          }),
          _c("el-table-column", {
            attrs: { prop: "deposit", label: "保证金" }
          }),
          _c("el-table-column", {
            attrs: { prop: "profit", label: "众创返利" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        nativeOn: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.lookDetail(scope)
                          }
                        }
                      },
                      [_vm._v(" 查看详情 ")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "众创返利详情",
            visible: _vm.dialogFormVisible,
            modal: false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { "border-left": "1px solid #EBEEF5" },
              attrs: {
                data: _vm.profitList,
                border: "",
                size: "small",
                "header-cell-style": { "text-align": "center" },
                "cell-style": { "text-align": "center" }
              }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "logo", label: "商品图片", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-image", {
                          staticStyle: { width: "50px", height: "50px" },
                          attrs: {
                            src: _vm._f("qiniu")(scope.row.logo),
                            fit: "contain"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "商品名称", width: "350" }
              }),
              _c("el-table-column", {
                attrs: { prop: "high_profit", label: "高返利" }
              }),
              _c("el-table-column", {
                attrs: { prop: "profit", label: "低返利" }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.dialogFormVisible = false
                    }
                  }
                },
                [_vm._v("关闭")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }