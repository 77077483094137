var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { class: { "page-compact": _vm.crud.pageOptions.compact } },
    [
      _c(
        "d2-crud-x",
        _vm._g(
          _vm._b(
            {
              ref: "d2Crud",
              on: { "custom-deposit": _vm.allowDeposit },
              scopedSlots: _vm._u([
                {
                  key: "skuSlot",
                  fn: function(scope) {
                    return _vm._l(scope.row["sku"], function(val, index) {
                      return _c(
                        "el-tag",
                        {
                          key: index,
                          staticStyle: { "margin-right": "5px" },
                          attrs: { type: "success", size: "mini" }
                        },
                        [_vm._v(_vm._s(val.name))]
                      )
                    })
                  }
                },
                {
                  key: "permissionFormSlot",
                  fn: function(scope) {
                    return _vm._l(scope.row["permission"], function(
                      item,
                      index
                    ) {
                      return _c(
                        "el-tag",
                        {
                          key: index,
                          staticStyle: {
                            background: "#F5F7FA",
                            "margin-right": "10px"
                          },
                          attrs: { type: "info" }
                        },
                        [_vm._v(_vm._s(item))]
                      )
                    })
                  }
                },
                {
                  key: "created_atFormSlot",
                  fn: function(scope) {
                    return [
                      _c("el-date-picker", {
                        attrs: {
                          disabled: "",
                          type: "datetime",
                          placeholder: "选择日期"
                        },
                        model: {
                          value: scope.row.created_at,
                          callback: function($$v) {
                            _vm.$set(scope.row, "created_at", $$v)
                          },
                          expression: "scope.row.created_at"
                        }
                      })
                    ]
                  }
                },
                {
                  key: "created_atSlot",
                  fn: function(scope) {
                    return [
                      _c("span", [_vm._v(_vm._s(scope.row["created_at"]))])
                    ]
                  }
                },
                {
                  key: "third_party_snFormSlot",
                  fn: function(scope) {
                    return [
                      _c("span", { staticStyle: { color: "#909090" } }, [
                        _vm._v(_vm._s(scope.row["third_party_sn"] || "暂无"))
                      ])
                    ]
                  }
                }
              ])
            },
            "d2-crud-x",
            _vm._crudProps,
            false
          ),
          _vm._crudListeners
        ),
        [
          _c(
            "div",
            { attrs: { slot: "header" }, slot: "header" },
            [
              _c("crud-search", {
                ref: "search",
                attrs: { options: _vm.crud.searchOptions },
                on: { submit: _vm.handleSearch }
              }),
              _c("crud-toolbar", {
                attrs: {
                  compact: _vm.crud.pageOptions.compact,
                  columns: _vm.crud.columns,
                  export: true
                },
                on: {
                  "update:compact": function($event) {
                    return _vm.$set(_vm.crud.pageOptions, "compact", $event)
                  },
                  refresh: function($event) {
                    return _vm.doRefresh()
                  },
                  export: _vm.handleExport,
                  "columns-filter-changed": _vm.handleColumnsFilterChanged
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }