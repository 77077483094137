var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { class: { "page-compact": _vm.crud.pageOptions.compact } },
    [
      _c(
        "d2-crud-x",
        _vm._g(
          _vm._b(
            {
              ref: "d2Crud",
              scopedSlots: _vm._u([
                {
                  key: "coupon_page_idFormSlot",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            disabled: scope.mode === "edit",
                            placeholder: "请选择"
                          },
                          model: {
                            value: scope.form.coupon_page_id,
                            callback: function($$v) {
                              _vm.$set(scope.form, "coupon_page_id", $$v)
                            },
                            expression: "scope.form.coupon_page_id"
                          }
                        },
                        _vm._l(_vm.specialList, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "mleft30",
                          attrs: {
                            type: "primary",
                            size: "mini",
                            disabled: scope.mode === "edit"
                          },
                          on: { click: _vm.addSpecialPart }
                        },
                        [_vm._v("添加专区+")]
                      )
                    ]
                  }
                },
                {
                  key: "goods_idFormSlot",
                  fn: function(scope) {
                    return [
                      _vm._l(scope.form.goods_id, function(item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "flex wid100 mgb20" },
                          [
                            _c(
                              "el-input",
                              {
                                staticClass: "wid87",
                                model: {
                                  value: scope.form.goods_id[index],
                                  callback: function($$v) {
                                    _vm.$set(scope.form.goods_id, index, $$v)
                                  },
                                  expression: "scope.form.goods_id[index]"
                                }
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    type: "danger",
                                    icon: "el-icon-delete"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.removeGoods(index)
                                    }
                                  },
                                  slot: "append"
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-button",
                              {
                                staticClass: "mleft30",
                                on: {
                                  click: function($event) {
                                    return _vm.openDrawer(index)
                                  }
                                }
                              },
                              [_vm._v("查找商品")]
                            )
                          ],
                          1
                        )
                      }),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            disabled: scope.mode === "edit",
                            size: "small"
                          },
                          on: { click: _vm.addGoods }
                        },
                        [_vm._v("添加商品+")]
                      )
                    ]
                  }
                }
              ])
            },
            "d2-crud-x",
            _vm._crudProps,
            false
          ),
          _vm._crudListeners
        ),
        [
          _c(
            "div",
            { attrs: { slot: "header" }, slot: "header" },
            [
              _c("crud-search", {
                ref: "search",
                attrs: { options: _vm.crud.searchOptions },
                on: { submit: _vm.handleSearch }
              }),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.addRow }
                },
                [_c("i", { staticClass: "el-icon-plus" }), _vm._v(" 新增")]
              ),
              _c("crud-toolbar", {
                attrs: {
                  compact: _vm.crud.pageOptions.compact,
                  columns: _vm.crud.columns
                },
                on: {
                  "update:compact": function($event) {
                    return _vm.$set(_vm.crud.pageOptions, "compact", $event)
                  },
                  refresh: function($event) {
                    return _vm.doRefresh()
                  },
                  "columns-filter-changed": _vm.handleColumnsFilterChanged
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }