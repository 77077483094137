//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    editDialogVisible: {
      type: Boolean,
      default: false
    },
    editProForm: {
      type: Object,
      default: function _default() {}
    }
  },
  computed: {
    isShowDialog: {
      get: function get() {
        console.log(this.editDialogVisible);
        return this.editDialogVisible;
      },
      set: function set() {// 因为我这里并不需要其他属性也跟着一起改变 所以就这样写了      console.log('1')
      }
    },
    form: {
      get: function get() {
        console.log('传进来的参数', this.editProForm);
        var obj = this.editProForm;
        obj.is_up = this.editProForm.is_up !== 0;
        return obj;
      },
      set: function set() {// 因为我这里并不需要其他属性也跟着一起改变 所以就这样写了      console.log('1')
      }
    }
  },
  watch: {},
  methods: {
    handleClose: function handleClose() {
      this.$emit('closeEditDialog');
    }
  }
};