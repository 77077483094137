import { find, assign } from 'lodash';
var users = [{
  username: 'admin',
  password: 'Vode168168',
  uuid: 'admin-uuid',
  name: 'Admin'
}, {
  username: 'editor',
  password: 'editor',
  uuid: 'editor-uuid',
  name: 'Editor'
}, {
  username: 'user1',
  password: 'user1',
  uuid: 'user1-uuid',
  name: 'User1'
}];
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    /**
     * @description 登录
     * @param {Object} data 登录携带的信息
     */
    SYS_USER_LOGIN: function SYS_USER_LOGIN() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (process.env.VUE_APP_PM_ENABLED === 'true') {
        return request({
          url: '/login',
          method: 'post',
          data: data
        });
      } // 模拟数据


      mock.onAny('/login').reply(function (config) {
        var user = find(users, tools.parse(config.data));
        return user ? tools.responseSuccess(assign({}, user, {
          token: faker.random.uuid()
        })) : tools.responseError({}, '账号或密码不正确');
      }); // 接口请求

      return requestForMock({
        url: '/login',
        method: 'post',
        data: data
      });
    }
  };
});