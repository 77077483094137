import { request } from '@/api/service';
export default {
  FilterMaterial: function FilterMaterial(params) {
    return request({
      url: '/svc/marketing-svc/admin/v1/material/search',
      method: 'get',
      params: params
    });
  },
  DeleteMaterial: function DeleteMaterial(id) {
    return request({
      url: "/svc/marketing-svc/admin/v1/material/delete?id=".concat(id),
      method: 'delete'
    });
  },
  UpdateMaterialStatus: function UpdateMaterialStatus(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/material/is-enable',
      method: 'put',
      data: data
    });
  }
};