import { request } from '@/api/service';
export default {
  /**
   * 获取赞明细列表
   * http://doc.vodeshop.com/web/#/34?page_id=2885
   * @param {*} data
   */
  getDetailList: function getDetailList(params) {
    return request({
      url: '/svc/marketing-svc/admin/v1/give-likes',
      method: 'get',
      params: params
    });
  },

  /**
   * 点赞详情接口
   * http://doc.vodeshop.com/web/#/34?page_id=2912
   * @param {*} data
   */
  getDetail: function getDetail(params) {
    return request({
      url: '/svc/marketing-svc/admin/v1/give-likes/info',
      method: 'get',
      params: params
    });
  },

  /**
   * 点赞记录列表接口
   * http://doc.vodeshop.com/web/#/34?page_id=2913
   * @param {*} data
   */
  getRecordList: function getRecordList(params) {
    return request({
      url: '/svc/marketing-svc/admin/v1/give-likes/like-records',
      method: 'get',
      params: params
    });
  }
};