import "core-js/modules/es.array.concat.js";
import { request } from '@/api/service';
export default {
  // 获取权限模板列表
  GetPermissionList: function GetPermissionList(data) {
    return request({
      url: "/svc/marketing-svc/admin/v1/template/list?page=".concat(data.page, "&per_page=").concat(data.per_page),
      method: 'get'
    });
  },
  // 获取分类列表
  GetCategoryList: function GetCategoryList() {
    return request({
      url: '/svc/marketing-svc/admin/v1/pink-circle-category/index',
      method: 'get'
    });
  },
  // 修改分类的状态
  UpdateCategoryStatus: function UpdateCategoryStatus(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/pink-circle-category/enable',
      method: 'put',
      data: data
    });
  },
  // 创建分类
  CreateCategory: function CreateCategory(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/pink-circle-category/create',
      method: 'post',
      data: data
    });
  },
  // 编辑分类
  UpdateCategory: function UpdateCategory(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/pink-circle-category/update',
      method: 'put',
      data: data
    });
  },
  // 删除分类
  DeleteCategory: function DeleteCategory(params) {
    return request({
      url: '/svc/marketing-svc/admin/v1/pink-circle-category/delete',
      method: 'delete',
      params: params
    });
  }
};