var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增/编辑活动商品",
            visible: _vm.isShowDialog,
            width: "35%",
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.isShowDialog = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { "label-width": "120px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品ID" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "250px" },
                    model: {
                      value: _vm.form.id,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "id", $$v)
                      },
                      expression: "form.id"
                    }
                  }),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "20px" },
                      attrs: { type: "primary", size: "mini" }
                    },
                    [_vm._v("选择商品")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品名称" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "250px" },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否上架" } },
                [
                  _c("el-switch", {
                    attrs: { "active-text": "上架", "inactive-text": "下架" },
                    model: {
                      value: _vm.form.is_up,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "is_up", $$v)
                      },
                      expression: "form.is_up"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.isShowDialog = false
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }