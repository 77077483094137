// import API from './api'
import util from '@/libs/util';
import { ORDER_STATUS, RENEWAL_STATUS } from './dict';
export var crudOptions = function crudOptions(vm) {
  return {
    options: {
      height: '100%',
      // 表格高度100%,使用toolbar必须设置
      rowKey: 'id',
      stripe: true
    },
    viewOptions: {
      disabled: false
    },
    formOptions: {
      // 编辑对话框及el-form的配置
      type: 'drawer',
      // 默认对话框模式，drawer 抽屉模式
      size: '60%',
      // 抽屉模式的宽度
      fullscreen: true,
      // 全屏按钮，传null则隐藏全屏按钮，抽屉模式请隐藏
      draggable: true,
      // 是否支持表单对话框拖拽，抽屉模式请关闭
      saveRemind: true,
      // 有修改时是否需要保存提醒，也可以传入一个方法，自定义确认对话框，()=> return vm.$confirm({})
      labelWidth: '100px',
      labelPosition: 'left',
      saveLoading: false,
      gutter: 20,
      saveButtonType: 'primary',
      maxHeight: true,
      // 对话框内部显示滚动条
      defaultSpan: 24,
      // 默认表单字段所占宽度
      updateTableDataAfterEdit: true // 添加和删除提交后，是否直接更新本地table的数据，默认会自动刷新表格，所以不需要更新本地数据

    },
    rowHandle: {
      // width: 100,
      edit: false,
      remove: false,
      view: false,
      // fixed: 'right',
      align: 'center',
      custom: [{
        // text: '允许用户退押',
        // text (scope) { return scope.row.is_allow_return_deposit === 1 ? '已允许用户退押' : '允许用户退押' },
        type: 'success',
        text: '审核通过',
        size: 'small',
        // disabled (index, row) { return row.is_allow_return_deposit === 1 },
        emit: 'custom-checked',
        show: function show(index, row) {
          return row.renewal_status === 1;
        }
      }, {
        // text: '允许用户退押',
        // text (scope) { return scope.row.is_allow_return_deposit === 1 ? '已允许用户退押' : '允许用户退押' },
        type: 'warning',
        text: '拒绝通过',
        size: 'small',
        // disabled (index, row) { return row.is_allow_return_deposit === 1 },
        emit: 'custom-refuse',
        show: function show(index, row) {
          return row.renewal_status === 1;
        }
      }, {
        // text: '允许用户退押',
        text: function text(scope) {
          return scope.row.renewal_status === 2 ? '审核通过' : '审核不通过';
        },
        type: 'info',
        size: 'small',
        // disabled (index, row) { return row.is_allow_return_deposit === 1 },
        emit: 'custom-ok',
        show: function show(index, row) {
          return row.renewal_status === 2 || row.renewal_status === 3;
        }
      }]
    },
    columns: [{
      title: '众创订单ID',
      key: 'id',
      width: '100',
      align: 'center',
      type: 'el-input',
      search: {
        disabled: false
      },
      form: {
        disabled: true
      }
    }, // {
    //   title: '商品ID',
    //   key: 'goods_id',
    //   align: 'center',
    //   type: 'el-input',
    //   search: {
    //     disabled: false
    //   }
    // },
    // {
    //   title: '众创商品ID',
    //   key: 'raise_goods_item_id',
    //   align: 'center',
    //   type: 'el-input',
    //   search: {
    //     disabled: false
    //   },
    //   form: {
    //   },
    //   view: { // 查看对话框组件的单独配置
    //     disabled: true
    //   }
    // },
    {
      title: '认领用户ID',
      type: 'el-input',
      key: 'user_id',
      align: 'center',
      search: {
        disabled: false
      }
    }, {
      title: '认领规格',
      key: 'name',
      align: 'center',
      width: 220,
      type: 'radio',
      search: {
        disabled: true
      },
      // rowSlot: true,
      view: {
        // 查看对话框组件的单独配置
        disabled: false,
        component: {// name: SkuTable,
          // props: {
          //   isShow: true
          // }
        }
      } // valueBuilder (row, key) {
      //   row.sku = `${row.sku}份`
      // }

    }, {
      title: '订单状态',
      key: 'order_status',
      type: 'radio',
      align: 'center',
      dict: {
        data: ORDER_STATUS
      },
      search: {
        disabled: true,
        component: {
          name: 'dict-select'
        }
      }
    }, {
      title: '续期状态',
      key: 'renewal_status',
      type: 'radio',
      align: 'center',
      dict: {
        data: RENEWAL_STATUS
      },
      search: {
        disabled: false,
        component: {
          name: 'dict-select'
        }
      }
    }, {
      title: '创建时间',
      key: 'created_at',
      type: 'datetimerange',
      align: 'center',
      search: {
        width: 400,
        disabled: false,
        component: {
          // 查询 使用选择框组件，并且是可以清除的
          props: {
            'time-arrow-control': true,
            'default-time': ['00:00:00', '00:00:00'] // 'picker-options': { shortcuts: ShortCUTS }

          }
        }
      },
      form: {
        slot: true
      },
      valueResolve: function valueResolve(row, key) {
        var _row$created_at;

        if (((_row$created_at = row.created_at) === null || _row$created_at === void 0 ? void 0 : _row$created_at.length) > 1) {
          row.start_created_at = util.formatDate(row.created_at[0]);
          row.end_created_at = util.formatDate(row.created_at[1]);
        } else {
          row.start_created_at = null;
          row.end_created_at = null;
        }
      }
    }]
  };
};