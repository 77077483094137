var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { class: { "page-compact": _vm.crud.pageOptions.compact } },
    [
      _c(
        "d2-crud-x",
        _vm._g(
          _vm._b(
            {
              ref: "d2Crud",
              on: { "custom-list": _vm.thumbListView },
              scopedSlots: _vm._u([
                {
                  key: "created_atSlot",
                  fn: function(scope) {
                    return [
                      _c("span", [_vm._v(_vm._s(scope.row["created_at"]))])
                    ]
                  }
                },
                {
                  key: "like_hourFormSlot",
                  fn: function(scope) {
                    return [
                      _c("el-input", {
                        staticClass: "durationInput",
                        attrs: { maxlength: "6", placeholder: "默认48小时" },
                        on: {
                          blur: _vm.likeHourBlur,
                          input: function($event) {
                            return _vm.numberInput($event, "like_hour")
                          }
                        },
                        model: {
                          value: scope.form["like_hour"],
                          callback: function($$v) {
                            _vm.$set(scope.form, "like_hour", $$v)
                          },
                          expression: "scope.form['like_hour']"
                        }
                      }),
                      _c("span", [_vm._v("小时")])
                    ]
                  }
                },
                {
                  key: "merchant_priceFormSlot",
                  fn: function(scope) {
                    return [
                      _c("el-input", {
                        staticClass: "durationInput",
                        attrs: {
                          maxlength: "6",
                          disabled: scope.mode === "edit",
                          placeholder: ""
                        },
                        on: {
                          input: function($event) {
                            return _vm.floatInput($event, "merchant_price")
                          }
                        },
                        model: {
                          value: scope.form["merchant_price"],
                          callback: function($$v) {
                            _vm.$set(scope.form, "merchant_price", $$v)
                          },
                          expression: "scope.form['merchant_price']"
                        }
                      }),
                      _c("span", [_vm._v("元")])
                    ]
                  }
                },
                {
                  key: "sales_volumeFormSlot",
                  fn: function(scope) {
                    return [
                      _c("el-input", {
                        staticClass: "durationInput",
                        attrs: { maxlength: "6", placeholder: "" },
                        on: {
                          input: function($event) {
                            return _vm.numberInput($event, "sales_volume")
                          }
                        },
                        model: {
                          value: scope.form["sales_volume"],
                          callback: function($$v) {
                            _vm.$set(scope.form, "sales_volume", $$v)
                          },
                          expression: "scope.form['sales_volume']"
                        }
                      }),
                      _c("span", [_vm._v("件")])
                    ]
                  }
                },
                {
                  key: "virtual_inventoryFormSlot",
                  fn: function(scope) {
                    return [
                      _c("el-input", {
                        staticClass: "durationInput",
                        attrs: { maxlength: "6", placeholder: "" },
                        on: {
                          input: function($event) {
                            return _vm.numberInput($event, "virtual_inventory")
                          }
                        },
                        model: {
                          value: scope.form["virtual_inventory"],
                          callback: function($$v) {
                            _vm.$set(scope.form, "virtual_inventory", $$v)
                          },
                          expression: "scope.form['virtual_inventory']"
                        }
                      }),
                      _c("span", [_vm._v("件")])
                    ]
                  }
                },
                {
                  key: "sales_bonusFormSlot",
                  fn: function(scope) {
                    return [
                      _c("el-input", {
                        staticClass: "durationInput",
                        attrs: {
                          maxlength: "6",
                          disabled: scope.mode === "edit",
                          placeholder: ""
                        },
                        on: {
                          input: function($event) {
                            return _vm.floatInput($event, "sales_bonus")
                          }
                        },
                        model: {
                          value: scope.form["sales_bonus"],
                          callback: function($$v) {
                            _vm.$set(scope.form, "sales_bonus", $$v)
                          },
                          expression: "scope.form['sales_bonus']"
                        }
                      }),
                      _c("span", [_vm._v("元")])
                    ]
                  }
                },
                {
                  key: "cost_priceFormSlot",
                  fn: function(scope) {
                    return [
                      _c("el-input", {
                        staticClass: "durationInput",
                        attrs: {
                          maxlength: "6",
                          disabled: scope.mode === "edit",
                          placeholder: ""
                        },
                        on: {
                          input: function($event) {
                            return _vm.floatInput($event, "cost_price")
                          }
                        },
                        model: {
                          value: scope.form["cost_price"],
                          callback: function($$v) {
                            _vm.$set(scope.form, "cost_price", $$v)
                          },
                          expression: "scope.form['cost_price']"
                        }
                      }),
                      _c("span", [_vm._v("元")])
                    ]
                  }
                },
                {
                  key: "buy_typeFormSlot",
                  fn: function(scope) {
                    return [
                      _c("el-radio", { attrs: { value: "1", label: "1" } }, [
                        _vm._v("任意金额可购买")
                      ])
                    ]
                  }
                },
                {
                  key: "goods_idFormSlot",
                  fn: function(scope) {
                    return [
                      _c(
                        "div",
                        [
                          _c("el-input", {
                            staticClass: "goodInput",
                            attrs: {
                              disabled: scope.mode === "edit",
                              placeholder: "输入商品ID"
                            },
                            on: {
                              blur: function($event) {
                                return _vm.goodInputBlur(scope.form["goods_id"])
                              }
                            },
                            model: {
                              value: scope.form["goods_id"],
                              callback: function($$v) {
                                _vm.$set(scope.form, "goods_id", $$v)
                              },
                              expression: "scope.form['goods_id']"
                            }
                          }),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                disabled: scope.mode === "edit",
                                size: "small"
                              },
                              on: { click: _vm.addPro }
                            },
                            [_vm._v("选择商品")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.goodsName,
                              expression: "goodsName"
                            }
                          ],
                          staticStyle: { color: "#606266" }
                        },
                        [_vm._v("商品名称：" + _vm._s(_vm.goodsName))]
                      ),
                      _c(
                        "el-table",
                        {
                          staticClass: "margin-top-20",
                          staticStyle: {
                            "border-left": "1px solid #EBEEF5",
                            "margin-top": "10px"
                          },
                          attrs: {
                            data: scope.form["give_like_goods_sku"],
                            border: "",
                            "empty-text": "先添加商品，才可以编辑规格喔",
                            "header-row-style": {
                              color: scope.mode === "edit" ? "#C0C4CC" : ""
                            },
                            "header-cell-style": { textAlign: "center" },
                            "cell-style": {
                              textAlign: "center",
                              color: scope.mode === "edit" ? "#C0C4CC" : ""
                            }
                          }
                        },
                        _vm._l(_vm.skuColumnArr, function(val, index) {
                          return _c("el-table-column", {
                            key: index,
                            attrs: { prop: val.prop, label: val.label },
                            scopedSlots: _vm._u(
                              [
                                val.isEdit
                                  ? {
                                      key: "default",
                                      fn: function(scopes) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "input-box" },
                                            [
                                              _c("el-input", {
                                                attrs: { size: "small" },
                                                on: {
                                                  blur: function($event) {
                                                    return _vm.skuBlur(
                                                      $event,
                                                      scopes
                                                    )
                                                  },
                                                  input: function($event) {
                                                    return _vm.skuNumberInput(
                                                      $event,
                                                      scopes,
                                                      val.inputType
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value: scopes.row[val.prop],
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      scopes.row,
                                                      val.prop,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scopes.row[val.prop]"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  : null
                              ],
                              null,
                              true
                            )
                          })
                        }),
                        1
                      )
                    ]
                  }
                },
                {
                  key: "expandSlot",
                  fn: function(scope) {
                    return _vm._l(scope.row.goods, function(item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "expand-content" },
                        [
                          _vm._v("(" + _vm._s(index + 1) + ") "),
                          _c("span", [_vm._v("商品ID: " + _vm._s(item.id))]),
                          _c("span", { staticClass: "pro-name" }, [
                            _vm._v("商品名称：" + _vm._s(item.name))
                          ])
                        ]
                      )
                    })
                  }
                }
              ])
            },
            "d2-crud-x",
            _vm._crudProps,
            false
          ),
          _vm._crudListeners
        ),
        [
          _c(
            "div",
            { attrs: { slot: "header" }, slot: "header" },
            [
              _c("crud-search", {
                ref: "search",
                attrs: { options: _vm.crud.searchOptions },
                on: { submit: _vm.handleSearch }
              }),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.addRow }
                },
                [_c("i", { staticClass: "el-icon-plus" }), _vm._v(" 新增")]
              ),
              _c("crud-toolbar", {
                attrs: {
                  compact: _vm.crud.pageOptions.compact,
                  columns: _vm.crud.columns,
                  export: true
                },
                on: {
                  "update:compact": function($event) {
                    return _vm.$set(_vm.crud.pageOptions, "compact", $event)
                  },
                  refresh: function($event) {
                    return _vm.doRefresh()
                  },
                  export: _vm.handleExport,
                  "columns-filter-changed": _vm.handleColumnsFilterChanged
                }
              })
            ],
            1
          )
        ]
      ),
      _c("thumbListDialog", {
        attrs: { goodsId: _vm.goodsId, listDialogShow: _vm.listDialogShow },
        on: { closeListDialog: _vm.closeListDialog }
      }),
      _c("selProDialog", {
        attrs: { selProDialogShow: _vm.selProDialogShow },
        on: {
          closeSelProDialog: _vm.closeSelProDialog,
          selHrefPro: _vm.selHrefPro
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }