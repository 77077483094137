var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      staticStyle: { "border-left": "1px solid #EBEEF5" },
      attrs: { data: _vm.tableData, border: "", size: "small" }
    },
    _vm._l(_vm.columnArr, function(val, index) {
      return _c("el-table-column", {
        key: index,
        attrs: {
          prop: val.prop,
          width: val.width,
          align: "center",
          label: val.label
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }