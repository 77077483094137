export default (function (_ref) {
  var request = _ref.request;
  return {
    // 获取七牛TOKEN
    qiniuToken: function qiniuToken() {
      return request({
        url: '/api/qiniu-uptoken',
        method: 'get'
      });
    }
  };
});