import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'PreviewModel',
  props: {
    issuerInfo: {
      type: Object,
      default: function _default() {}
    },
    proImgList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    showProInfo: {
      type: Object,
      default: function _default() {}
    },
    virtualNum: {
      type: [Number, String],
      default: 0
    },
    content: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'image'
    }
  },
  computed: {
    handelProImgList: function handelProImgList() {
      var arr = this.proImgList.filter(function (val) {
        return val.url;
      }); // console.log(arr)

      return arr;
    },
    showVirtualNum: function showVirtualNum() {
      return this.virtualNum > 10000 ? this.virtualNum / 10000 + '万' : this.virtualNum;
    },
    // 判断是否展示发布人信息模块
    isShowOnePart: function isShowOnePart() {
      return this.virtualNum || JSON.stringify(this.issuerInfo) !== '{}';
    },
    // 判断是否展示展示商品模块
    isShowFourPart: function isShowFourPart() {
      return JSON.stringify(this.showProInfo) !== '{}';
    },
    // 判断是否展示空白提示
    isShowEmptyTip: function isShowEmptyTip() {
      return JSON.stringify(this.issuerInfo) === '{}' && JSON.stringify(this.showProInfo) === '{}' && !this.proImgList.length && !this.content && !this.virtualNum;
    },
    // 处理文本换行显示
    showText: function showText() {
      var arr = [];
      this.content.split('\n').forEach(function (item) {
        return arr.push("<p style=\"margin:0;word-break: break-word;\">".concat(item.trim(), "</p>"));
      }); // console.log(arr)

      return arr.join('');
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    delProImg: function delProImg(index) {
      this.$emit('delProImg', index);
    },
    // 删除版块 1=发布人 2=文案 3=商品图片 4=展示商品
    delTemplate: function delTemplate(type) {
      // console.log('删除', type)
      switch (type) {
        case 1:
          this.$emit('delPublisherPart');
          break;

        case 2:
          this.$emit('delContentPart');
          break;

        case 3:
          this.$emit('delProImgPart');
          break;

        case 4:
          this.$emit('delShowProPart');
          break;
      }
    }
  }
};