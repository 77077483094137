var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sku-box" },
    [
      _c(
        "el-button",
        {
          attrs: { type: "primary", size: "small" },
          on: {
            click: function($event) {
              return _vm.addSku(1)
            }
          }
        },
        [_vm._v("添加规格 +")]
      ),
      _vm._l(_vm.dataArr, function(val, index) {
        return _c(
          "div",
          { key: index, staticClass: "every-sku-box" },
          [
            _c("div", { staticClass: "operation-row" }, [
              _c(
                "div",
                {
                  staticClass: "left-part cursor",
                  on: {
                    click: function($event) {
                      return _vm.toCollapse(index)
                    }
                  }
                },
                [
                  val.isCollapse
                    ? _c("i", {
                        staticClass:
                          "el-icon-circle-plus-outline operation-icon"
                      })
                    : _vm._e(),
                  !val.isCollapse
                    ? _c("i", {
                        staticClass: "el-icon-remove-outline operation-icon"
                      })
                    : _vm._e(),
                  _c("span", [_vm._v("规格" + _vm._s(index + 1))])
                ]
              ),
              _c(
                "div",
                [
                  _c("el-switch", {
                    staticStyle: { "margin-right": "10px" },
                    attrs: {
                      "active-value": 1,
                      "inactive-value": 0,
                      "active-text": "上架",
                      "inactive-text": "下架"
                    },
                    on: {
                      change: function($event) {
                        return _vm.toUp($event, index)
                      }
                    },
                    model: {
                      value: val.shelf,
                      callback: function($$v) {
                        _vm.$set(val, "shelf", $$v)
                      },
                      expression: "val.shelf"
                    }
                  }),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "warning" },
                      on: {
                        click: function($event) {
                          return _vm.edit(val, index)
                        }
                      }
                    },
                    [_vm._v("编辑"), _c("i", { staticClass: "el-icon-edit" })]
                  ),
                  _vm.canEdit
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "danger" },
                          on: {
                            click: function($event) {
                              return _vm.del(index)
                            }
                          }
                        },
                        [
                          _vm._v("删除"),
                          _c("i", { staticClass: "el-icon-delete" })
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ]),
            _c("el-collapse-transition", [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !val.isCollapse,
                      expression: "!val.isCollapse"
                    }
                  ],
                  staticClass: "content-box"
                },
                [
                  _c("div", { staticClass: "every-row" }, [
                    _c("span", [_vm._v("名称：")]),
                    _c("span", [_vm._v(_vm._s(val.name))])
                  ]),
                  _c("div", { staticClass: "every-row" }, [
                    _c("span", [_vm._v("上下架状态：")]),
                    _c("span", [
                      _vm._v(_vm._s(val.shelf === 1 ? "上架" : "下架"))
                    ])
                  ]),
                  _c("div", { staticClass: "every-row" }, [
                    _c("span", [_vm._v("等级：")]),
                    _c("span", [
                      _vm._v(_vm._s(val.is_high === 1 ? "高等级" : "低等级"))
                    ])
                  ]),
                  _c("div", { staticClass: "every-row" }, [
                    _c("span", [_vm._v("规格权限：")]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          val.sku_permission_text.length
                            ? val.sku_permission_text.join("，")
                            : "暂无"
                        )
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "every-row" }, [
                    _c("span", [_vm._v("认领数量：")]),
                    _c("span", [_vm._v(_vm._s(val.number))])
                  ]),
                  _c("div", { staticClass: "every-row" }, [
                    _c("span", [_vm._v("保证金：")]),
                    _c("span", [
                      _vm._v(
                        _vm._s(val.deposit) + _vm._s(val.is_deposit ? "元" : "")
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "every-row" }, [
                    _c("span", [_vm._v("总库存：")]),
                    _c("span", [_vm._v(_vm._s(val.store) + "份")])
                  ]),
                  _c("div", { staticClass: "every-row" }, [
                    _c("span", [_vm._v("续期数量：")]),
                    _c("span", [_vm._v(_vm._s(val.renewal_count) + "份")])
                  ])
                ]
              )
            ])
          ],
          1
        )
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "destroy-on-close": "",
            center: "",
            width: "30%",
            title: "添加规格",
            visible: _vm.dialogVisible,
            modal: false
          },
          on: {
            open: _vm.open,
            close: _vm.close,
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                rules: _vm.rules,
                model: _vm.editForm,
                "label-width": "130px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "every-item",
                  attrs: { label: "规格名称：", prop: "name" }
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "12",
                      "show-word-limit": "",
                      placeholder: "限12个字"
                    },
                    model: {
                      value: _vm.editForm.name,
                      callback: function($$v) {
                        _vm.$set(_vm.editForm, "name", $$v)
                      },
                      expression: "editForm.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "every-item",
                  attrs: { label: "规格等级：", prop: "is_high" }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: !_vm.canEdit && !_vm.isAdd },
                      model: {
                        value: _vm.editForm.is_high,
                        callback: function($$v) {
                          _vm.$set(_vm.editForm, "is_high", $$v)
                        },
                        expression: "editForm.is_high"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("高等级")
                      ]),
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("低等级")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "every-item",
                  attrs: { label: "规格权限：", prop: "permission" }
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.editForm.permission,
                        callback: function($$v) {
                          _vm.$set(_vm.editForm, "permission", $$v)
                        },
                        expression: "editForm.permission"
                      }
                    },
                    _vm._l(_vm.permissionArr, function(permission, index) {
                      return _c(
                        "el-checkbox",
                        { key: index, attrs: { label: permission.id } },
                        [_vm._v(_vm._s(permission.name))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "every-item",
                  attrs: { label: "规格数量：", prop: "number" }
                },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: !_vm.canEdit && !_vm.isAdd,
                      onkeyup: "value=value.replace(/[^\\d]/g,'')",
                      placeholder: "限数字，最大100万"
                    },
                    on: { input: _vm.numberInput },
                    model: {
                      value: _vm.editForm.number,
                      callback: function($$v) {
                        _vm.$set(_vm.editForm, "number", $$v)
                      },
                      expression: "editForm.number"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "every-item",
                  attrs: { label: "是否需要保证金：", prop: "is_deposit" }
                },
                [
                  _c("el-switch", {
                    attrs: {
                      disabled: !_vm.canEdit && !_vm.isAdd,
                      "active-text": "是",
                      "inactive-text": "否"
                    },
                    on: { change: _vm.switchChange },
                    model: {
                      value: _vm.editForm.is_deposit,
                      callback: function($$v) {
                        _vm.$set(_vm.editForm, "is_deposit", $$v)
                      },
                      expression: "editForm.is_deposit"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "every-item",
                  attrs: { label: "保证金：", prop: "deposit" }
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "6",
                      disabled:
                        (!_vm.canEdit && !_vm.isAdd) || _vm.moneyDisabled,
                      placeholder: "输入商品认领保证金"
                    },
                    on: { input: _vm.depositInput },
                    model: {
                      value: _vm.editForm.deposit,
                      callback: function($$v) {
                        _vm.$set(_vm.editForm, "deposit", $$v)
                      },
                      expression: "editForm.deposit"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "every-item",
                  attrs: { label: "总库存:", prop: "store" }
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "6", placeholder: "输入总库存" },
                    on: { input: _vm.storeInput },
                    model: {
                      value: _vm.editForm.store,
                      callback: function($$v) {
                        _vm.$set(_vm.editForm, "store", $$v)
                      },
                      expression: "editForm.store"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "every-item",
                  attrs: { label: "续期数量:", prop: "renewal_count" }
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "6", placeholder: "输入续期数量" },
                    on: { input: _vm.renewalNumInput },
                    model: {
                      value: _vm.editForm.renewal_count,
                      callback: function($$v) {
                        _vm.$set(_vm.editForm, "renewal_count", $$v)
                      },
                      expression: "editForm.renewal_count"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return function() {
                        return (_vm.dialogVisible = false)
                      }.apply(null, arguments)
                    }
                  }
                },
                [_vm._v("返回")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.submitForm("form")
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }