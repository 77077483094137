import { request } from '@/api/service';
export default {
  /**
   * 获取点赞操作列表
   * http://doc.vodeshop.com/web/#/34?page_id=2963
   * @param {*} data
   */
  getRecordList: function getRecordList(params) {
    return request({
      url: '/svc/marketing-svc/admin/v1/give-like-goods-log/index',
      method: 'get',
      params: params
    });
  },

  /**
   * 操作记录详情接口
   * http://doc.vodeshop.com/web/#/34?page_id=2912
   * @param {*} data
   */
  getDetail: function getDetail(params) {
    return request({
      url: '/svc/marketing-svc/admin/v1/give-like-goods-log/show',
      method: 'get',
      params: params
    });
  }
};