import "core-js/modules/es.array.concat.js";
import { request } from '@/api/service';
export default {
  // 获取权限模板列表
  GetPermissionList: function GetPermissionList(data) {
    return request({
      url: "/svc/marketing-svc/admin/v1/template/list?page=".concat(data.page, "&per_page=").concat(data.per_page),
      method: 'get'
    });
  },
  // 获取某个权限详情
  GetPermissionDetail: function GetPermissionDetail(data) {
    return request({
      url: "/svc/marketing-svc/admin/v1/template/show-data?id=".concat(data.id),
      method: 'get'
    });
  },
  // 新增或者编辑权限
  AddAndEditPermission: function AddAndEditPermission(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/template/store',
      method: 'post',
      data: data
    });
  },
  // 修改权限状态
  EditPermissionStatus: function EditPermissionStatus(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/template/status',
      method: 'post',
      data: data
    });
  },
  // 删除权限模板
  DeletePermission: function DeletePermission(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/template/delete',
      method: 'post',
      data: data
    });
  }
};