import * as DIST from './dist';
import util from '@/libs/util';
export var crudOptions = {
  // rowHandle: {
  //   columnHeader: '操作',
  //   width: 100,
  //   custom: [
  //     {
  //       text: ' 自定义',
  //       type: 'warning',
  //       size: 'small',
  //       emit: 'customHandleBtn',
  //       icon: 'el-icon-s-flag'
  //     }
  //   ],
  //   fixed: 'right' // 右边固定列
  // },
  // indexRow: {
  //   headerAlign: 'center',
  //   align: 'center'
  // },
  rowHandle: false,
  searchOptions: {// columns: [],
    // defaultSpan: 12,
    // span: 12
  },
  options: {
    height: '100%',
    // 表格高度100%, 使用toolbar必须设置
    rowKey: 'id',
    stripe: true
  },
  viewOptions: {
    componentType: 'row'
  },
  pageOptions: {
    compact: true // 是否紧凑型页面

  },
  columns: [{
    title: 'ID',
    key: 'id',
    width: 90,
    // fixed: 'left',
    align: 'center',
    span: '12',
    search: {
      key: 'id',
      disabled: false,
      props: {
        span: 12
      }
    },
    form: {
      // disabled: true
      component: {
        show: false
      }
    }
  }, {
    title: '参与人',
    key: 'user_name',
    // sortable: true,
    align: 'center',
    search: {
      key: 'user_name',
      disabled: false
    } // fixed: 'left',

  }, {
    title: '参与人id',
    key: 'user_id',
    // sortable: true,
    align: 'center',
    disabled: true,
    // 设置true可以在行展示中隐藏
    search: {
      key: 'user_id',
      disabled: false
    } // fixed: 'left',

  }, {
    title: '活动名称',
    key: 'title',
    align: 'center',
    width: 500,
    type: 'text',
    search: {
      key: 'title',
      disabled: false
    }
  }, {
    title: '支付时间',
    type: 'datetimerange',
    sortable: true,
    key: 'payDate',
    align: 'center',
    search: {
      disabled: false,
      width: 400
    },
    formatter: function formatter(row) {
      return row.pay_time;
    },
    valueResolve: function valueResolve(row, key) {
      var _row$payDate;

      if (((_row$payDate = row.payDate) === null || _row$payDate === void 0 ? void 0 : _row$payDate.length) > 1) {
        row.pay_start_at = util.formatDate(row.payDate[0]);
        row.pay_end_at = util.formatDate(row.payDate[1]);
      } else {
        row.pay_start_at = null;
        row.pay_end_at = null;
      }
    }
  }, {
    title: '报名时间',
    key: 'sign_at',
    sortable: true,
    align: 'center',
    type: 'datetimerange',
    search: {
      disabled: false,
      width: 400
    },
    valueResolve: function valueResolve(row, key) {
      var _row$sign_at;

      if (((_row$sign_at = row.sign_at) === null || _row$sign_at === void 0 ? void 0 : _row$sign_at.length) > 1) {
        row.sign_start_at = util.formatDate(row.sign_at[0]);
        row.sign_end_at = util.formatDate(row.sign_at[1]);
      } else {
        row.sign_start_at = null;
        row.sign_end_at = null;
      }
    } // fixed: 'right'

  }, {
    title: '是否付费',
    key: 'is_toll',
    type: 'select',
    align: 'center',
    dict: {
      data: DIST.DICT_YES_NO
    }
  }, {
    title: '活动状态',
    key: 'status',
    type: 'select',
    align: 'center',
    form: {
      component: {
        props: {
          filterable: true,
          multiple: false,
          clearable: true
        }
      }
    },
    dict: {
      data: DIST.ACTIVITY_STATUS
    }
  }, {
    title: '活动ID',
    key: ' marketing_activity_id',
    disabled: true,
    // 设置true可以在行展示中隐藏
    search: {
      key: 'marketing_activity_id',
      disabled: false
    }
  }]
};