// 订单状态
export var ORDER_STATUS = [{
  value: 1,
  label: '众创中'
}, {
  value: 2,
  label: '审核中'
}, {
  value: 3,
  label: '已结束'
}, {
  value: 0,
  label: '未付款'
}];
export var RENEWAL_STATUS = [{
  value: 1,
  label: '续期审核中'
}, {
  value: 2,
  label: '审核通过'
}, {
  value: 3,
  label: '审核不通过'
}, {
  value: 0,
  label: '未续期'
}];