import { request } from '@/api/service';
export default {
  /**
   * 获取回归用户明细
   * http://doc.vodeshop.com/web/#/34?page_id=2808
   * @param {*} data
   */
  getUserList: function getUserList(params) {
    return request({
      url: '/svc/marketing-svc/admin/v1/regress/invite-user/list',
      method: 'get',
      params: params
    });
  }
};