import { request } from '@/api/service';
export default {
  // 内容管理树 http://doc.vodeshop.com/web/#/34?page_id=2815
  GetContentData: function GetContentData() {
    return request({
      url: '/svc/marketing-svc/admin/v1/content-management/tree',
      method: 'get'
    });
  },
  // 内容类型创建/编辑（一级） http://doc.vodeshop.com/web/#/34?page_id=2816
  createParent: function createParent(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/content-management/type-store',
      method: 'post',
      data: data
    });
  },
  // 内容类型删除（一级） http://doc.vodeshop.com/web/#/34?page_id=2817
  deleteParent: function deleteParent(id) {
    return request({
      url: "/svc/marketing-svc/admin/v1/content-management/type-delete/".concat(id),
      method: 'delete'
    });
  },
  // 内容添加/编辑(二级) http://doc.vodeshop.com/web/#/34?page_id=2817
  createLeaf: function createLeaf(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/content-management/content-store',
      method: 'post',
      data: data
    });
  },
  // 内容删除(二级) http://doc.vodeshop.com/web/#/34?page_id=2821
  deleteLeaf: function deleteLeaf(id) {
    return request({
      url: "/svc/marketing-svc/admin/v1/content-management/content-delete/".concat(id),
      method: 'delete'
    });
  },
  // 内容批量禁用/启用  http://doc.vodeshop.com/web/#/34?page_id=2822
  updateStatus: function updateStatus(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/content-management/batch-status-set',
      method: 'post',
      data: data
    });
  },
  // 内容批量删除  http://doc.vodeshop.com/web/#/34?page_id=2821
  batchDelete: function batchDelete(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/content-management/batch-content-delete',
      method: 'post',
      data: data
    });
  }
};