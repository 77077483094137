import { request } from '@/api/service';
export default {
  // 获取发布人列表（分页）
  GetIssuerList: function GetIssuerList(params) {
    return request({
      url: '/svc/marketing-svc/admin/v1/pink-circle-user/index',
      method: 'get',
      params: params
    });
  },
  // 添加一个发布人
  AddIssuer: function AddIssuer(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/pink-circle-user/create',
      method: 'post',
      data: data
    });
  },
  // 编辑发布人
  UpdateIssuer: function UpdateIssuer(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/pink-circle-user/update',
      method: 'put',
      data: data
    });
  },
  // 删除一个发布人
  DeleteIssuer: function DeleteIssuer(params) {
    return request({
      url: "/svc/marketing-svc/admin/v1/pink-circle-user/delete?id=".concat(params),
      method: 'delete'
    });
  },
  // 更改发布人状态
  UpdateIssuerStatus: function UpdateIssuerStatus(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/pink-circle-user/enable',
      method: 'put',
      data: data
    });
  }
};