import { request } from '@/api/service';
export default {
  /**
   * 获取点赞商品列表
   * http://doc.vodeshop.com/web/#/34?page_id=2886
   * @param {*} data
   */
  getProductList: function getProductList(params) {
    return request({
      url: '/svc/marketing-svc/admin/v1/give-like-goods/index',
      method: 'get',
      params: params
    });
  },

  /**
   * 创建点赞商品
   *http://doc.vodeshop.com/web/#/34?page_id=2893
   * @param {*} data
   */
  addProduct: function addProduct(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/give-like-goods/create',
      method: 'post',
      data: data
    });
  },

  /**
   * 编辑点赞商品
   * http://doc.vodeshop.com/web/#/34?page_id=2894
   * @param {*} data
   */
  editProduct: function editProduct(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/give-like-goods/update',
      method: 'put',
      data: data
    });
  },

  /**
   * 编辑商品启用状态 接口
   * http://doc.vodeshop.com/web/#/34?page_id=2895
   * @param {*} data
   */
  editProductStatus: function editProductStatus(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/give-like-goods/enable',
      method: 'put',
      data: data
    });
  },

  /**
   * 删除点赞商品
   * http://doc.vodeshop.com/web/#/34?page_id=2896
   * @param {*} data
   */
  deleteProduct: function deleteProduct(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/give-like-goods/delete',
      method: 'delete',
      data: data
    });
  },
  // 获取商品详情
  GetProDetail: function GetProDetail(id) {
    return request({
      url: "/svc/marketing-svc/admin/v1/goods/show?goods_id=".concat(id),
      method: 'get'
    });
  },

  /**
   * 搜索商品
   * http://doc.vodeshop.com/web/#/34?page_id=2897
   * @param {*} data
   */
  getSearchProList: function getSearchProList(params) {
    return request({
      url: '/svc/marketing-svc/admin/v1/give-like-goods/search-goods',
      method: 'get',
      params: params
    });
  },

  /**
   * 获取所有分类
   * http://doc.vodeshop.com/web/#/34?page_id=2897
   * @param {*} data
   */
  getAllClassifyList: function getAllClassifyList() {
    return request({
      url: '/svc/marketing-svc/admin/v1/give-like-goods-category/all',
      method: 'get'
    });
  }
};