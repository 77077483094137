export var ACTIVITY_STATUS = [{
  value: '0',
  label: '待报名'
}, {
  value: '1',
  label: '报名中'
}, {
  value: '2',
  label: '待进行'
}, {
  value: '3',
  label: '进行中'
}, {
  value: '4',
  label: '已结束'
}]; // 是否字典

export var DICT_YES_NO = [{
  value: '1',
  label: '是'
}, {
  value: '0',
  label: '否'
}];