// 状态字典
export var DICT_STATUS = [{
  value: 1,
  label: '启用'
}, {
  value: 0,
  label: '禁用'
}]; // 订单状态

export var ORDER_STATUS = [{
  value: 1,
  label: '众创中'
}, {
  value: 2,
  label: '审核中'
}, {
  value: 3,
  label: '已结束'
}, {
  value: 0,
  label: '未付款'
}]; // 权限

export var LIMIT_STATUS = [{
  name: 'saver会员',
  id: 'saver会员'
}, {
  name: '三星服务商',
  id: '三星服务商'
}, {
  name: '五星服务商',
  id: '五星服务商'
}, {
  name: '七星服务商',
  id: '七星服务商'
}];