import { request } from '@/api/service';
export default {
  /**
   * 获取众筹商品列表
   * http://doc.vodeshop.com/web/#/34?page_id=2731
   * @param {*} data
   */
  getProductList: function getProductList(params) {
    return request({
      url: '/svc/marketing-svc/admin/v1/raise-goods/index',
      method: 'get',
      params: params
    });
  },

  /**
   * 创建众筹商品
   * http://doc.vodeshop.com/web/#/34?page_id=2732
   * @param {*} data
   */
  addProduct: function addProduct(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/raise-goods/store',
      method: 'post',
      data: data
    });
  },

  /**
   * 编辑众筹商品
   * http://doc.vodeshop.com/web/#/34?page_id=2733
   * @param {*} data
   */
  editProduct: function editProduct(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/raise-goods/update',
      method: 'put',
      data: data
    });
  },

  /**
   * 编辑众筹商品状态
   * http://doc.vodeshop.com/web/#/34?page_id=2939
   * @param {*} data
   */
  editProductStatus: function editProductStatus(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/raise-goods/enable',
      method: 'put',
      data: data
    });
  },

  /**
   * 删除众筹商品
   * http://doc.vodeshop.com/web/#/34?page_id=2735
   * @param {*} data
   */
  deleteProduct: function deleteProduct(id) {
    return request({
      url: "/svc/marketing-svc/admin/v1/raise-goods/delete?id=".concat(id),
      method: 'delete'
    });
  },
  // 获取商品详情
  GetProDetail: function GetProDetail(id) {
    return request({
      url: "/svc/marketing-svc/admin/v1/goods/show?goods_id=".concat(id),
      method: 'get'
    });
  }
};