import { request } from '@/api/service';
export default {
  /**
   * 编辑点个赞设置 接口
   * http://doc.vodeshop.com/web/#/34?page_id=2901
   * @param {*} data
   */
  editSetting: function editSetting(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/give-like-setting/update',
      method: 'put',
      data: data
    });
  },

  /**
   * 点个赞设置详情 接口
   * http://doc.vodeshop.com/web/#/34?page_id=2900
   * @param {*} data
   */
  getSettingDetail: function getSettingDetail() {
    return request({
      url: '/svc/marketing-svc/admin/v1/give-like-setting/show',
      method: 'get'
    });
  },

  /**
   * 获取所有优惠券礼包 接口
   * http://doc.vodeshop.com/web/#/34?page_id=2805
   * @param {*} data
   */
  getAllGift: function getAllGift() {
    return request({
      url: '/svc/marketing-svc/admin/v1/regress/gift/get-all',
      method: 'get'
    });
  }
};