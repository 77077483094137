import _createForOfIteratorHelper from "/www/wwwroot/test.admin.vodeshop.com/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper";
import _toConsumableArray from "/www/wwwroot/test.admin.vodeshop.com/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.split.js";
import { mock } from '@/api/service';
import * as tools from '@/api/tools';

var req = function req(context) {
  return context.keys().map(context);
};

var apiList1 = req(require.context('./api/', true, /\.js$/)).filter(function (e) {
  return e.default;
}).map(function (e) {
  return e.default;
}); // 模拟数据

var apiList = req(require.context('../../views/', true, /mock\.js$/)).filter(function (e) {
  return e.default;
}).map(function (e) {
  return e.default;
});
apiList.push.apply(apiList, _toConsumableArray(apiList1));
apiList.forEach(function (apiFile) {
  var _iterator = _createForOfIteratorHelper(apiFile),
      _step;

  try {
    var _loop = function _loop() {
      var item = _step.value;
      mock.onAny(new RegExp('/api' + item.path)).reply(function (config) {
        console.log('request:', config);
        var data = config.data ? JSON.parse(config.data) : {};
        var query = config.url.indexOf('?') >= 0 ? config.url.substring(config.url.indexOf('?') + 1) : undefined;
        var params = {};

        if (query) {
          var arr = query.split('&');

          var _iterator2 = _createForOfIteratorHelper(arr),
              _step2;

          try {
            for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
              var _item = _step2.value;

              var kv = _item.split('=');

              params[kv[0]] = kv[1];
            }
          } catch (err) {
            _iterator2.e(err);
          } finally {
            _iterator2.f();
          }
        } else {
          params = data;
        }

        var req = {
          body: data,
          params: params
        };
        var ret = item.handle(req);
        console.log('response:', ret);

        if (ret.code === 0) {
          return tools.responseSuccess(ret.data, ret.msg);
        } else {
          return tools.responseError(ret.data, ret.msg, ret.code);
        }
      });
    };

    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      _loop();
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
});