var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { staticClass: "flex justify-center", attrs: { span: 10 } },
            [
              _c("preview-model", {
                attrs: {
                  type: "video",
                  virtualNum: _vm.formData.pink_circle_fictitious_forward,
                  issuerInfo: _vm.issuerInfo,
                  proImgList: _vm.formData.media,
                  content: _vm.formData.content,
                  showProInfo: _vm.showProInfo
                },
                on: {
                  delProImg: _vm.delProImg,
                  delPublisherPart: _vm.delPublisherPart,
                  delContentPart: _vm.delContentPart,
                  delProImgPart: _vm.delProImgPart,
                  delShowProPart: _vm.delShowProPart
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "flex justify-center", attrs: { span: 10 } },
            [
              _c("div", { staticClass: "publish-box" }, [
                _c("div", { staticClass: "title color-333 font-bold" }, [
                  _vm._v("爱粉圈素材编辑区")
                ]),
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "color-666 font-size-9 mb-9 text-center" },
                    [_vm._v("素材基础信息")]
                  ),
                  _c("div", { staticClass: "flex align-center mb-9" }, [
                    _c("div", { staticClass: "label color-333 font-size-8" }, [
                      _c("span", { staticClass: "red-tip" }, [_vm._v("*")]),
                      _vm._v("素材名称：")
                    ]),
                    _c(
                      "div",
                      { staticClass: "flex-sub" },
                      [
                        _c("el-input", {
                          staticClass: "input-width-100",
                          attrs: {
                            "show-word-limit": "",
                            maxlength: "10",
                            size: "mini"
                          },
                          model: {
                            value: _vm.formData.name,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "name", $$v)
                            },
                            expression: "formData.name"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "flex align-center mb-9" }, [
                    _c("div", { staticClass: "label color-333 font-size-8" }, [
                      _c("span", { staticClass: "red-tip" }, [_vm._v("*")]),
                      _vm._v("素材分类：")
                    ]),
                    _c(
                      "div",
                      { staticClass: "flex-sub" },
                      [
                        _c("el-cascader", {
                          staticClass: "input-width-100",
                          attrs: {
                            size: "mini",
                            options: _vm.materailClassList
                          },
                          model: {
                            value: _vm.materailClassValue,
                            callback: function($$v) {
                              _vm.materailClassValue = $$v
                            },
                            expression: "materailClassValue"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "flex align-center mb-9" }, [
                    _c("div", { staticClass: "label color-333 font-size-8" }, [
                      _c("span", { staticClass: "red-tip" }, [_vm._v("*")]),
                      _vm._v("权限模板：")
                    ]),
                    _c(
                      "div",
                      { staticClass: "flex-sub" },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "input-width-100",
                            attrs: { size: "mini" },
                            model: {
                              value: _vm.formData.pink_circle_competence_id,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "pink_circle_competence_id",
                                  $$v
                                )
                              },
                              expression: "formData.pink_circle_competence_id"
                            }
                          },
                          _vm._l(_vm.permissionOption, function(item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.title, value: item.id }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "flex align-center mb-9" }, [
                    _c("div", { staticClass: "label color-333 font-size-8" }, [
                      _vm._v("虚拟转发：")
                    ]),
                    _c(
                      "div",
                      { staticClass: "flex-sub" },
                      [
                        _c("el-input", {
                          staticClass: "input-width-100",
                          attrs: { type: "number", size: "mini" },
                          model: {
                            value: _vm.formData.pink_circle_fictitious_forward,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formData,
                                "pink_circle_fictitious_forward",
                                $$v
                              )
                            },
                            expression:
                              "formData.pink_circle_fictitious_forward"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "color-666 font-size-9 mb-9 text-center" },
                    [_vm._v("素材图文信息")]
                  ),
                  _c("div", { staticClass: "flex align-center mb-9" }, [
                    _c("div", { staticClass: "label color-333 font-size-8" }, [
                      _c("span", { staticClass: "red-tip" }, [_vm._v("*")]),
                      _vm._v("发布人：")
                    ]),
                    _c(
                      "div",
                      { staticClass: "flex-sub" },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "input-width-100",
                            attrs: { size: "mini" },
                            on: { change: _vm.publisherChange },
                            model: {
                              value: _vm.formData.pink_circle_user_id,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "pink_circle_user_id",
                                  $$v
                                )
                              },
                              expression: "formData.pink_circle_user_id"
                            }
                          },
                          _vm._l(_vm.publisherOption, function(item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: {
                                label: item.name,
                                disabled: !item.is_enable,
                                value: item.id
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "flex align-center mb-9" }, [
                    _c("div", { staticClass: "label color-333 font-size-8" }, [
                      _vm._v("展示商品：")
                    ]),
                    _c(
                      "div",
                      { staticClass: "flex-sub" },
                      [
                        _c("el-input", {
                          staticClass: "input-width-100",
                          attrs: { placeholder: "输入商品id", size: "mini" },
                          on: { change: _vm.inputShowProId },
                          model: {
                            value: _vm.formData.goods_id,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "goods_id", $$v)
                            },
                            expression: "formData.goods_id"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "btn ml-5 cursor",
                        on: {
                          click: function($event) {
                            return _vm.choosePro()
                          }
                        }
                      },
                      [_vm._v("选择商品")]
                    )
                  ]),
                  _vm.idIsError
                    ? _c("div", { staticClass: "flex align-center mb-9" }, [
                        _c("div", {
                          staticClass: "label color-333 font-size-8"
                        }),
                        _c("div", { staticClass: "ml-5 red-tip font-size-8" }, [
                          _vm._v("请输入正确的商品ID")
                        ])
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "color-333 font-size-8 mb-9" }, [
                    _vm._v(
                      "已绑商品:" +
                        _vm._s(_vm.showProInfo.name || "暂未绑定商品")
                    )
                  ]),
                  _c("div", { staticClass: "flex align-center mb-9" }, [
                    _c("div", { staticClass: "label color-333 font-size-8" }, [
                      _c("span", { staticClass: "red-tip" }, [_vm._v("*")]),
                      _vm._v("文本内容：")
                    ]),
                    _c(
                      "div",
                      { staticClass: "flex-sub" },
                      [
                        _c("el-input", {
                          staticClass: "input-width-100",
                          attrs: { type: "textarea", rows: 8, size: "mini" },
                          model: {
                            value: _vm.formData.content,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "content", $$v)
                            },
                            expression: "formData.content"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "flex align-center mb-9" }, [
                    _c("div", { staticClass: "label color-333 font-size-8" }, [
                      _c("span", { staticClass: "red-tip" }, [_vm._v("*")]),
                      _vm._v("上传视频：")
                    ]),
                    _c(
                      "div",
                      { staticClass: "flex-sub" },
                      [
                        !_vm.formData.media.length && !_vm.uploadLoading
                          ? _c(
                              "el-upload",
                              {
                                staticClass: "upload-btn",
                                attrs: {
                                  action: _vm.QINIUURL,
                                  data: _vm.dataToken,
                                  "show-file-list": false,
                                  "on-success": _vm.uploadProImgSuccess,
                                  "before-upload": _vm.beforeUpload
                                }
                              },
                              [_c("i", { staticClass: "el-icon-plus" })]
                            )
                          : _vm._e(),
                        _vm.formData.media.length && !_vm.uploadLoading
                          ? _c(
                              "div",
                              {
                                staticClass: "upload-btn cursor",
                                on: { click: _vm.controlUploadProNum }
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-plus color-666"
                                })
                              ]
                            )
                          : _vm._e(),
                        _vm.uploadLoading
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "upload-btn cursor flex flex-direction-column align-center justify-center"
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "color-666 font-size-8",
                                    staticStyle: { "line-height": "20px" }
                                  },
                                  [_vm._v("上传中")]
                                ),
                                _c("i", {
                                  staticClass: "el-icon-loading color-666"
                                })
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "submit-btn-box pb-20" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: { click: _vm.save }
                      },
                      [_vm._v(_vm._s(_vm.id ? "修改" : "保存"))]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      ),
      _c("SelProDialog", {
        attrs: { selProDialogShow: _vm.selProDialogShow },
        on: {
          selHrefPro: _vm.selHrefPro,
          closeSelProDialog: _vm.closeSelProDialog
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }