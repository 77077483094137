var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      staticStyle: { "border-left": "1px solid #EBEEF5" },
      style: { color: _vm.isShow ? "#C0C4CC" : "" },
      attrs: {
        data: _vm.value,
        border: "",
        "header-row-style": { color: _vm.isShow ? "#C0C4CC" : "" }
      }
    },
    [
      _c("el-table-column", {
        attrs: { prop: "sales_volume", label: "已售数量(份)", width: "180" }
      }),
      _c("el-table-column", {
        attrs: {
          prop: "total_last_number",
          label: "剩余库存(份)",
          width: "180"
        }
      }),
      _c("el-table-column", {
        attrs: { prop: "profit_status", label: "保证金" }
      }),
      _c("el-table-column", {
        attrs: { prop: "profit_price", label: "已返利" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }