//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    tableData: {
      type: Array,
      required: true
    },
    columnArr: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  watch: {},
  methods: {}
};