var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { class: { "page-compact": _vm.crud.pageOptions.compact } },
    [
      _c(
        "d2-crud-x",
        _vm._g(
          _vm._b(
            {
              ref: "d2Crud",
              scopedSlots: _vm._u([
                {
                  key: "regress_gift.titleSlot",
                  fn: function(scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            scope.row.is_gift === 1
                              ? scope.row.regress_gift.title
                              : "未关联"
                          )
                        )
                      ])
                    ]
                  }
                },
                {
                  key: "created_atSlot",
                  fn: function(scope) {
                    return [
                      _c("span", [_vm._v(_vm._s(scope.row["created_at"]))])
                    ]
                  }
                },
                {
                  key: "awardFormSlot",
                  fn: function(scope) {
                    return [
                      _c("el-input", {
                        staticClass: "small-input",
                        staticStyle: { width: "220px", "margin-right": "10px" },
                        attrs: { placeholder: "输入奖励比例" },
                        on: { input: _vm.floatNumberInput },
                        model: {
                          value: scope.form["award"],
                          callback: function($$v) {
                            _vm.$set(scope.form, "award", $$v)
                          },
                          expression: "scope.form['award']"
                        }
                      }),
                      _c("span", [_vm._v("%")])
                    ]
                  }
                },
                {
                  key: "new_user_dayFormSlot",
                  fn: function(scope) {
                    return [
                      _c("el-input", {
                        staticClass: "small-input",
                        attrs: {
                          disabled: scope.mode === "edit",
                          placeholder: "输入天数"
                        },
                        on: { input: _vm.numberInput },
                        model: {
                          value: scope.form["new_user_day"],
                          callback: function($$v) {
                            _vm.$set(scope.form, "new_user_day", $$v)
                          },
                          expression: "scope.form['new_user_day']"
                        }
                      }),
                      _c("span", [_vm._v("天")])
                    ]
                  }
                },
                {
                  key: "content_management.content_keyFormSlot",
                  fn: function(scope) {
                    return [
                      _c("el-input", {
                        staticClass: "small-input",
                        attrs: { placeholder: "输入活动规则ID" },
                        on: {
                          blur: function($event) {
                            return _vm.ruleIdBlur(
                              scope.form["content_management"].content_key
                            )
                          },
                          input: _vm.ruleIdInput
                        },
                        model: {
                          value: scope.form["content_management"].content_key,
                          callback: function($$v) {
                            _vm.$set(
                              scope.form["content_management"],
                              "content_key",
                              $$v
                            )
                          },
                          expression:
                            "scope.form['content_management'].content_key"
                        }
                      }),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: scope.mode === "edit",
                            type: "primary",
                            size: "mini",
                            round: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.selDialog(2)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.ruleList.length ? "选择规则" : "去添加规则"
                            )
                          )
                        ]
                      ),
                      _vm.ruleName
                        ? _c(
                            "span",
                            { staticClass: "red-label margin-left-20" },
                            [_vm._v("名称：" + _vm._s(_vm.ruleName || "暂无"))]
                          )
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "regress_gift.idFormSlot",
                  fn: function(scope) {
                    return [
                      _c("el-input", {
                        staticClass: "small-input",
                        attrs: {
                          disabled:
                            scope.form["is_gift"] == 0 || scope.mode === "edit",
                          placeholder: "输入回归礼包ID"
                        },
                        on: {
                          blur: function($event) {
                            return _vm.giftIdBlur(scope.form["regress_gift"].id)
                          },
                          input: _vm.giftIdInput
                        },
                        model: {
                          value: scope.form["regress_gift"].id,
                          callback: function($$v) {
                            _vm.$set(scope.form["regress_gift"], "id", $$v)
                          },
                          expression: "scope.form['regress_gift'].id"
                        }
                      }),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            disabled:
                              scope.form["is_gift"] === 0 ||
                              scope.mode === "edit",
                            type: "primary",
                            size: "mini",
                            round: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.selDialog(1)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.giftList.length ? "选择礼包" : "去添加礼包"
                            )
                          )
                        ]
                      ),
                      _vm.giftName
                        ? _c(
                            "span",
                            { staticClass: "red-label margin-left-20" },
                            [_vm._v("名称：" + _vm._s(_vm.giftName || "暂无"))]
                          )
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "is_red_packetFormSlot",
                  fn: function(scope) {
                    return [
                      _c(
                        "div",
                        [
                          _c("el-switch", {
                            attrs: {
                              disabled: scope.mode === "edit",
                              "active-text": "是",
                              "inactive-text": "否"
                            },
                            on: { change: _vm.isRedChange },
                            model: {
                              value: scope.form.is_red_packet,
                              callback: function($$v) {
                                _vm.$set(scope.form, "is_red_packet", $$v)
                              },
                              expression: "scope.form.is_red_packet"
                            }
                          }),
                          _c(
                            "el-button",
                            {
                              staticClass: "margin-left-20",
                              attrs: {
                                disabled:
                                  !scope.form.is_red_packet ||
                                  scope.mode === "edit" ||
                                  _vm.redPacketList.length >= 8,
                                type: "primary",
                                size: "mini",
                                round: ""
                              },
                              on: { click: _vm.addPacket }
                            },
                            [_vm._v("+新增红包")]
                          ),
                          _c("span", { staticClass: "tip-text" }, [
                            _vm._v("注意：可添加最多8个红包")
                          ])
                        ],
                        1
                      ),
                      _vm._l(_vm.redPacketList, function(item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "every-packet" },
                          [
                            _c("span", { staticClass: "red-label" }, [
                              _vm._v("红包" + _vm._s(index + 1))
                            ]),
                            _c("el-input", {
                              staticClass: "small-input",
                              attrs: {
                                disabled: scope.mode === "edit",
                                placeholder: "输入红包ID"
                              },
                              on: {
                                blur: function($event) {
                                  return _vm.redPacketBlur(item.id, index)
                                },
                                input: function($event) {
                                  return _vm.redIdInput($event, index)
                                }
                              },
                              model: {
                                value: item.id,
                                callback: function($$v) {
                                  _vm.$set(item, "id", $$v)
                                },
                                expression: "item.id"
                              }
                            }),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled: scope.mode === "edit",
                                  type: "primary",
                                  size: "mini",
                                  round: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.selDialog(3, index)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.packetList.length
                                      ? "选择红包"
                                      : "去添加红包"
                                  )
                                )
                              ]
                            ),
                            _c("el-button", {
                              attrs: {
                                disabled: scope.mode === "edit",
                                size: "mini",
                                type: "danger",
                                icon: "el-icon-delete",
                                circle: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.delPacket(index)
                                }
                              }
                            }),
                            item.name
                              ? _c(
                                  "span",
                                  { staticClass: "red-label margin-left-20" },
                                  [
                                    _vm._v(
                                      "名称：" + _vm._s(item.name || "暂无")
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      })
                    ]
                  }
                },
                {
                  key: "red_packetSlot",
                  fn: function(scope) {
                    return [
                      scope.row.is_red_packet === 1
                        ? _c(
                            "div",
                            _vm._l(scope.row.red_packet, function(val, index) {
                              return _c(
                                "el-tag",
                                {
                                  key: index,
                                  staticStyle: { "margin-right": "5px" },
                                  attrs: { type: "warning", size: "mini" }
                                },
                                [_vm._v(_vm._s(val.name))]
                              )
                            }),
                            1
                          )
                        : _c("span", [_vm._v("未关联")])
                    ]
                  }
                }
              ])
            },
            "d2-crud-x",
            _vm._crudProps,
            false
          ),
          _vm._crudListeners
        ),
        [
          _c(
            "div",
            { attrs: { slot: "header" }, slot: "header" },
            [
              _c("crud-search", {
                ref: "search",
                attrs: { options: _vm.crud.searchOptions },
                on: { submit: _vm.handleSearch }
              }),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.addRow }
                },
                [_c("i", { staticClass: "el-icon-plus" }), _vm._v(" 新增")]
              ),
              _c("crud-toolbar", {
                attrs: {
                  compact: _vm.crud.pageOptions.compact,
                  columns: _vm.crud.columns
                },
                on: {
                  "update:compact": function($event) {
                    return _vm.$set(_vm.crud.pageOptions, "compact", $event)
                  },
                  refresh: function($event) {
                    return _vm.doRefresh()
                  },
                  "columns-filter-changed": _vm.handleColumnsFilterChanged
                }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.dialogTitle, visible: _vm.selDialogVisible },
          on: {
            "update:visible": function($event) {
              _vm.selDialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择" },
              model: {
                value: _vm.selValue,
                callback: function($$v) {
                  _vm.selValue = $$v
                },
                expression: "selValue"
              }
            },
            _vm._l(_vm.selectList, function(val, index) {
              return _c("el-option", {
                key: index,
                attrs: {
                  disabled: val.disabled,
                  label: val.name || val.title,
                  value: val.id
                }
              })
            }),
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.selDialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.selConfirm } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }