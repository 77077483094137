import util from '@/libs/util';
export var crudOptions = function crudOptions(vm) {
  return {
    options: {
      height: '100%',
      // 表格高度100%,使用toolbar必须设置
      rowKey: 'id',
      stripe: true
    },
    viewOptions: {
      disabled: false
    },
    rowHandle: false,
    columns: [{
      title: 'ID',
      key: 'id',
      width: '100',
      type: 'el-input',
      align: 'center',
      search: {
        disabled: true
      }
    }, {
      title: '用户ID',
      key: 'user_id',
      width: '100',
      type: 'el-input',
      align: 'center',
      search: {
        disabled: false
      }
    }, {
      title: '活动ID',
      key: 'activity_id',
      width: '100',
      align: 'center',
      type: 'el-input',
      search: {
        disabled: false
      }
    }, {
      title: '活动名称',
      key: 'activity_name',
      type: 'el-input',
      align: 'center',
      search: {
        disabled: false
      }
    }, {
      title: '红包领取时间',
      key: 'packet_get_time',
      type: 'datetimerange',
      align: 'center',
      search: {
        width: 400,
        disabled: true
      },
      valueResolve: function valueResolve(row, key) {// if (row.created_at?.length > 1) {
        //   row.start_created_at = util.formatDate(row.created_at[0])
        //   row.end_created_at = util.formatDate(row.created_at[1])
        // }
      }
    }, {
      title: '来源用户ID',
      key: 'user_source_id',
      type: 'el-input',
      width: '100',
      align: 'center',
      search: {
        disabled: false
      }
    }, {
      title: '创建时间',
      key: 'created_at',
      type: 'datetimerange',
      align: 'center',
      search: {
        width: 400,
        disabled: false,
        component: {
          props: {
            'time-arrow-control': true,
            'default-time': ['00:00:00', '00:00:00'] // 'picker-options': { shortcuts: ShortCUTS }

          }
        }
      },
      valueResolve: function valueResolve(row, key) {
        var _row$created_at;

        if (((_row$created_at = row.created_at) === null || _row$created_at === void 0 ? void 0 : _row$created_at.length) > 1) {
          row.start_created_at = util.formatDate(row.created_at[0]);
          row.end_created_at = util.formatDate(row.created_at[1]);
        }
      }
    }]
  };
};