var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-login" },
    [
      _c("div", { staticClass: "page-login--layer page-login--layer-area" }, [
        _c(
          "ul",
          { staticClass: "circles" },
          _vm._l(10, function(n) {
            return _c("li", { key: n })
          }),
          0
        )
      ]),
      _c(
        "div",
        {
          staticClass: "page-login--layer page-login--layer-time",
          attrs: { flex: "main:center cross:center" }
        },
        [_vm._v(" " + _vm._s(_vm.time) + " ")]
      ),
      _c("div", { staticClass: "page-login--layer" }, [
        _c(
          "div",
          {
            staticClass: "page-login--content",
            attrs: { flex: "dir:top main:justify cross:stretch box:justify" }
          },
          [
            _vm._m(0),
            _c(
              "div",
              {
                staticClass: "page-login--content-main",
                attrs: { flex: "dir:top main:center cross:center" }
              },
              [
                _vm._m(1),
                _c(
                  "div",
                  { staticClass: "page-login--form" },
                  [
                    _c(
                      "el-card",
                      { attrs: { shadow: "never" } },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "loginForm",
                            attrs: {
                              "label-position": "top",
                              rules: _vm.rules,
                              model: _vm.formLogin,
                              size: "default"
                            }
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "username" } },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      type: "text",
                                      placeholder: "用户名"
                                    },
                                    model: {
                                      value: _vm.formLogin.username,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formLogin, "username", $$v)
                                      },
                                      expression: "formLogin.username"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-user-circle-o",
                                      attrs: { slot: "prepend" },
                                      slot: "prepend"
                                    })
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "password" } },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      type: "password",
                                      placeholder: "密码"
                                    },
                                    model: {
                                      value: _vm.formLogin.password,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formLogin, "password", $$v)
                                      },
                                      expression: "formLogin.password"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-keyboard-o",
                                      attrs: { slot: "prepend" },
                                      slot: "prepend"
                                    })
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "code" } },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      type: "text",
                                      placeholder: "验证码"
                                    },
                                    model: {
                                      value: _vm.formLogin.code,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formLogin, "code", $$v)
                                      },
                                      expression: "formLogin.code"
                                    }
                                  },
                                  [
                                    _c("template", { slot: "append" }, [
                                      _c("img", {
                                        staticClass: "login-code",
                                        attrs: {
                                          src: require("./image/login-code.png")
                                        }
                                      })
                                    ])
                                  ],
                                  2
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-button",
                              {
                                staticClass: "button-login",
                                attrs: { size: "default", type: "danger" },
                                on: { click: _vm.submit }
                              },
                              [_vm._v(" 登录 ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            ),
            _c("div", { staticClass: "page-login--content-footer" }, [
              _c(
                "p",
                { staticClass: "page-login--content-footer-locales" },
                _vm._l(_vm.$languages, function(language) {
                  return _c(
                    "a",
                    {
                      key: language.value,
                      on: {
                        click: function($event) {
                          return _vm.onChangeLocale(language.value)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(language.label) + " ")]
                  )
                }),
                0
              ),
              _c(
                "p",
                { staticClass: "page-login--content-footer-copyright" },
                [
                  _vm._v(" Copyright "),
                  _c("d2-icon", { attrs: { name: "copyright" } }),
                  _c("a", { attrs: { href: "https://www.vodeshop.com" } }, [
                    _vm._v(" 广东各有所爱信息科技有限公司 ")
                  ])
                ],
                1
              ),
              _vm._m(2)
            ])
          ]
        )
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "快速选择用户",
            visible: _vm.dialogVisible,
            width: "400px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-row",
            {
              staticStyle: { margin: "-20px 0px -10px 0px" },
              attrs: { gutter: 10 }
            },
            _vm._l(_vm.users, function(user, index) {
              return _c("el-col", { key: index, attrs: { span: 8 } }, [
                _c(
                  "div",
                  {
                    staticClass: "page-login--quick-user",
                    on: {
                      click: function($event) {
                        return _vm.handleUserBtnClick(user)
                      }
                    }
                  },
                  [
                    _c("d2-icon", { attrs: { name: "user-circle-o" } }),
                    _c("span", [_vm._v(_vm._s(user.name))])
                  ],
                  1
                )
              ])
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-login--content-header" }, [
      _c("p", { staticClass: "page-login--content-header-motto" }, [
        _vm._v(" 时间是一切财富中最宝贵的财富 ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "background-color": "gainsboro" } }, [
      _c("img", {
        staticClass: "page-login--logo",
        attrs: { src: require("@/assets/logo.png") }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "page-login--content-footer-options" }, [
      _c("a", { attrs: { href: "#" } }, [_vm._v("帮助")]),
      _c("a", { attrs: { href: "#" } }, [_vm._v("隐私")]),
      _c("a", { attrs: { href: "#" } }, [_vm._v("条款")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }