// 状态字典
export var DICT_STATUS = [{
  value: 1,
  label: '启用'
}, {
  value: 0,
  label: '禁用'
}];
export var PERMISSION_DETAIL = {
  // 商城&美肌
  mall: [{
    title: '普通用户',
    id: '0'
  }, {
    title: 'saver会员',
    id: '1'
  }, {
    title: '三星服务商',
    id: '2'
  }, {
    title: '五星服务商',
    id: '3'
  }, {
    title: '七星服务商',
    id: '4'
  }],
  // 名媛私享
  dym: [{
    title: '普通用户',
    id: '0'
  }, {
    title: '名媛',
    id: '1'
  }, {
    title: '名流',
    id: '2'
  }, {
    title: '大王',
    id: '3'
  }],
  // O2O新零售
  retail: [{
    title: '普通用户',
    id: '0'
  }, {
    title: '联合店长',
    id: '1'
  }, {
    title: '店铺合伙人',
    id: '2'
  }, {
    title: '标准店主',
    id: '3'
  }, {
    title: '区代',
    id: '4'
  }, {
    title: '市代',
    id: '5'
  }],
  // s健身计划
  slim: [{
    title: '普通用户',
    id: '0'
  }, {
    title: 'Svip',
    id: '1'
  }, {
    title: '合伙人',
    id: '3'
  }, {
    title: '董事',
    id: '5'
  }, {
    title: '联合创始人',
    id: '7'
  }]
};