import "core-js/modules/es.array.concat.js";
import { request } from '@/api/service';
export default {
  /**
   * 获取认筹订单列表
   * http://doc.vodeshop.com/web/#/34?page_id=2744
   * @param {*} data
   */
  getOrderList: function getOrderList(params) {
    return request({
      url: '/svc/marketing-svc/admin/v1/raise-order/index',
      method: 'get',
      params: params
    });
  },

  /**
   * 修改认筹订单启用状态
   * http://doc.vodeshop.com/web/#/34?page_id=2748
   * @param {*} data
   */
  editOrderEnable: function editOrderEnable(id, is_enable) {
    return request({
      url: "/svc/marketing-svc/admin/v1/raise-order/enable?id=".concat(id, "&is_enable=").concat(is_enable),
      method: 'put'
    });
  },

  /**
   * 修改众创订单允许退押金
   * http://doc.vodeshop.com/web/#/34?page_id=2761
   * @param {*} data
   */
  allowDeposit: function allowDeposit(id, is_allow_return_deposit) {
    return request({
      url: "/svc/marketing-svc/admin/v1/raise-order/allow-return-deposit?id=".concat(id, "&is_allow_return_deposit=").concat(is_allow_return_deposit),
      method: 'put'
    });
  }
};