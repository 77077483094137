var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { class: { "page-compact": _vm.crud.pageOptions.compact } },
    [
      _c(
        "d2-crud-x",
        _vm._g(
          _vm._b(
            {
              ref: "d2Crud",
              scopedSlots: _vm._u([
                {
                  key: "created_atSlot",
                  fn: function(scope) {
                    return [
                      _c("span", [_vm._v(_vm._s(scope.row["created_at"]))])
                    ]
                  }
                },
                {
                  key: "regress_gift_couponSlot",
                  fn: function(scope) {
                    return _vm._l(scope.row.regress_gift_coupon, function(
                      val,
                      index
                    ) {
                      return _c(
                        "el-tag",
                        {
                          key: index,
                          staticStyle: { "margin-right": "5px" },
                          attrs: { type: "warning", size: "mini" }
                        },
                        [
                          _vm._v(
                            _vm._s(val.coupon_name) + " x " + _vm._s(val.count)
                          )
                        ]
                      )
                    })
                  }
                },
                {
                  key: "regress_gift_couponFormSlot",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            disabled:
                              scope.form.regress_gift_coupon.length >= 8,
                            type: "primary",
                            size: "mini",
                            round: ""
                          },
                          on: { click: _vm.addDiscount }
                        },
                        [_vm._v("+新增优惠券")]
                      ),
                      _c("span", { staticClass: "tip-text" }, [
                        _vm._v("注意：可最多关联8个优惠券")
                      ]),
                      _vm._l(scope.form.regress_gift_coupon, function(
                        item,
                        index
                      ) {
                        return _c(
                          "div",
                          { key: index, staticClass: "every-packet" },
                          [
                            _c("span", { staticClass: "red-label" }, [
                              _vm._v("优惠券" + _vm._s(index + 1) + ":")
                            ]),
                            _c("el-input", {
                              staticClass: "small-input",
                              attrs: { placeholder: "输入优惠券ID" },
                              on: {
                                blur: function($event) {
                                  return _vm.idBlur($event, index)
                                },
                                input: function($event) {
                                  return _vm.numberInput(
                                    $event,
                                    index,
                                    "coupon_id"
                                  )
                                }
                              },
                              model: {
                                value: item.coupon_id,
                                callback: function($$v) {
                                  _vm.$set(item, "coupon_id", $$v)
                                },
                                expression: "item.coupon_id"
                              }
                            }),
                            _c("span", { staticClass: "red-label" }, [
                              _vm._v("数量:")
                            ]),
                            _c("el-input", {
                              staticClass: "small-input",
                              attrs: { placeholder: "输入优惠券数量" },
                              on: {
                                input: function($event) {
                                  return _vm.numberInput($event, index, "count")
                                }
                              },
                              model: {
                                value: item.count,
                                callback: function($$v) {
                                  _vm.$set(item, "count", $$v)
                                },
                                expression: "item.count"
                              }
                            }),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  size: "mini",
                                  round: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.selDialog(index)
                                  }
                                }
                              },
                              [_vm._v("选择优惠券")]
                            ),
                            _c("el-button", {
                              attrs: {
                                size: "mini",
                                type: "danger",
                                icon: "el-icon-delete",
                                circle: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.delDiscount(index)
                                }
                              }
                            }),
                            _c(
                              "span",
                              { staticClass: "red-label margin-left-20" },
                              [
                                _vm._v(
                                  "名称：" + _vm._s(item.coupon_name || "暂无")
                                )
                              ]
                            )
                          ],
                          1
                        )
                      })
                    ]
                  }
                }
              ])
            },
            "d2-crud-x",
            _vm._crudProps,
            false
          ),
          _vm._crudListeners
        ),
        [
          _c(
            "div",
            { attrs: { slot: "header" }, slot: "header" },
            [
              _c("crud-search", {
                ref: "search",
                attrs: { options: _vm.crud.searchOptions },
                on: { submit: _vm.handleSearch }
              }),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.addRow }
                },
                [_c("i", { staticClass: "el-icon-plus" }), _vm._v(" 新增")]
              ),
              _c("crud-toolbar", {
                attrs: {
                  compact: _vm.crud.pageOptions.compact,
                  columns: _vm.crud.columns
                },
                on: {
                  "update:compact": function($event) {
                    return _vm.$set(_vm.crud.pageOptions, "compact", $event)
                  },
                  refresh: function($event) {
                    return _vm.doRefresh()
                  },
                  "columns-filter-changed": _vm.handleColumnsFilterChanged
                }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择优惠券",
            "destroy-on-close": "",
            visible: _vm.selDialogVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.selDialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择" },
              model: {
                value: _vm.selValue,
                callback: function($$v) {
                  _vm.selValue = $$v
                },
                expression: "selValue"
              }
            },
            _vm._l(_vm.discountList, function(val, index) {
              return _c("el-option", {
                key: index,
                attrs: {
                  disabled: val.disabled,
                  label: val.name,
                  value: val.id
                }
              })
            }),
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.selDialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.selConfirm } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }