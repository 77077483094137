var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "商家点个赞商品详细",
        visible: _vm.isShowDetailDialog,
        center: true,
        width: "70%"
      },
      on: {
        "update:visible": function($event) {
          _vm.isShowDetailDialog = $event
        },
        close: _vm.close
      }
    },
    [
      _c("div", { staticClass: "content-box" }, [
        _c("div", { staticClass: "title" }, [_vm._v("发起用户信息")]),
        _c(
          "div",
          { staticStyle: { width: "70%" } },
          [
            _c("tableTemple", {
              attrs: {
                tableData: _vm.detailInfo.userInfo,
                columnArr: _vm.columnUser
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "title" }, [_vm._v("上架商品信息")]),
        _c("div", { staticClass: "detail-row" }, [
          _c("span", { staticClass: "margin-right-20" }, [
            _vm._v("商品名称：")
          ]),
          _c("span", [
            _vm._v(_vm._s(_vm.detailInfo.goods && _vm.detailInfo.goods.title))
          ])
        ]),
        _c(
          "div",
          { staticStyle: { width: "90%", "margin-bottom": "20px" } },
          [
            _c("tableTemple", {
              attrs: {
                tableData: _vm.detailInfo.give_like_goods_sku,
                columnArr: _vm.columnGoods
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "detail-row" }, [
          _c("span", { staticClass: "margin-right-20" }, [
            _vm._v("活动时间：")
          ]),
          _c("span", [
            _vm._v(
              _vm._s(_vm.detailInfo.start_at) +
                "至" +
                _vm._s(_vm.detailInfo.end_at)
            )
          ])
        ]),
        _c("div", { staticClass: "detail-row" }, [
          _c("span", { staticClass: "margin-right-20" }, [
            _vm._v("购买方式：")
          ]),
          _c("span", [_vm._v("任意金额可购买")])
        ]),
        _c("div", { staticClass: "detail-row" }, [
          _c("span", { staticClass: "margin-right-20" }, [
            _vm._v("集赞时长：")
          ]),
          _c("span", [_vm._v(_vm._s(_vm.detailInfo.like_hour) + "小时")])
        ]),
        _c(
          "div",
          { staticClass: "detail-row" },
          [
            _c("span", { staticClass: "margin-right-20" }, [_vm._v("说明：")]),
            _c("el-image", {
              staticClass: "introduce-img",
              attrs: {
                fit: "contain",
                src: _vm._f("qiniu")(_vm.detailInfo.illustrative_picture)
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }