var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { cursor: "pointer" } },
    [
      _c(
        "el-tag",
        {
          attrs: { type: _vm.type },
          nativeOn: {
            click: function($event) {
              return _vm.editPermissionStatus.apply(null, arguments)
            }
          }
        },
        [_vm._v(_vm._s(_vm.text))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }