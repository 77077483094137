import { request } from '@/api/service';
export default {
  /**
   * 获取消费明细列表
   * http://doc.vodeshop.com/web/#/34?page_id=2746
   * @param {*} data
   */
  getConsumingList: function getConsumingList(params) {
    return request({
      url: '/svc/marketing-svc/admin/v1/raise-order/buy-list',
      method: 'get',
      params: params
    });
  },

  /**
   * 导出数据
   * http://doc.vodeshop.com/web/#/34?page_id=2746
   * @param {*} data
   */
  exportConsumingList: function exportConsumingList(params) {
    return request({
      url: '/svc/marketing-svc/admin/v1/raise-order/buy-list?_export=1',
      method: 'get',
      params: params
    });
  }
};