// import API from './api'
import { DICT_TYPE } from './dict'; // import { IMGBASEURL } from '@/api/config'
// import UEditorConfig from '@/api/UEditor-config'

import util from '@/libs/util';
export var crudOptions = function crudOptions(vm) {
  return {
    options: {
      height: '100%',
      // 表格高度100%,使用toolbar必须设置
      rowKey: 'id',
      stripe: true
    },
    viewOptions: {
      disabled: false
    },
    formOptions: {
      // 编辑对话框及el-form的配置
      type: 'drawer',
      // 默认对话框模式，drawer 抽屉模式
      size: '60%',
      // 抽屉模式的宽度
      fullscreen: true,
      // 全屏按钮，传null则隐藏全屏按钮，抽屉模式请隐藏
      draggable: true,
      // 是否支持表单对话框拖拽，抽屉模式请关闭
      saveRemind: true,
      // 有修改时是否需要保存提醒，也可以传入一个方法，自定义确认对话框，()=> return vm.$confirm({})
      labelWidth: '110px',
      labelPosition: 'left',
      saveLoading: false,
      gutter: 20,
      saveButtonType: 'primary',
      maxHeight: true,
      // 对话框内部显示滚动条
      defaultSpan: 24,
      // 默认表单字段所占宽度
      updateTableDataAfterEdit: true // 添加和删除提交后，是否直接更新本地table的数据，默认会自动刷新表格，所以不需要更新本地数据

    },
    rowHandle: {
      width: 600,
      view: false,
      fixed: 'right',
      align: 'center'
    },
    columns: [{
      title: 'ID',
      key: 'id',
      width: '100',
      align: 'center',
      form: {
        disabled: true
      }
    }, {
      title: '内容',
      key: 'text',
      align: 'center',
      search: {
        disabled: false,
        width: 200
      },
      form: {
        rules: [{
          required: true,
          message: '请输入内容'
        }]
      },
      addForm: {
        component: {
          name: 'el-input',
          maxlength: 20,
          // 原生属性要写在这里
          props: {
            type: 'text',
            showWordLimit: true
          }
        }
      },
      editForm: {
        component: {
          name: 'el-input',
          maxlength: 20,
          // 原生属性要写在这里
          props: {
            type: 'text',
            showWordLimit: true
          }
        }
      }
    }, {
      title: '类型',
      key: 'type',
      type: 'radio',
      align: 'center',
      search: {
        disabled: false
      },
      dict: {
        data: DICT_TYPE
      },
      form: {
        rules: [{
          required: true,
          message: '请选择类型'
        }],
        value: 1,
        valueResolve: function valueResolve(row, key) {},
        component: {
          name: 'dict-select',
          span: 8
        }
      }
    }, {
      title: '创建时间',
      key: 'created_at',
      width: 150,
      type: 'datetimerange',
      align: 'center',
      search: {
        width: 400,
        disabled: false,
        component: {
          // 查询 使用选择框组件，并且是可以清除的
          props: {
            'time-arrow-control': true,
            'default-time': ['00:00:00', '00:00:00'] // 'picker-options': { shortcuts: ShortCUTS }

          }
        }
      },
      show: false,
      form: {
        disabled: true
      },
      valueResolve: function valueResolve(row, key) {
        var _row$created_at;

        if (((_row$created_at = row.created_at) === null || _row$created_at === void 0 ? void 0 : _row$created_at.length) > 1) {
          row.created_at_start = util.formatDate(row.created_at[0]);
          row.created_at_end = util.formatDate(row.created_at[1]);
        }
      }
    }]
  };
};