import "/www/wwwroot/test.admin.vodeshop.com/node_modules/core-js/modules/es.array.iterator.js";
import "/www/wwwroot/test.admin.vodeshop.com/node_modules/core-js/modules/es.promise.js";
import "/www/wwwroot/test.admin.vodeshop.com/node_modules/core-js/modules/es.object.assign.js";
import "/www/wwwroot/test.admin.vodeshop.com/node_modules/core-js/modules/es.promise.finally.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
// Vue
import Vue from 'vue';
import i18n from './i18n';
import App from './App'; // 核心插件

import d2Admin from '@/plugin/d2admin'; // store

import store from '@/store/index'; // 富文本

import VueUeditorWrap from 'vue-ueditor-wrap'; // 菜单和路由设置

import $apis from '@/api/';
import router from './router';
import * as filters from './filters/';
import { menuHeader, menuAside } from '@/menu';
import { frameInRoutes } from '@/router/routes'; // import '@/api/mock' // mock数据，实际开发要去掉
// import { d2CrudPlus } from 'd2-crud-plus'

Vue.prototype.$apis = $apis; // 注册过滤器

Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
}); // 核心插件

Vue.use(d2Admin);
Vue.component('VueUeditorWrap', VueUeditorWrap);
new Vue({
  router: router,
  store: store,
  i18n: i18n,
  render: function render(h) {
    return h(App);
  },
  created: function created() {
    // 处理路由 得到每一级的路由设置
    this.$store.commit('d2admin/page/init', frameInRoutes); // 设置顶栏菜单

    this.$store.commit('d2admin/menu/headerSet', menuHeader); // // 设置侧边栏菜单
    // this.$store.commit('d2admin/menu/asideSet', menuAside)
    // 初始化菜单搜索功能

    this.$store.commit('d2admin/search/init', menuHeader);
  },
  mounted: function mounted() {
    // 展示系统信息
    this.$store.commit('d2admin/releases/versionShow'); // 用户登录后从数据库加载一系列的设置

    this.$store.dispatch('d2admin/account/load'); // 获取并记录用户 UA

    this.$store.commit('d2admin/ua/get'); // 初始化全屏监听

    this.$store.dispatch('d2admin/fullscreen/listen');
  },
  watch: {
    // 检测路由变化切换侧边栏内容
    '$route.matched': {
      handler: function handler(matched) {
        if (matched.length > 0) {
          var _side = menuAside.filter(function (menu) {
            return menu.path === matched[0].path;
          });

          this.$store.commit('d2admin/menu/asideSet', _side.length > 0 ? _side[0].children : []);
        }
      },
      immediate: true
    }
  }
}).$mount('#app');