// 订单状态
export var ORDER_STATUS = [{
  value: 0,
  label: '待支付'
}, {
  value: 1,
  label: '待发货'
}, {
  value: 2,
  label: '待收货'
}, {
  value: 3,
  label: '已完成'
}, {
  value: 4,
  label: '处理中'
}, {
  value: 5,
  label: '已换货'
}, {
  value: 6,
  label: '已退货'
}, {
  value: 7,
  label: '订单过期'
}, {
  value: 8,
  label: '用户取消'
}, {
  value: 9,
  label: '即将过期'
}, {
  value: 10,
  label: '待成团'
}, {
  value: 11,
  label: '拼团失败'
}];