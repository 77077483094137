var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "集赞详细",
        visible: _vm.isShowDetailDialog,
        center: true,
        width: "70%"
      },
      on: {
        "update:visible": function($event) {
          _vm.isShowDetailDialog = $event
        },
        close: _vm.close
      }
    },
    [
      _c("div", { staticClass: "content-box" }, [
        _c("div", { staticClass: "title" }, [_vm._v("发起用户信息")]),
        _c(
          "div",
          { staticStyle: { width: "60%" } },
          [
            _c("tableTemple", {
              attrs: {
                tableData: _vm.detailInfo.userInfo,
                columnArr: _vm.columnUser
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "title" }, [_vm._v("集赞信息")]),
        _c("div", { staticClass: "detail-row" }, [
          _c("span", { staticClass: "margin-right-20" }, [
            _vm._v("发起时间：")
          ]),
          _c("span", [_vm._v(_vm._s(_vm.detailInfo.created_at))])
        ]),
        _c("div", { staticClass: "detail-row" }, [
          _c("span", { staticClass: "margin-right-20" }, [
            _vm._v("截止时间：")
          ]),
          _c("span", [_vm._v(_vm._s(_vm.detailInfo.end_at))])
        ]),
        _c("div", { staticClass: "detail-row" }, [
          _c("span", { staticClass: "margin-right-20" }, [
            _vm._v("商品名称：")
          ]),
          _c("span", [
            _vm._v(_vm._s(_vm.detailInfo.goods && _vm.detailInfo.goods.title))
          ])
        ]),
        _c("div", { staticClass: "detail-row" }, [
          _c("span", { staticClass: "margin-right-20" }, [
            _vm._v("商品规格：")
          ]),
          _c("span", [
            _vm._v(
              _vm._s(
                (_vm.detailInfo.give_like_goods_sku &&
                  _vm.detailInfo.give_like_goods_sku.goods_sku &&
                  _vm.detailInfo.give_like_goods_sku.goods_sku.name) ||
                  "暂无"
              )
            )
          ])
        ]),
        _c("div", { staticClass: "detail-row" }, [
          _c("span", { staticClass: "margin-right-20" }, [
            _vm._v("商品原价：")
          ]),
          _c("span", [
            _vm._v(
              "￥" +
                _vm._s(
                  _vm.detailInfo.give_like_goods_sku &&
                    _vm.detailInfo.give_like_goods_sku.price
                )
            )
          ])
        ]),
        _c("div", { staticClass: "detail-row" }, [
          _c("span", { staticClass: "margin-right-20" }, [
            _vm._v("商品底价：")
          ]),
          _c("span", [
            _vm._v(
              "￥" +
                _vm._s(
                  _vm.detailInfo.give_like_goods_sku &&
                    _vm.detailInfo.give_like_goods_sku.reserve_price
                )
            )
          ])
        ]),
        _c("div", { staticClass: "detail-row" }, [
          _c("span", { staticClass: "margin-right-20" }, [
            _vm._v("购买方式：")
          ]),
          _c("span", [_vm._v("任意金额可购买")])
        ]),
        _c("div", { staticClass: "detail-row" }, [
          _c("span", { staticClass: "margin-right-20" }, [
            _vm._v("已集赞数：")
          ]),
          _c("span", [_vm._v(_vm._s(_vm.detailInfo.like))])
        ]),
        _c("div", { staticClass: "detail-row" }, [
          _c("span", { staticClass: "margin-right-20" }, [
            _vm._v("集赞状态：")
          ]),
          _c("span", [_vm._v(_vm._s(_vm.statusChange(_vm.detailInfo.status)))])
        ]),
        _c("div", { staticClass: "title" }, [_vm._v("集赞订单")]),
        _c(
          "div",
          { staticStyle: { width: "85%" } },
          [
            _c("tableTemple", {
              attrs: {
                tableData: _vm.detailInfo.orderInfo,
                columnArr: _vm.columnOrder
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "title" }, [_vm._v("集赞记录")]),
        _c(
          "div",
          { staticStyle: { width: "75%" } },
          [
            _c("tableTemple", {
              attrs: {
                tableData: _vm.detailInfo.recordList,
                columnArr: _vm.columnRecord
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }