import _objectSpread from "/www/wwwroot/test.admin.vodeshop.com/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import * as API from './api';
import { crudOptions } from './crud';
import { d2CrudPlus } from 'd2-crud-plus';
import log from '@/libs/util.log'; // import helper from './helper'

export default {
  name: 'activitiesParticipant',
  mixins: [d2CrudPlus.crud],
  data: function data() {
    return {// helper: helper
    };
  },
  computed: {},
  mounted: function mounted() {
    var _this$crud, _this$crud$searchOpti;

    // 将初始化推迟到mounted
    var params = this.$route.params;

    if (params !== null && params !== void 0 && params.marketing_activity_id && (_this$crud = this.crud) !== null && _this$crud !== void 0 && (_this$crud$searchOpti = _this$crud.searchOptions) !== null && _this$crud$searchOpti !== void 0 && _this$crud$searchOpti.form) {
      this.getSearch().setForm(_objectSpread(_objectSpread({}, this.getSearch().form), {}, {
        marketing_activity_id: params.marketing_activity_id
      }));
      this.getSearch().doSearch();
    }
  },
  methods: {
    pageRequest: function pageRequest(query) {
      delete query.payDate;
      delete query.sign_at;
      return API.GetList(query);
    },
    getCrudOptions: function getCrudOptions() {
      // return crudOptions(this)
      return crudOptions;
    },
    // addRequest (row) {
    //   return AddObj(row)
    // },
    // updateRequest (row) {
    //   return UpdateObj(row)
    // },
    // delRequest (row) {
    //   return DelObj(row.id)
    // },
    handleCurrentChange: function handleCurrentChange(currentRow, oldCurrentRow) {// this.$message('单选事件：' + currentRow.data)
    },
    handleSelectionChange: function handleSelectionChange(selection) {// console.log('多选数据：', selection)
    },
    customHandleBtnHandle: function customHandleBtnHandle(_ref) {// this.$message('自定义操作按钮：' + row.data + ',index:' + index)

      var index = _ref.index,
          row = _ref.row;
    }
  }
};