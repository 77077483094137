export default {
  path: '/extend',
  title: '扩展应用',
  children: [{
    title: '众创',
    icon: 'handshake-o',
    children: [{
      title: '认领订单管理',
      icon: 'file-o',
      path: '/extend/crowdfunding/order'
    }, {
      title: '众创商品管理',
      icon: 'list-ul',
      path: '/extend/crowdfunding/product'
    }, {
      title: '购买明细',
      icon: 'credit-card',
      path: '/extend/crowdfunding/consumingRecords'
    }, {
      title: '续期审核',
      icon: 'hand-stop-o',
      path: '/extend/crowdfunding/renewalCheck'
    }]
  }, {
    title: '内容管理',
    icon: 'wrench',
    path: '/extend/contentMag'
  }]
};