import "core-js/modules/es.array.concat.js";
import { request } from '@/api/service';
export default {
  // 获取权限模板列表
  GetPermissionList: function GetPermissionList(data) {
    return request({
      url: "/svc/marketing-svc/admin/v1/template/list?page=".concat(data.page, "&per_page=").concat(data.per_page),
      method: 'get'
    });
  },
  // 获取全部素材分类列表
  GetCategoryAllList: function GetCategoryAllList() {
    return request({
      url: '/svc/marketing-svc/admin/v1/pink-circle-category/all-category',
      method: 'get'
    });
  },
  // 获取分类列表
  GetCategoryList: function GetCategoryList() {
    return request({
      url: '/svc/marketing-svc/admin/v1/pink-circle-category/index',
      method: 'get'
    });
  },
  // 新增视频素材
  AddVideoMaterial: function AddVideoMaterial(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/material/video',
      method: 'post',
      data: data
    });
  },
  // 获取发布人列表(不分页)
  GetAllIssuerList: function GetAllIssuerList(params) {
    return request({
      url: '/svc/marketing-svc/admin/v1/pink-circle-user/user',
      method: 'get',
      params: params
    });
  },
  // 获取商品详情
  GetProDetail: function GetProDetail(id) {
    return request({
      url: "/svc/marketing-svc/admin/v1/goods/show?goods_id=".concat(id),
      method: 'get'
    });
  },
  // 获取商品列表
  GetProList: function GetProList(data) {
    return request({
      url: "/svc/marketing-svc/admin/v1/goods/search?keyword=".concat(data.keyWord, "&category_id=").concat(data.category_id, "&page=").concat(data.page),
      method: 'get'
    });
  },
  // 获取商品分类列表
  GetProCategoryList: function GetProCategoryList(params) {
    return request({
      url: "/svc/marketing-svc/admin/v1/goods/category?category_id=".concat(params.category_id),
      method: 'get'
    });
  },
  // 获取素材详情
  GetMaterialDetail: function GetMaterialDetail(data) {
    return request({
      url: "/svc/marketing-svc/admin/v1/material/show?id=".concat(data.id),
      method: 'get'
    });
  },
  // 编辑素材
  EditMaterial: function EditMaterial(data, id) {
    return request({
      url: "/svc/marketing-svc/admin/v1/material/update?id=".concat(id),
      method: 'put',
      data: data
    });
  }
};