export default {
  path: '/marketing',
  title: '全局营销工具',
  icon: 'plug',
  children: [{
    title: '爱粉圈',
    icon: 'home',
    children: [{
      title: '运营状态',
      icon: 'bar-chart',
      path: '/marketing/loveCircle/operation'
    }, {
      title: '权限管理',
      icon: 'vcard',
      path: '/marketing/loveCircle/permission'
    }, {
      title: '分类设置',
      icon: 'align-justify',
      children: [{
        title: '所有分类',
        icon: 'minus',
        path: '/marketing/loveCircle/category/allCategory'
      } // {
      //   title: '新增/编辑分类',
      //   icon: 'edit',
      //   path: '/marketing/loveCircle/category/addCategory'
      // }
      ]
    }, {
      title: '所有素材',
      icon: 'file-o',
      path: '/marketing/loveCircle/allMaterial'
    }, {
      title: '上传素材',
      icon: 'paste',
      children: [// {
      //   title: '发布人身份设置',
      //   icon: 'user',
      //   path: '/marketing/loveCircle/material/issuerSetting'
      // },
      {
        title: '发布人身份设置',
        icon: 'user',
        path: '/marketing/loveCircle/material/issuerSetting'
      }, {
        title: '图文素材',
        icon: 'file-photo-o',
        path: '/marketing/loveCircle/material/imageMaterial'
      }, {
        title: '视频素材',
        icon: 'file-video-o',
        path: '/marketing/loveCircle/material/videoMaterial'
      }]
    }]
  }, {
    title: '报名活动',
    icon: 'bullhorn',
    children: [{
      title: '所有活动',
      icon: 'archive',
      path: '/marketing/activities/activities'
    }, {
      title: '参与人记录',
      icon: 'calendar',
      path: '/marketing/activities/participant'
    }]
  }, {
    title: '优惠券',
    icon: 'shopping-bag',
    children: [{
      title: '优惠券管理',
      icon: 'folder-o',
      path: '/marketing/coupon/couponMag'
    }, {
      title: '优惠券分类',
      icon: 'navicon',
      path: '/marketing/coupon/couponCag'
    }, {
      title: '优惠券礼包',
      icon: 'yelp',
      path: '/marketing/coupon/giftMag'
    }]
  }, {
    title: '专区',
    icon: 'shopping-basket',
    children: [// {
    //   title: '活动专区商品管理',
    //   icon: 'flickr',
    //   path: '/marketing/specialGoods/specialGoodsMag'
    // },
    {
      title: '专区商品管理',
      icon: 'flickr',
      path: '/marketing/specialGoods/goodsMag'
    }, {
      title: '专区商品分类管理',
      icon: 'book',
      path: '/marketing/specialGoods/goodsCag'
    }, {
      title: '活动专区',
      icon: 'bookmark-o',
      path: '/marketing/specialGoods/specialActivity'
    }]
  } // {
  //   title: '红包',
  //   icon: 'cny',
  //   children: [
  //     {
  //       title: '红包管理',
  //       icon: 'envelope-o',
  //       path: '/marketing/redpacket/packetMag'
  //     }
  //   ]
  // }
  ]
};