import { request } from '@/api/service';
export default {
  // 获取礼包列表 http://doc.vodeshop.com/web/#/34?page_id=2799
  GetGiftList: function GetGiftList(params) {
    return request({
      url: '/svc/marketing-svc//admin/v1/regress/gift/list',
      method: 'get',
      params: params
    });
  },
  // 创建礼包 http://doc.vodeshop.com/web/#/34?page_id=2797
  CreateGift: function CreateGift(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/regress/gift/create',
      method: 'post',
      data: data
    });
  },
  // 编辑礼包 http://doc.vodeshop.com/web/#/34?page_id=2797
  EditGift: function EditGift(data, id) {
    return request({
      url: "/svc/marketing-svc/admin/v1/regress/gift/edit/".concat(id),
      method: 'post',
      data: data
    });
  },
  // 编辑礼包状态 http://doc.vodeshop.com/web/#/34?page_id=2802
  EditGiftStstus: function EditGiftStstus(params) {
    return request({
      url: '/svc/marketing-svc/admin/v1/regress/gift/set-status',
      method: 'get',
      params: params
    });
  },
  // 回归礼包删除 http://doc.vodeshop.com/web/#/34?page_id=2803
  DeleteGift: function DeleteGift(id) {
    return request({
      url: "/svc/marketing-svc/admin/v1/regress/gift/delete/".concat(id),
      method: 'DELETE'
    });
  },
  // 获取所有优惠券 http://doc.vodeshop.com/web/#/34?page_id=2806
  getAllDiscount: function getAllDiscount() {
    return request({
      url: '/svc/marketing-svc/admin/v1/regress/coupon/get-all',
      method: 'get'
    });
  }
};