import { request } from '@/api/service';
var prefix = '/svc/marketing-svc';
export default {
  getPacketList: function getPacketList(params) {
    return request({
      url: "".concat(prefix, "/admin/v1/regress/red-packet"),
      method: 'get',
      params: params
    });
  },
  addPacket: function addPacket(data) {
    return request({
      url: "".concat(prefix, "/admin/v1/regress/red-packet"),
      method: 'post',
      data: data
    });
  },
  deletePacket: function deletePacket(id) {
    return request({
      url: "".concat(prefix, "/admin/v1/regress/red-packet?id=").concat(id),
      method: 'delete'
    });
  },
  updatePacket: function updatePacket(data) {
    return request({
      url: "".concat(prefix, "/admin/v1/regress/red-packet"),
      method: 'put',
      data: data
    });
  },
  updatePacketStatus: function updatePacketStatus(data) {
    return request({
      url: "".concat(prefix, "/admin/v1/regress/red-packet"),
      method: 'put',
      data: data
    });
  }
};