import _objectSpread from "/www/wwwroot/test.admin.vodeshop.com/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.map.js";
import { uniqueId } from 'lodash'; // import header from '@/menu/header'
// import aside from '@/menu/aside'

import demo from '@/menu/modules/demo';
import marketing from '@/menu/modules/marketing';
import extend from '@/menu/modules/extend';
import shopping from '@/menu/modules/shopping';
/**
 * @description 给菜单数据补充上 path 字段
 * @description https://github.com/d2-projects/d2-admin/issues/209
 * @param {Array} menu 原始的菜单数据
 */

function supplementPath(menu) {
  return menu.map(function (e) {
    return _objectSpread(_objectSpread({}, e), {}, {
      path: e.path || uniqueId('d2-menu-empty-')
    }, e.children ? {
      children: supplementPath(e.children)
    } : {});
  });
} // 菜单 侧边栏


export var menuAside = supplementPath([demo, marketing, extend, shopping]); // 菜单 顶栏

export var menuHeader = supplementPath([{
  path: '/index',
  title: '首页',
  icon: 'home'
}, // demo,
marketing, extend, shopping]); // export const menuHeader = supplementPath(header)
// export const menuAside = supplementPath(aside)