import layoutHeaderAside from '@/layout/header-aside'; // 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载

var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var meta = {
  auth: true
};
export default {
  path: '/extend',
  name: 'extend',
  meta: meta,
  redirect: {
    name: 'extend'
  },
  component: layoutHeaderAside,
  children: [{
    path: 'crowdfunding/consumingRecords',
    name: 'consumingRecords',
    meta: {
      title: '购买明细',
      auth: true
    },
    component: _import('extend/crowdfunding/consumingRecords')
  }, {
    path: 'crowdfunding/order',
    name: 'order',
    meta: {
      title: '认领订单管理',
      auth: true
    },
    component: _import('extend/crowdfunding/order')
  }, {
    path: 'crowdfunding/product',
    name: 'product',
    meta: {
      title: '众创商品管理',
      auth: true
    },
    component: _import('extend/crowdfunding/product')
  }, {
    path: 'crowdfunding/renewalCheck',
    name: 'renewalCheck',
    meta: {
      title: '续期审核',
      auth: true
    },
    component: _import('extend/crowdfunding/renewalCheck')
  }, {
    path: 'contentMag',
    name: 'ruleMag',
    meta: {
      title: '内容管理',
      auth: true
    },
    component: _import('extend/contentMag')
  }]
};