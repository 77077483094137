import layoutHeaderAside from '@/layout/header-aside'; // 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载

var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var meta = {
  auth: true
};
export default {
  path: '/shopping',
  name: 'shopping',
  meta: meta,
  redirect: {
    name: 'shopping'
  },
  component: layoutHeaderAside,
  children: [{
    path: 'extend/regress/activityMag',
    name: 'activityMag',
    meta: {
      title: '回归活动管理',
      auth: true
    },
    component: _import('shopping/extend/regress/activityMag')
  }, // {
  //   path: 'extend/regress/ruleMag',
  //   name: 'ruleMag',
  //   meta: {
  //     title: '回归规则管理',
  //     auth: true
  //   },
  //   component: _import('shopping/extend/regress/ruleMag')
  // },
  {
    path: 'extend/regress/userDetail',
    name: 'userDetail',
    meta: {
      title: '回归用户明细',
      auth: true
    },
    component: _import('shopping/extend/regress/userDetail')
  }, {
    path: 'extend/regress/redPacketDetail',
    name: 'redPacketDetail',
    meta: {
      title: '回归红包明细',
      auth: true
    },
    component: _import('shopping/extend/regress/redPacketDetail')
  }, {
    path: 'extend/regress/orderDetail',
    name: 'orderDetail',
    meta: {
      title: '回归订单明细',
      auth: true
    },
    component: _import('shopping/extend/regress/orderDetail')
  }, // {
  //   path: 'extend/regress/giftMag',
  //   name: 'giftMag',
  //   meta: {
  //     title: '回归礼包管理',
  //     auth: true
  //   },
  //   component: _import('shopping/extend/regress/giftMag')
  // },
  {
    path: 'extend/regress/activitySum',
    name: 'activitySummary',
    meta: {
      title: '回归活动概况',
      auth: true
    },
    component: _import('shopping/extend/regress/activitySum')
  }, // 点个赞
  {
    path: 'marketingTool/thumbUp/productMag',
    name: 'productMag',
    meta: {
      title: '点个赞商品',
      auth: true
    },
    component: _import('shopping/marketingTool/thumbUp/productMag')
  }, {
    path: 'marketingTool/thumbUp/productClassify',
    name: 'productClassify',
    meta: {
      title: '点个赞商品分类',
      auth: true
    },
    component: _import('shopping/marketingTool/thumbUp/productClassify')
  }, {
    path: 'marketingTool/thumbUp/thumbDetail',
    name: 'thumbDetail',
    meta: {
      title: '点个赞明细',
      auth: true
    },
    component: _import('shopping/marketingTool/thumbUp/thumbDetail')
  }, {
    path: 'marketingTool/thumbUp/setting',
    name: 'setting',
    meta: {
      title: '点个赞设置',
      auth: true
    },
    component: _import('shopping/marketingTool/thumbUp/setting')
  }, {
    path: 'marketingTool/thumbUp/virtualContent',
    name: 'virtualContent',
    meta: {
      title: '点个赞虚拟内容',
      auth: true
    },
    component: _import('shopping/marketingTool/thumbUp/virtualContent')
  }, // 智慧店
  {
    path: 'storeLike/thumbUp/thumbGoods',
    name: 'thumbGoods',
    meta: {
      title: '商家点个赞商品',
      auth: true
    },
    component: _import('shopping/storeLike/thumbUp/thumbGoods')
  }, {
    path: 'storeLike/thumbUp/thumbRecord',
    name: 'thumbRecord',
    meta: {
      title: '商家点个赞操作记录',
      auth: true
    },
    component: _import('shopping/storeLike/thumbUp/thumbRecord')
  }, {
    path: 'storeLike/thumbUp/dataDetail',
    name: 'dataDetail',
    meta: {
      title: '商家流量明细',
      auth: true
    },
    component: _import('shopping/storeLike/thumbUp/dataDetail')
  }, {
    path: 'storeLike/thumbUp/exchangeHot',
    name: 'exchangeHot',
    meta: {
      title: '智慧店兑换热度',
      auth: true
    },
    component: _import('shopping/storeLike/thumbUp/exchangeHot')
  }, {
    path: 'storeLike/thumbUp/hotDetail',
    name: 'hotDetail',
    meta: {
      title: '智慧店热度明细',
      auth: true
    },
    component: _import('shopping/storeLike/thumbUp/hotDetail')
  }, {
    path: 'storeLike/thumbUp/setting',
    name: 'exchangeSetting',
    meta: {
      title: '智慧店兑换中心设置',
      auth: true
    },
    component: _import('shopping/storeLike/thumbUp/setting')
  }]
};