export var DICT_STATUS = [{
  value: 1,
  label: '启用'
}, {
  value: 0,
  label: '禁用'
}];
export var DICT_COUPON_TYPE = [{
  value: 1,
  label: '立减券'
}, {
  value: 2,
  label: '满减券'
} // { value: 3, label: '指定商品券' }
];
export var PLATFORM = [// { value: 0, label: '无' },
{
  value: 1,
  label: '商城&美肌'
}, {
  value: 2,
  label: '名媛'
}, {
  value: 3,
  label: '共享新零售'
}];
export var SCOPE_TYPE = [{
  value: 0,
  label: '全场通用'
}, {
  value: 1,
  label: '回归活动'
}];
export var TIMESTATUS = [{
  value: 1,
  label: '时长'
}, {
  value: 0,
  label: '时间区间'
}];