// import API from './api'
// import StringUtils from 'd2-crud-plus/src/lib/utils/util.string'
import { DICT_TYPE } from './dict'; // import UEditorConfig from '@/api/UEditor-config'

import util from '@/libs/util'; // import API from './api'

export var crudOptions = function crudOptions(vm) {
  return {
    options: {
      height: '100%',
      // 表格高度100%,使用toolbar必须设置
      rowKey: 'id',
      stripe: true
    },
    viewOptions: {
      disabled: false
    },
    pageOptions: {
      export: {
        local: false // 配置为服务端导出

      }
    },
    formOptions: {
      // 编辑对话框及el-form的配置
      type: 'drawer',
      // 默认对话框模式，drawer 抽屉模式
      size: '60%',
      // 抽屉模式的宽度
      fullscreen: true,
      // 全屏按钮，传null则隐藏全屏按钮，抽屉模式请隐藏
      draggable: true,
      // 是否支持表单对话框拖拽，抽屉模式请关闭
      saveRemind: true,
      // 有修改时是否需要保存提醒，也可以传入一个方法，自定义确认对话框，()=> return vm.$confirm({})
      labelWidth: '110px',
      labelPosition: 'left',
      saveLoading: false,
      gutter: 20,
      saveButtonType: 'primary',
      maxHeight: true,
      // 对话框内部显示滚动条
      defaultSpan: 24,
      // 默认表单字段所占宽度
      updateTableDataAfterEdit: true // 添加和删除提交后，是否直接更新本地table的数据，默认会自动刷新表格，所以不需要更新本地数据

    },
    rowHandle: false,
    columns: [{
      title: 'ID',
      key: 'id',
      width: 120,
      align: 'center',
      search: {
        disabled: false,
        width: 200
      }
    }, {
      title: '用户名称',
      key: 'user_name',
      width: 200,
      align: 'center',
      search: {
        disabled: true,
        width: 200
      }
    }, {
      title: '用户ID',
      key: 'user_id',
      width: 120,
      align: 'center',
      search: {
        disabled: false,
        width: 200
      }
    }, {
      title: '智慧店ID',
      key: 'supplier_id',
      align: 'center',
      width: 120,
      search: {
        disabled: false,
        width: 200
      }
    }, {
      title: '类型',
      key: 'operate_type',
      align: 'center',
      width: 150,
      type: 'radio',
      form: {
        disabled: true
      },
      search: {
        disabled: false,
        component: {
          name: 'dict-select'
        }
      },
      dict: {
        data: DICT_TYPE
      }
    }, {
      title: '流量值',
      key: 'operate_flow',
      align: 'center',
      width: 120
    }, {
      title: '创建时间',
      key: 'created_at',
      type: 'datetimerange',
      align: 'center',
      width: 170,
      search: {
        width: 400,
        disabled: false,
        component: {
          // 查询 使用选择框组件，并且是可以清除的
          props: {
            'time-arrow-control': true,
            'default-time': ['00:00:00', '00:00:00'] // 'picker-options': { shortcuts: ShortCUTS }

          }
        }
      },
      valueResolve: function valueResolve(row, key) {
        var _row$created_at;

        if (((_row$created_at = row.created_at) === null || _row$created_at === void 0 ? void 0 : _row$created_at.length) > 1) {
          row.start_at = util.formatDate(row.created_at[0]);
          row.end_at = util.formatDate(row.created_at[1]);
        }
      }
    }]
  };
};