import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { crudOptions } from './crud';
import { d2CrudPlus } from 'd2-crud-plus';
import api from './api';
import StatusTag from '@/components/status-tag/status-tag.vue';
export default {
  mixins: [d2CrudPlus.crud],
  methods: {
    getCrudOptions: function getCrudOptions() {
      return crudOptions(StatusTag, this);
    },
    pageRequest: function pageRequest(query) {
      return api.getGoodsCategoryList(query);
    },
    addRequest: function addRequest(data) {
      return api.addGoodsCategory({
        name: data.name,
        shelf: data.shelf
      });
    },
    updateRequest: function updateRequest(data) {
      // console.log(data)
      return api.updateGoodsCategory({
        id: data.id,
        name: data.name,
        shelf: data.shelf
      });
    },
    delRequest: function delRequest(row) {
      return api.deleteGoodsCategory(row.id); // return new Promise((resolve, reject) => {
      //   if (row.shelf === 1) {
      //     this.$message({
      //       message: '提示，该分类下还有上架的商品！不可删除！',
      //       type: 'warning'
      //     })
      //     return reject(new Error(false))
      //   } else {
      //     return resolve(api.deleteGoodsCategory(row.id))
      //   }
      // })
    }
  }
};