import { request } from '@/api/service';
export default {
  /**
   * 活动专区列表 接口
   * http://doc.vodeshop.com/web/#/34?page_id=2930
   * @param {*} data
   */
  getSpecialList: function getSpecialList(params) {
    return request({
      url: '/svc/marketing-svc/admin/v1/regress/coupon-page',
      method: 'get',
      params: params
    });
  },

  /**
   * 创建活动专区 接口
   * http://doc.vodeshop.com/web/#/34?page_id=2929
   * @param {*} data
   */
  addSpecial: function addSpecial(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/regress/coupon-page',
      method: 'post',
      data: data
    });
  },

  /**
   * 编辑活动专区 接口
   * http://doc.vodeshop.com/web/#/34?page_id=2931
   * @param {*} data
   */
  editSpecial: function editSpecial(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/regress/coupon-page',
      method: 'put',
      data: data
    });
  },

  /**
   * 删除活动专区
   * http://doc.vodeshop.com/web/#/34?page_id=2932
   * @param {*} data
   */
  deleteSpecial: function deleteSpecial(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/regress/coupon-page',
      method: 'delete',
      data: data
    });
  }
};