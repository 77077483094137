// 状态字典
export var DICT_STATIS_TYPE = [{
  value: 1,
  label: '直接下级'
}, {
  value: 2,
  label: '整个团队'
}]; // 是否字典

export var DICT_YES_NO = [{
  value: 1,
  label: '是'
}, {
  value: 0,
  label: '否'
}]; // 状态字典

export var DICT_STATUS = [{
  value: 1,
  label: '启用',
  color: 'success'
}, {
  value: 0,
  label: '禁用',
  color: 'danger'
}];