export default (function (_ref) {
  var request = _ref.request;
  return {
    /**
     * 搜索商品
     * http://doc.vodeshop.com/web/#/34?page_id=2897
     * @param {*} data
     */
    getSearchProList: function getSearchProList(params) {
      return request({
        url: '/svc/marketing-svc/admin/v1/give-like-goods/search-goods',
        method: 'get',
        params: params
      });
    },

    /**
     * 根据商品id获取点赞列表
     * http://doc.vodeshop.com/web/#/34?page_id=2911
     * @param {*} data
     */
    getThumbList: function getThumbList(params) {
      return request({
        url: '/svc/marketing-svc/admin/v1/give-likes',
        method: 'get',
        params: params
      });
    },

    /**
     * 根据id获取点赞详情
     * http://doc.vodeshop.com/web/#/34?page_id=2912
     * @param {*} data
     */
    getThumbDetail: function getThumbDetail(params) {
      return request({
        url: '/svc/marketing-svc/admin/v1/give-likes/info',
        method: 'get',
        params: params
      });
    },

    /**
     * 根据id获取点赞记录
     * http://doc.vodeshop.com/web/#/34?page_id=2912
     * @param {*} data
     */
    getRecordList: function getRecordList(params) {
      return request({
        url: '/svc/marketing-svc/admin/v1/give-likes/like-records',
        method: 'get',
        params: params
      });
    }
  };
});