var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "素材基础信息",
        visible: _vm.selProDialogShow,
        width: "40%",
        center: ""
      },
      on: {
        "update:visible": function($event) {
          _vm.selProDialogShow = $event
        },
        close: _vm.close
      }
    },
    [
      _c("div", { staticClass: "content-box" }, [
        _c("div", { staticClass: "flex justify-between" }, [
          _c("div", { staticClass: "flex-sub" }, [
            _c("div", { staticClass: "flex align-center mb-9" }, [
              _c("div", { staticClass: "label color-333 font-size-8" }, [
                _vm._v("查找商品：")
              ]),
              _c(
                "div",
                { staticClass: "flex-sub" },
                [
                  _c("el-input", {
                    staticClass: "input-width-100",
                    attrs: { size: "mini" },
                    model: {
                      value: _vm.keyWord,
                      callback: function($$v) {
                        _vm.keyWord = $$v
                      },
                      expression: "keyWord"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "flex align-center mb-9" }, [
              _c("div", { staticClass: "label color-333 font-size-8" }, [
                _vm._v("分类查找：")
              ]),
              _c(
                "div",
                { staticClass: "flex-sub" },
                [
                  _c("el-cascader", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      props: _vm.props,
                      size: "mini",
                      "show-all-levels": false
                    },
                    model: {
                      value: _vm.categoryValue,
                      callback: function($$v) {
                        _vm.categoryValue = $$v
                      },
                      expression: "categoryValue"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  staticClass: "ml-5",
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.search }
                },
                [_vm._v("查找")]
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "pro-list-box" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "infinite-scroll",
                  rawName: "v-infinite-scroll",
                  value: _vm.load,
                  expression: "load"
                }
              ],
              staticClass: "scroll-box",
              attrs: {
                "infinite-scroll-disabled": "busy",
                "infinite-scroll-immediate": "false"
              }
            },
            [
              _vm._l(_vm.proList, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass:
                      "pro-item mb-9 flex justify-between align-center"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "pro-left-part flex align-center flex-sub"
                      },
                      [
                        _c("el-image", {
                          staticClass: "pro-img",
                          attrs: {
                            src: _vm._f("qiniu")(item.image),
                            fit: "contain"
                          }
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "pro-left-info color-333 font-size-8  ml-10"
                          },
                          [
                            _c("div", { staticClass: "mb-5 hidden-ellipsis" }, [
                              _vm._v(_vm._s(item.name))
                            ]),
                            _c("div", { staticClass: "mb-5" }, [
                              _vm._v("价格：" + _vm._s(item.price) + "元")
                            ]),
                            _c("div", { staticClass: "mb-5" }, [
                              _vm._v("会员价：" + _vm._s(item.vip_price) + "元")
                            ]),
                            _c("div", [_vm._v(_vm._s(item.updated_at))])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "ml-5",
                            attrs: { size: "mini", type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.selPro(item)
                              }
                            }
                          },
                          [_vm._v("选择")]
                        )
                      ],
                      1
                    )
                  ]
                )
              }),
              _vm.proList.length && _vm.isFinished
                ? _c(
                    "div",
                    { staticClass: "mt-10 color-333 font-size-9 text-center" },
                    [_vm._v("已经没有更多啦~")]
                  )
                : _vm._e(),
              _vm.proList.length && _vm.isLoading
                ? _c(
                    "div",
                    { staticClass: "mt-10 color-333 font-size-9 text-center" },
                    [
                      _c("i", { staticClass: "el-icon-loading" }),
                      _vm._v("加载中~")
                    ]
                  )
                : _vm._e()
            ],
            2
          ),
          !_vm.proList.length && _vm.firstLoad
            ? _c("div", { staticClass: "empty-tip color-333 font-size-9" }, [
                _vm._v("请输入搜索条件进行检索~")
              ])
            : _vm._e(),
          !_vm.proList.length && !_vm.firstLoad
            ? _c("div", { staticClass: "empty-tip color-333 font-size-9" }, [
                _vm._v("暂无相关商品~")
              ])
            : _vm._e()
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }