var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { class: { "page-compact": _vm.crud.pageOptions.compact } },
    [
      _c(
        "d2-crud-x",
        _vm._g(
          _vm._b(
            {
              ref: "d2Crud",
              on: {
                "custom-ok": _vm.checkOk,
                "custom-checked": _vm.allowRenewal,
                "custom-refuse": _vm.refuseRenewal
              }
            },
            "d2-crud-x",
            _vm._crudProps,
            false
          ),
          _vm._crudListeners
        ),
        [
          _c(
            "div",
            { attrs: { slot: "header" }, slot: "header" },
            [
              _c("crud-search", {
                ref: "search",
                attrs: { options: _vm.crud.searchOptions },
                on: { submit: _vm.handleSearch }
              }),
              _c("crud-toolbar", {
                attrs: {
                  compact: _vm.crud.pageOptions.compact,
                  columns: _vm.crud.columns
                },
                on: {
                  "update:compact": function($event) {
                    return _vm.$set(_vm.crud.pageOptions, "compact", $event)
                  },
                  refresh: function($event) {
                    return _vm.doRefresh()
                  },
                  "columns-filter-changed": _vm.handleColumnsFilterChanged
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }