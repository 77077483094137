import { request } from '@/api/service';
export default {
  // 获取活动列表 http://doc.vodeshop.com/web/#/34?page_id=2795
  GetActivityList: function GetActivityList(params) {
    return request({
      url: '/svc/marketing-svc/admin/v1/regress/activity/list',
      method: 'get',
      params: params
    });
  },
  // 创建活动 http://doc.vodeshop.com/web/#/34?page_id=2797
  CreateActivity: function CreateActivity(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/regress/activity/create',
      method: 'post',
      data: data
    });
  },
  // 编辑活动 http://doc.vodeshop.com/web/#/34?page_id=2797
  EditActivity: function EditActivity(data, id) {
    return request({
      url: "/svc/marketing-svc/admin/v1/regress/activity/edit/".concat(id),
      method: 'post',
      data: data
    });
  },
  // 编辑活动状态 http://doc.vodeshop.com/web/#/34?page_id=2798
  EditActivityStstus: function EditActivityStstus(params) {
    return request({
      url: '/svc/marketing-svc/admin/v1/regress/activity/set-status',
      method: 'get',
      params: params
    });
  },
  // 获取所有礼包 http://doc.vodeshop.com/web/#/34?page_id=2805
  getAllGift: function getAllGift() {
    return request({
      url: '/svc/marketing-svc/admin/v1/regress/gift/get-all',
      method: 'get'
    });
  },
  // 获取所有红包 http://doc.vodeshop.com/web/#/34?page_id=2806
  getAllRed: function getAllRed() {
    return request({
      url: '/svc/marketing-svc/admin/v1/regress/red-packet/get-all',
      method: 'get'
    });
  },
  // 获取规则
  getRuleAll: function getRuleAll(params) {
    return request({
      url: '/svc/marketing-svc/admin/v1/regress/activity/get-rule-data',
      method: 'get',
      params: params
    });
  }
};