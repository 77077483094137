import { request } from '@/api/service';
export default {
  /**
   * 流量明细列表接口
   * http://doc.vodeshop.com/web/#/34?page_id=2961
   * @param {*} data
   */
  getDataList: function getDataList(params) {
    return request({
      url: '/svc/marketing-svc/admin/v1/give-likes-store/flow-logs',
      method: 'get',
      params: params
    });
  }
};