export default {
  path: '/shopping',
  title: '商城&美肌',
  icon: 'heart',
  children: [// {
  //   title: '扩展应用',
  //   icon: 'folder-open-o',
  //   children: [
  //     {
  //       title: '用户回归',
  //       icon: 'star',
  //       children: [
  //         {
  //           title: '回归活动管理',
  //           icon: 'trophy',
  //           path: '/shopping/extend/regress/activityMag'
  //         },
  //         // {
  //         //   title: '回归礼包管理',
  //         //   icon: 'gift',
  //         //   path: '/shopping/extend/regress/giftMag'
  //         // },
  //         {
  //           title: '回归规则管理',
  //           icon: 'bell',
  //           path: '/shopping/extend/regress/ruleMag'
  //         },
  //         {
  //           title: '回归用户明细',
  //           icon: 'address-book-o',
  //           path: '/shopping/extend/regress/userDetail'
  //         },
  //         {
  //           title: '回归红包明细',
  //           icon: 'hacker-news',
  //           path: '/shopping/extend/regress/redPacketDetail'
  //         },
  //         {
  //           title: '回归订单明细',
  //           icon: 'sticky-note',
  //           path: '/shopping/extend/regress/orderDetail'
  //         },
  //         {
  //           title: '回归活动概括',
  //           icon: 'home',
  //           path: '/shopping/extend/regress/activitySum'
  //         }
  //       ]
  //     }
  //   ]
  // },
  {
    title: '营销工具',
    icon: 'send-o',
    children: [{
      title: '点个赞活动',
      icon: 'hand-pointer-o',
      children: [{
        title: '点个赞商品',
        icon: 'asterisk',
        path: '/shopping/marketingTool/thumbUp/productMag'
      }, {
        title: '点个赞商品分类',
        icon: 'folder-open-o',
        path: '/shopping/marketingTool/thumbUp/productClassify'
      }, {
        title: '点个赞明细',
        icon: 'calendar',
        path: '/shopping/marketingTool/thumbUp/thumbDetail'
      }, {
        title: '点个赞设置',
        icon: 'certificate',
        path: '/shopping/marketingTool/thumbUp/setting'
      }, {
        title: '点个赞虚拟内容',
        icon: 'eye',
        path: '/shopping/marketingTool/thumbUp/virtualContent'
      }]
    }]
  }, {
    title: '智慧店',
    icon: 'modx',
    children: [{
      title: '点个赞',
      icon: 'thumbs-o-up',
      children: [{
        title: '商家点个赞商品',
        icon: 'cart-plus',
        path: '/shopping/storeLike/thumbUp/thumbGoods'
      }, // {
      //   title: '智慧店兑换中心设置',
      //   icon: 'cog',
      //   path: '/shopping/storeLike/thumbUp/setting'
      // },
      {
        title: '智慧店兑换热度',
        icon: 'heartbeat',
        path: '/shopping/storeLike/thumbUp/exchangeHot'
      }, {
        title: '智慧店流量明细',
        icon: 'file',
        path: '/shopping/storeLike/thumbUp/dataDetail'
      }, {
        title: '智慧店热度明细',
        icon: 'file-text',
        path: '/shopping/storeLike/thumbUp/hotDetail'
      }, {
        title: '商家点个赞操作记录',
        icon: 'bars',
        path: '/shopping/storeLike/thumbUp/thumbRecord'
      }]
    }]
  }]
};