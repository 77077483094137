var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { class: { "page-compact": _vm.crud.pageOptions.compact } },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("crud-search", {
            ref: "search",
            attrs: { options: _vm.crud.searchOptions },
            on: { submit: _vm.handleSearch }
          }),
          _c(
            "el-button-group",
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.addRow }
                },
                [_c("i", { staticClass: "el-icon-plus" }), _vm._v(" 新增")]
              )
            ],
            1
          ),
          _c("crud-toolbar", {
            attrs: {
              search: _vm.crud.searchOptions.show,
              compact: _vm.crud.pageOptions.compact,
              columns: _vm.crud.columns
            },
            on: {
              "update:search": function($event) {
                return _vm.$set(_vm.crud.searchOptions, "show", $event)
              },
              "update:compact": function($event) {
                return _vm.$set(_vm.crud.pageOptions, "compact", $event)
              },
              refresh: function($event) {
                return _vm.doRefresh()
              },
              "columns-filter-changed": _vm.handleColumnsFilterChanged
            }
          })
        ],
        1
      ),
      _c(
        "d2-crud-x",
        _vm._g(
          _vm._b(
            {
              ref: "d2Crud",
              scopedSlots: _vm._u([
                {
                  key: "sign_countSlot",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "mini" },
                          on: {
                            click: function($event) {
                              return _vm.showDetail(scope.row.id)
                            }
                          }
                        },
                        [_vm._v(_vm._s(scope.row.sign_count))]
                      )
                    ]
                  }
                }
              ])
            },
            "d2-crud-x",
            _vm._crudProps,
            false
          ),
          _vm._crudListeners
        ),
        [
          _c(
            "template",
            { slot: "permissionTitleFormSlot" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "success", size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.checkAll(1)
                    }
                  }
                },
                [_vm._v("全选权限")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.checkAll(0)
                    }
                  }
                },
                [_vm._v("清空权限")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }