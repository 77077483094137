//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      dialogFormVisible: false,
      profitList: []
    };
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    isShow: {
      type: Boolean,
      default: true
    }
  },
  watch: {},
  methods: {
    lookDetail: function lookDetail(scope) {
      // console.log(scope)
      this.profitList = scope.row.item_goods;
      this.dialogFormVisible = true;
    }
  }
};