import mockUtil from '@/api/mock/base';
var options = {
  name: 'icon',
  idGenerator: 0
};
var list = [{
  name: '张三',
  province: 'gz',
  iconSelect: 'check',
  iconSelect2: 'check'
}, {
  name: '李四',
  province: 'sh',
  iconSelect: 'check',
  iconSelect2: 'check'
}];
options.list = list;
var mock = mockUtil.buildMock(options);
export default mock;