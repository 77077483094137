var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { class: { "page-compact": _vm.crud.pageOptions.compact } },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c("div", { staticClass: "header-content" }, [
            _c("span", { staticStyle: { "margin-right": "30px" } }, [
              _vm._v("所有素材")
            ]),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.toAddMaterial(1)
                      }
                    }
                  },
                  [_vm._v("新增图片素材")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.toAddMaterial(2)
                      }
                    }
                  },
                  [_vm._v("新增视频素材")]
                )
              ],
              1
            )
          ]),
          _c("crud-search", {
            ref: "search",
            attrs: { options: _vm.crud.searchOptions },
            on: { submit: _vm.handleSearch }
          }),
          _c("crud-toolbar", {
            attrs: {
              search: _vm.crud.searchOptions.show,
              compact: _vm.crud.pageOptions.compact,
              columns: _vm.crud.columns
            },
            on: {
              "update:search": function($event) {
                return _vm.$set(_vm.crud.searchOptions, "show", $event)
              },
              "update:compact": function($event) {
                return _vm.$set(_vm.crud.pageOptions, "compact", $event)
              },
              refresh: function($event) {
                return _vm.doRefresh()
              },
              "columns-filter-changed": _vm.handleColumnsFilterChanged
            }
          })
        ],
        1
      ),
      _c(
        "d2-crud-x",
        _vm._g(
          _vm._b(
            { ref: "d2Crud", on: { edit: _vm.handleClick } },
            "d2-crud-x",
            _vm._crudProps,
            false
          ),
          _vm._crudListeners
        )
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }