var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { class: { "page-compact": _vm.crud.pageOptions.compact } },
    [
      _c("template", { slot: "header" }, [_vm._v("D2-Crud-Plus")]),
      _c(
        "d2-crud-x",
        _vm._g(
          _vm._b(
            {
              ref: "d2Crud",
              on: { customHandleBtn: _vm.customHandleBtnHandle },
              scopedSlots: _vm._u([
                {
                  key: "expandSlot",
                  fn: function(scope) {
                    return [
                      _vm._v(
                        " 这里显示行展开数据:" + _vm._s(scope.row.data) + " "
                      )
                    ]
                  }
                }
              ])
            },
            "d2-crud-x",
            _vm._crudProps,
            false
          ),
          _vm._crudListeners
        ),
        [
          _c(
            "div",
            { attrs: { slot: "header" }, slot: "header" },
            [
              _c("crud-search", {
                ref: "search",
                attrs: { options: _vm.crud.searchOptions },
                on: { submit: _vm.handleSearch }
              }),
              _c(
                "el-button-group",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.addRow }
                    },
                    [_c("i", { staticClass: "el-icon-plus" }), _vm._v(" 新增")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "danger" },
                      on: { click: _vm.batchDelete }
                    },
                    [
                      _c("i", { staticClass: "el-icon-delete" }),
                      _vm._v(" 批量删除")
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.checkSecond }
                    },
                    [_vm._v("选中第一、二行")]
                  )
                ],
                1
              ),
              _c(
                "crud-toolbar",
                _vm._g(
                  _vm._b({}, "crud-toolbar", _vm._crudToolbarProps, false),
                  _vm._crudToolbarListeners
                )
              )
            ],
            1
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }