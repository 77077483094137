var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    {
      class: { "page-compact": _vm.crud.pageOptions.compact },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c("crud-search", {
                ref: "search",
                attrs: { options: _vm.crud.searchOptions },
                on: { submit: _vm.handleSearch }
              }),
              _c(
                "el-button-group",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.addRow }
                    },
                    [_c("i", { staticClass: "el-icon-plus" }), _vm._v(" 新增")]
                  )
                ],
                1
              ),
              _c("crud-toolbar", {
                attrs: {
                  search: _vm.crud.searchOptions.show,
                  compact: _vm.crud.pageOptions.compact,
                  columns: _vm.crud.columns
                },
                on: {
                  "update:search": function($event) {
                    return _vm.$set(_vm.crud.searchOptions, "show", $event)
                  },
                  "update:compact": function($event) {
                    return _vm.$set(_vm.crud.pageOptions, "compact", $event)
                  },
                  refresh: function($event) {
                    return _vm.doRefresh()
                  },
                  "columns-filter-changed": _vm.handleColumnsFilterChanged
                }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "d2-crud-x",
        _vm._g(
          _vm._b({ ref: "d2Crud" }, "d2-crud-x", _vm._crudProps, false),
          _vm._crudListeners
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }