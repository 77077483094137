import "core-js/modules/es.array.concat.js";
export default (function (_ref) {
  var request = _ref.request;
  return {
    GetVersion: function GetVersion(data) {
      return request({
        url: "/svc/marketing-svc/api/personal-center-function?version=".concat(data.version, "&type=").concat(data.type),
        method: 'get'
      });
    },
    // 获取商品列表
    GetProList: function GetProList(data) {
      return request({
        url: "/svc/marketing-svc/admin/v1/goods/search?keyword=".concat(data.keyWord, "&category_id=").concat(data.category_id, "&page=").concat(data.page),
        method: 'get'
      });
    },
    // 获取商品分类列表
    GetProCategoryList: function GetProCategoryList(params) {
      return request({
        url: "/svc/marketing-svc/admin/v1/goods/category?category_id=".concat(params.category_id),
        method: 'get'
      });
    }
  };
});