import { request } from '@/api/service';
export default {
  /**
   * 虚拟文案列表接口
   * http://doc.vodeshop.com/web/#/34?page_id=2907
   * @param {*} data
   */
  getContentList: function getContentList(params) {
    return request({
      url: '/svc/marketing-svc/admin/v1/give-like-barrages',
      method: 'get',
      params: params
    });
  },

  /**
   * 添加虚拟文案
   * http://doc.vodeshop.com/web/#/34?page_id=2732
   * @param {*} data
   */
  addContent: function addContent(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/give-like-barrages/store',
      method: 'post',
      data: data
    });
  },

  /**
   * 编辑虚拟文案
   * http://doc.vodeshop.com/web/#/34?page_id=2909
   * @param {*} data
   */
  editContent: function editContent(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/give-like-barrages/update',
      method: 'put',
      data: data
    });
  },

  /**
   * 删除众筹商品
   * http://doc.vodeshop.com/web/#/34?page_id=2910
   * @param {*} data
   */
  deleteContent: function deleteContent(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/give-like-barrages/delete',
      method: 'delete',
      data: data
    });
  }
};