import _objectSpread from "/www/wwwroot/test.admin.vodeshop.com/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
import cookies from './util.cookies';
import db from './util.db';
import log from './util.log';
import dayjs from 'dayjs';
import { IMGBASEURL } from '@/api/config';
var util = {
  cookies: cookies,
  db: db,
  log: log
};
/**
 * @description 更新标题
 * @param {String} title 标题
 */

util.title = function (titleText) {
  var processTitle = process.env.VUE_APP_TITLE || 'D2Admin';
  window.document.title = "".concat(processTitle).concat(titleText ? " | ".concat(titleText) : '');
};
/**
 * @description 打开新页面
 * @param {String} url 地址
 */


util.open = function (url) {
  var a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('target', '_blank');
  a.setAttribute('id', 'd2admin-link-temp');
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(document.getElementById('d2admin-link-temp'));
};
/**
 * 将任意对象转化为树
 * @param data
 * @param key
 * @param pid
 * @param parent
 * @returns {Array}
 */


util.formatDataToTree = function (data) {
  var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'menu_id';
  var pid = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'parent_id';
  var parent = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

  if (!data || Object.keys(data).length <= 0) {
    return [];
  }

  var map = {};
  var isSetParent = Object.keys(parent).length > 0;
  data.forEach(function (value) {
    if (isSetParent && parent.value.includes(value[parent.key])) {
      value[pid] = 0;
    }

    map[value[key]] = _objectSpread({}, value);
  });
  var tree = [];

  for (var id in data) {
    if (!Object.prototype.hasOwnProperty.call(data, id)) {
      continue;
    } // 对应索引


    var index = data[id][key];

    if (!Object.prototype.hasOwnProperty.call(map, index)) {
      continue;
    } // 子节点压入


    if (map[index][pid]) {
      if (!map[map[index][pid]]) {
        continue;
      }

      if (!Object.prototype.hasOwnProperty.call(map[map[index][pid]], 'children')) {
        map[map[index][pid]].children = [];
      }

      map[map[index][pid]].children.push(map[index]);
      continue;
    }

    tree.push(map[index]);
  }

  return tree;
};
/**
 *
 * @param {*} data对象
 * @description 日期格式化
 * @returns
 */


util.formatDate = function (data) {
  return dayjs(data).format('YYYY-MM-DD HH:mm:ss');
};
/**
 * @params {*} 文件名字符串
 * @description 判断是否为url
 */


util.convertHttp = function (url) {
  var regexp = new RegExp(/^http/);
  if (!url) return "".concat(IMGBASEURL, "gysa-default-logo.png");

  if (regexp.test(url)) {
    return url;
  } else {
    return "".concat(IMGBASEURL).concat(url.replace(/\\/g, '/'));
  }
};
/**
 *
 * @param {*} date1
 * @param {*} date2
 * @description 返回参数1 - 参数2的天数
 */


util.dateDiff = function (date_1, date_2) {
  var timeLine = dayjs(date_1).diff(dayjs(date_2)) / 1000;
  var second = parseInt(timeLine % (60 * 60) % 60);
  var minute = parseInt(timeLine % (60 * 60) / 60);
  var hour = parseInt(timeLine / 60 / 60);
  return {
    stamp: timeLine * 1000,
    seconds: timeLine,
    minutes: parseInt(timeLine / 60),
    hours: parseInt(timeLine / 60 / 60),
    days: parseInt(timeLine / 60 / 60 / 24),
    weeks: parseInt(timeLine / 60 / 60 / 24 / 7),
    time: "".concat(hour < 10 ? "0".concat(hour) : hour, ":").concat(minute < 10 ? "0".concat(minute) : minute, ":").concat(second < 10 ? "0".concat(second) : second)
  };
};
/**
 * json转字典
 * @param {*} json
 * @returns
 */


util.jsonToDict = function (json) {
  var arr = [];

  for (var i in json) {
    arr.push({
      key: i,
      value: json[i]
    });
  }

  return arr;
};

export default util;