import { request } from '@/api/service';
export default {
  /**
   * 获取赞明细列表
   * http://doc.vodeshop.com/web/#/34?page_id=2886
   * @param {*} data
   */
  getProList: function getProList(params) {
    return request({
      url: '/svc/marketing-svc/admin/v1/give-like-goods/index',
      method: 'get',
      params: params
    });
  },

  /**
   * 点赞详情接口
   * http://doc.vodeshop.com/web/#/34?page_id=2892
   * @param {*} data
   */
  getDetail: function getDetail(params) {
    return request({
      url: '/svc/marketing-svc/admin/v1/give-like-goods/show',
      method: 'get',
      params: params
    });
  },

  /**
   * 点赞详情接口
   * http://doc.vodeshop.com/web/#/34?page_id=2892
   * @param {*} data
   */
  editProductStatus: function editProductStatus(data) {
    return request({
      url: '/svc/marketing-svc//admin/v1/give-like-goods/enable',
      method: 'put',
      data: data
    });
  }
};