export default (function (_ref) {
  var request = _ref.request;
  return {
    /**
     * 获取活动列表
     * http://doc.vodeshop.com/web/#/34?page_id=2626
     * @param {*} data
     */
    getActivityList: function getActivityList(params) {
      return request({
        url: '/svc/marketing-svc/admin/v1/activity/index',
        method: 'get',
        params: params
      });
    },

    /**
     * 获取来源列表
     * http://doc.vodeshop.com/web/#/34?page_id=2636
     * @param {*} data
     */
    getActivityReleaseType: function getActivityReleaseType(params) {
      return request({
        url: '/svc/marketing-svc/admin/v1/activity/release-type',
        method: 'get',
        params: params
      });
    },

    /**
     * 获取活动分类
     * http://doc.vodeshop.com/web/#/34?page_id=2637
     * @param {*} params
     */
    getActivityCategory: function getActivityCategory(params) {
      return request({
        url: '/svc/marketing-svc/admin/v1/activity/category',
        method: 'get',
        params: params
      });
    },

    /**
     * 创建活动
     * http://doc.vodeshop.com/web/#/34?page_id=2624
     * @param {*} data
     */
    addActivityStore: function addActivityStore(data) {
      return request({
        url: '/svc/marketing-svc/admin/v1/activity/store',
        method: 'post',
        data: data
      });
    }
  };
});