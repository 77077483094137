var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [_vm._v("Page 3 header")]),
      _c(
        "el-upload",
        {
          attrs: {
            action: _vm.QINIUURL,
            multiple: true,
            data: _vm.dataToken,
            "on-remove": _vm.handleRemove,
            "on-success": _vm.handleSuccess,
            "before-upload": _vm.beforeUpload,
            "list-type": "picture-card"
          }
        },
        [_c("i", { staticClass: "el-icon-plus" })]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }