import { request } from '@/api/service';
export default {
  /**
   * 众创订单续期审核列表 接口
   * http://doc.vodeshop.com/web/#/34?page_id=2940
   * @param {*} data
   */
  getRenewalList: function getRenewalList(params) {
    return request({
      url: '/svc/marketing-svc/admin/v1/raise-order/renewal-list',
      method: 'get',
      params: params
    });
  },

  /**
   * 众创订单续期审核
   * http://doc.vodeshop.com/web/#/34?page_id=2748
   * @param {*} data
   */
  editRenewal: function editRenewal(data) {
    return request({
      url: '/svc/marketing-svc/admin/v1/raise-order/renewal-audit',
      method: 'put',
      data: data
    });
  }
};