//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    value: {
      type: Array,
      required: true
    },
    isShow: {
      type: Boolean,
      default: true
    }
  },
  watch: {},
  methods: {}
};