var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { class: { "page-compact": _vm.crud.pageOptions.compact } },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("权限管理")
      ]),
      _c(
        "d2-crud-x",
        _vm._g(
          _vm._b(
            {
              ref: "d2Crud",
              scopedSlots: _vm._u([
                {
                  key: "competenceFormSlot",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-row",
                        { attrs: { type: "flex", align: "center" } },
                        [
                          _c("el-col", { attrs: { span: 4 } }, [
                            _c(
                              "span",
                              {
                                style: {
                                  color: _vm.isCheckout ? "#C0C4CC" : "#606266"
                                }
                              },
                              [_vm._v("商城&美肌：")]
                            )
                          ]),
                          _c(
                            "el-col",
                            { attrs: { span: 20 } },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  attrs: { disabled: _vm.isCheckout },
                                  model: {
                                    value: scope.form.competence.mall,
                                    callback: function($$v) {
                                      _vm.$set(
                                        scope.form.competence,
                                        "mall",
                                        $$v
                                      )
                                    },
                                    expression: "scope.form.competence.mall"
                                  }
                                },
                                _vm._l(_vm.mall, function(item, index) {
                                  return _c("el-checkbox", {
                                    key: index,
                                    attrs: { value: item.id, label: item.title }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { type: "flex", align: "center" } },
                        [
                          _c("el-col", { attrs: { span: 4 } }, [
                            _c(
                              "span",
                              {
                                style: {
                                  color: _vm.isCheckout ? "#C0C4CC" : "#606266"
                                }
                              },
                              [_vm._v("名媛私享：")]
                            )
                          ]),
                          _c(
                            "el-col",
                            { attrs: { span: 20 } },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  attrs: { disabled: _vm.isCheckout },
                                  model: {
                                    value: scope.form.competence.dym,
                                    callback: function($$v) {
                                      _vm.$set(
                                        scope.form.competence,
                                        "dym",
                                        $$v
                                      )
                                    },
                                    expression: "scope.form.competence.dym"
                                  }
                                },
                                _vm._l(_vm.dym, function(item, index) {
                                  return _c("el-checkbox", {
                                    key: index,
                                    attrs: { value: item.id, label: item.title }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { type: "flex", align: "center" } },
                        [
                          _c("el-col", { attrs: { span: 4 } }, [
                            _c(
                              "span",
                              {
                                style: {
                                  color: _vm.isCheckout ? "#C0C4CC" : "#606266"
                                }
                              },
                              [_vm._v("O2O新零售：")]
                            )
                          ]),
                          _c(
                            "el-col",
                            { attrs: { span: 20 } },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  attrs: { disabled: _vm.isCheckout },
                                  model: {
                                    value: scope.form.competence.retail,
                                    callback: function($$v) {
                                      _vm.$set(
                                        scope.form.competence,
                                        "retail",
                                        $$v
                                      )
                                    },
                                    expression: "scope.form.competence.retail"
                                  }
                                },
                                _vm._l(_vm.retail, function(item, index) {
                                  return _c("el-checkbox", {
                                    key: index,
                                    attrs: { value: item.id, label: item.title }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { type: "flex", align: "center" } },
                        [
                          _c("el-col", { attrs: { span: 4 } }, [
                            _c(
                              "span",
                              {
                                style: {
                                  color: _vm.isCheckout ? "#C0C4CC" : "#606266"
                                }
                              },
                              [_vm._v("美肌S计划：")]
                            )
                          ]),
                          _c(
                            "el-col",
                            { attrs: { span: 20 } },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  attrs: { disabled: _vm.isCheckout },
                                  model: {
                                    value: scope.form.competence.slim,
                                    callback: function($$v) {
                                      _vm.$set(
                                        scope.form.competence,
                                        "slim",
                                        $$v
                                      )
                                    },
                                    expression: "scope.form.competence.slim"
                                  }
                                },
                                _vm._l(_vm.slim, function(item, index) {
                                  return _c("el-checkbox", {
                                    key: index,
                                    attrs: { value: item.id, label: item.title }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      !_vm.isCheckout
                        ? _c(
                            "el-row",
                            { attrs: { type: "flex", align: "center" } },
                            [
                              _c("el-col", { attrs: { span: 4 } }),
                              _c(
                                "el-col",
                                { attrs: { span: 20 } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "primary" },
                                      on: { click: _vm.selectAll }
                                    },
                                    [_vm._v("全选权限")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "danger" },
                                      on: { click: _vm.clearAll }
                                    },
                                    [_vm._v("清空权限")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  }
                }
              ])
            },
            "d2-crud-x",
            _vm._crudProps,
            false
          ),
          _vm._crudListeners
        ),
        [
          _c(
            "div",
            { attrs: { slot: "header" }, slot: "header" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.addRow }
                },
                [_c("i", { staticClass: "el-icon-plus" }), _vm._v(" 新增")]
              ),
              _c("crud-toolbar", {
                attrs: {
                  compact: _vm.crud.pageOptions.compact,
                  columns: _vm.crud.columns
                },
                on: {
                  "update:compact": function($event) {
                    return _vm.$set(_vm.crud.pageOptions, "compact", $event)
                  },
                  refresh: function($event) {
                    return _vm.doRefresh()
                  },
                  "columns-filter-changed": _vm.handleColumnsFilterChanged
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }