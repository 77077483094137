import { request } from '@/api/service';
var prefix = '/svc/marketing-svc';
export default {
  getCouponCategoryList: function getCouponCategoryList(params) {
    return request({
      url: "".concat(prefix, "/admin/v1/regress/coupon-category"),
      method: 'get',
      params: params
    });
  },
  addCouponCategory: function addCouponCategory(data) {
    return request({
      url: "".concat(prefix, "/admin/v1/regress/coupon-category"),
      method: 'post',
      data: data
    });
  },
  updateCouponCategory: function updateCouponCategory(data) {
    return request({
      url: "".concat(prefix, "/admin/v1/regress/coupon-category"),
      method: 'put',
      data: data
    });
  },
  updateCouponCategoryStatus: function updateCouponCategoryStatus(data) {
    return request({
      url: "".concat(prefix, "/admin/v1/regress/coupon-category"),
      method: 'put',
      data: data
    });
  },
  deleteCouponCategory: function deleteCouponCategory(id) {
    return request({
      url: "".concat(prefix, "/admin/v1/regress/coupon-category?id=").concat(id),
      method: 'delete'
    });
  }
};