import _objectSpread from "/www/wwwroot/test.admin.vodeshop.com/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import _createForOfIteratorHelper from "/www/wwwroot/test.admin.vodeshop.com/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.slice.js";

function copyList(originList, newList, options, parentId) {
  var _iterator = _createForOfIteratorHelper(originList),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var item = _step.value;

      var newItem = _objectSpread(_objectSpread({}, item), {}, {
        parentId: parentId
      });

      newItem.id = ++options.idGenerator;
      newList.push(newItem);

      if (item.children != null) {
        newItem.children = [];
        copyList(item.children, newItem.children, options, newItem.id);
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
}

function delById(req, list) {
  for (var i = 0; i < list.length; i++) {
    var item = list[i];

    if (item.id === parseInt(req.params.id)) {
      console.log('remove i');
      list.splice(i, 1);
      break;
    }

    if (item.children != null && item.children.length > 0) {
      delById(req, item.children);
    }
  }
}

function findById(id, list) {
  var _iterator2 = _createForOfIteratorHelper(list),
      _step2;

  try {
    for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
      var item = _step2.value;

      if (item.id === id) {
        return item;
      }

      if (item.children != null && item.children.length > 0) {
        var sub = findById(id, item.children);

        if (sub != null) {
          return sub;
        }
      }
    }
  } catch (err) {
    _iterator2.e(err);
  } finally {
    _iterator2.f();
  }
}

export default {
  findById: findById,
  buildMock: function buildMock(options) {
    var name = options.name;

    if (options.copyTimes == null) {
      options.copyTimes = 29;
    }

    var list = [];

    for (var i = 0; i < options.copyTimes; i++) {
      copyList(options.list, list, options);
    }

    options.list = list;
    return [{
      path: '/' + name + '/page',
      method: 'get',
      handle: function handle(req) {
        var data = list;
        var size = 20;
        req.body.current = req.body.page;
        req.body.size = req.body.per_page;
        var current = 1;

        var _iterator3 = _createForOfIteratorHelper(list),
            _step3;

        try {
          for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
            var item = _step3.value;

            if (item.children != null && item.children.length === 0) {
              item.hasChildren = false;
              item.lazy = false;
            }
          }
        } catch (err) {
          _iterator3.e(err);
        } finally {
          _iterator3.f();
        }

        if (req != null && req.body != null) {
          if (req.body.size != null) {
            size = parseInt(req.body.size);
          }

          if (req.body.current != null) {
            current = parseInt(req.body.current);
          }

          var query = _objectSpread({}, req.body);

          delete query.current;
          delete query.size;
          delete query.per_page;
          delete query.page;

          if (Object.keys(query).length > 0) {
            data = list.filter(function (item) {
              var allFound = true; // 是否所有条件都符合

              for (var key in query) {
                // 判定某一个条件
                var value = query[key];

                if (value == null || value === '') {
                  continue;
                }

                if (value instanceof Array) {
                  // 如果条件中的value是数组的话，只要查到一个就行
                  if (value.length === 0) {
                    continue;
                  }

                  var found = false;

                  var _iterator4 = _createForOfIteratorHelper(value),
                      _step4;

                  try {
                    for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
                      var _i = _step4.value;

                      if (item[key] instanceof Array) {
                        var _iterator5 = _createForOfIteratorHelper(item[key]),
                            _step5;

                        try {
                          for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
                            var j = _step5.value;

                            if (_i === j) {
                              found = true;
                              break;
                            }
                          }
                        } catch (err) {
                          _iterator5.e(err);
                        } finally {
                          _iterator5.f();
                        }

                        if (found) {
                          break;
                        }
                      } else if (item[key] === _i || typeof item[key] === 'string' && item[key].indexOf(_i + '') >= 0) {
                        found = true;
                        break;
                      }

                      if (found) {
                        break;
                      }
                    }
                  } catch (err) {
                    _iterator4.e(err);
                  } finally {
                    _iterator4.f();
                  }

                  if (!found) {
                    allFound = false;
                  }
                } else if (value instanceof Object) {
                  for (var key2 in value) {
                    var v = value[key2];

                    if (v && item[key] && v !== item[key][key2]) {
                      return false;
                    }
                  }
                } else if (item[key] !== value) {
                  allFound = false;
                }
              }

              return allFound;
            });
          }
        }

        var start = size * (current - 1);
        var end = size * current;

        if (data.length < end) {
          end = data.length;
        }

        var records = data.slice(start, end);
        var maxPage = data.length % size === 0 ? data.length / size : Math.floor(data.length / size) + 1;

        if (current > maxPage) {
          current = maxPage;
        }

        if (current < 1) {
          current = 1;
        }

        return {
          code: 0,
          msg: 'success',
          data: {
            data: records,
            total: data.length,
            per_page: size,
            current_page: current
          }
        };
      }
    }, {
      path: '/' + name + '/get',
      method: 'get',
      handle: function handle(req) {
        var id = req.params.id;
        id = parseInt(id);
        var current = null;

        var _iterator6 = _createForOfIteratorHelper(list),
            _step6;

        try {
          for (_iterator6.s(); !(_step6 = _iterator6.n()).done;) {
            var item = _step6.value;

            if (item.id === id) {
              current = item;
              break;
            }
          }
        } catch (err) {
          _iterator6.e(err);
        } finally {
          _iterator6.f();
        }

        return {
          code: 0,
          msg: 'success',
          data: current
        };
      }
    }, {
      path: '/' + name + '/add',
      method: 'post',
      handle: function handle(req) {
        req.body.id = ++options.idGenerator;
        list.unshift(req.body);
        return {
          code: 0,
          msg: 'success',
          data: req.body.id
        };
      }
    }, {
      path: '/' + name + '/update',
      method: 'post',
      handle: function handle(req) {
        var item = findById(req.body.id, list);

        if (item) {
          Object.assign(item, req.body);
        }

        return {
          code: 0,
          msg: 'success',
          data: null
        };
      }
    }, {
      path: '/' + name + '/delete',
      method: 'post',
      handle: function handle(req) {
        delById(req, list);
        return {
          code: 0,
          msg: 'success',
          data: null
        };
      }
    }, {
      path: '/' + name + '/batchDelete',
      method: 'post',
      handle: function handle(req) {
        var ids = req.body.ids;

        for (var _i2 = list.length - 1; _i2 >= 0; _i2--) {
          var item = list[_i2];

          if (ids.indexOf(item.id) >= 0) {
            list.splice(_i2, 1);
          }
        }

        return {
          code: 0,
          msg: 'success',
          data: null
        };
      }
    }];
  }
};