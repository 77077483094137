import { ORDER_STATUS } from './dict';
import util from '@/libs/util';
export var crudOptions = function crudOptions(vm) {
  return {
    pageOptions: {
      export: {
        local: false
      }
    },
    options: {
      height: '100%',
      // 表格高度100%,使用toolbar必须设置
      rowKey: 'id',
      stripe: true
    },
    viewOptions: {
      disabled: false
    },
    rowHandle: false,
    columns: [{
      title: '商城订单ID',
      key: 'order_id',
      width: '100',
      type: 'el-input',
      align: 'center',
      search: {
        disabled: false
      }
    }, {
      title: '众创订单ID',
      key: 'raise_order_id',
      align: 'center',
      type: 'el-input',
      search: {
        disabled: false
      }
    }, {
      title: '众创商品ID',
      key: 'raise_goods_item_id',
      type: 'el-input',
      align: 'center',
      search: {
        disabled: false
      }
    }, {
      title: '商品ID',
      key: 'goods_id',
      type: 'el-input',
      align: 'center',
      search: {
        disabled: false
      }
    }, {
      title: '用户ID',
      key: 'user_id',
      align: 'center',
      type: 'el-input',
      search: {
        disabled: false
      }
    }, {
      title: '购买数量',
      key: 'count',
      type: 'el-input',
      align: 'center',
      search: {
        disabled: true
      }
    }, {
      title: '金额（元）',
      key: 'pay_price',
      type: 'el-input',
      align: 'center',
      search: {
        disabled: true
      }
    }, {
      title: '订单状态',
      type: 'radio',
      key: 'order_status',
      align: 'center',
      dict: {
        data: ORDER_STATUS
      },
      search: {
        disabled: false,
        component: {
          name: 'dict-select'
        }
      }
    }, {
      title: '返利用户ID',
      key: 'profit_user_id',
      align: 'center',
      type: 'el-input',
      search: {
        disabled: true
      }
    }, {
      title: '返利金额',
      key: 'profit_price',
      align: 'center',
      type: 'el-input',
      search: {
        disabled: true
      }
    }, {
      title: '差价用户ID',
      key: 'parent_profit_user_id',
      align: 'center',
      type: 'el-input',
      search: {
        disabled: true
      }
    }, {
      title: '差价金额',
      key: 'parent_profit_price',
      align: 'center',
      type: 'el-input',
      search: {
        disabled: true
      }
    }, {
      title: '创建时间',
      key: 'created_at',
      type: 'datetimerange',
      align: 'center',
      search: {
        width: 400,
        disabled: false,
        component: {
          // 查询 使用选择框组件，并且是可以清除的
          props: {
            'time-arrow-control': true,
            'default-time': ['00:00:00', '00:00:00'] // 'picker-options': { shortcuts: ShortCUTS }

          }
        } // slot: true

      },
      rowSlot: true,
      form: {
        slot: true,
        addDisabled: true,
        // 是否仅在添加编辑框中关闭该字段
        editDisabled: true // 是否仅在修改编辑框中关闭该字段

      },
      valueResolve: function valueResolve(row, key) {
        var _row$created_at;

        if (((_row$created_at = row.created_at) === null || _row$created_at === void 0 ? void 0 : _row$created_at.length) > 1) {
          row.start_created_at = util.formatDate(row.created_at[0]);
          row.end_created_at = util.formatDate(row.created_at[1]);
        }
      }
    }]
  };
};