import layoutHeaderAside from '@/layout/header-aside'; // 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载

var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var meta = {
  auth: true
};
export default {
  path: '/marketing',
  name: 'marketing',
  meta: meta,
  redirect: {
    name: 'marketing'
  },
  component: layoutHeaderAside,
  children: [{
    path: 'loveCircle/operation',
    name: 'operation',
    meta: {
      title: '运营状态',
      auth: true
    },
    component: _import('marketing/loveCircle/operation/')
  }, {
    path: 'loveCircle/permission',
    name: 'template',
    meta: {
      title: '权限管理',
      auth: true
    },
    component: _import('marketing/loveCircle/permission')
  }, {
    path: 'loveCircle/category/allCategory',
    name: 'allcategory',
    meta: {
      title: '所有分类',
      auth: true
    },
    component: _import('marketing/loveCircle/category/allCategory')
  }, {
    path: 'loveCircle/category/addCategory',
    name: 'addCategory',
    meta: {
      title: '新增/编辑分类',
      auth: true
    },
    component: _import('marketing/loveCircle/category/addCategory')
  }, {
    path: 'loveCircle/allMaterial',
    name: 'allMaterial',
    meta: {
      title: '所有素材',
      auth: true
    },
    component: _import('marketing/loveCircle/material/allMaterial')
  }, // {
  //   path: 'loveCircle/material/issuerSetting',
  //   name: 'issuerSetting',
  //   meta: {
  //     title: '发布人身份设置',
  //     auth: true
  //   },
  //   component: _import('marketing/loveCircle/material/issuerSetting')
  // },
  {
    path: 'loveCircle/material/issuerSetting',
    name: 'issuerSetting',
    meta: {
      title: '发布人身份设置',
      auth: true
    },
    component: _import('marketing/loveCircle/material/issuerSetting/index.vue')
  }, {
    path: 'loveCircle/material/imageMaterial',
    name: 'imageMaterial',
    meta: {
      title: '图文素材',
      auth: true
    },
    component: _import('marketing/loveCircle/material/imageMaterial/')
  }, {
    path: 'loveCircle/material/videoMaterial',
    name: 'videoMaterial',
    meta: {
      title: '视频素材',
      auth: true
    },
    component: _import('marketing/loveCircle/material/videoMaterial/')
  }, {
    path: 'activities/activities',
    name: 'activitiesActivities',
    meta: {
      title: '所有活动',
      auth: true
    },
    component: _import('marketing/activities/activities/')
  }, {
    path: 'activities/participant',
    name: 'activitiesParticipant',
    meta: {
      title: '参与人记录',
      auth: true
    },
    component: _import('marketing/activities/participant/')
  }, // {
  //   path: 'coupon/couponMag',
  //   name: 'couponManager',
  //   meta: {
  //     title: '优惠券管理',
  //     auth: true
  //   },
  //   component: _import('marketing/coupon/couponMag')
  // },
  {
    path: 'coupon/couponMag',
    name: 'couponManager',
    meta: {
      title: '优惠券管理',
      auth: true
    },
    component: _import('marketing/coupon/couponMag')
  }, {
    path: 'coupon/couponCag',
    name: 'couponCategory',
    meta: {
      title: '优惠券分类',
      auth: true
    },
    component: _import('marketing/coupon/couponCag')
  }, {
    path: 'coupon/giftMag',
    name: 'giftMag',
    meta: {
      title: '优惠券礼包',
      auth: true
    },
    component: _import('marketing/coupon/giftMag')
  }, {
    path: 'redPacket/packetMag',
    name: 'redPacketManager',
    meta: {
      title: '红包管理',
      auth: true
    },
    component: _import('marketing/redPacket/packetMag')
  }, // {
  //   path: 'specialGoods/specialGoodsMag',
  //   name: 'specialGoodsMag',
  //   meta: {
  //     title: '活动专区商品管理',
  //     auth: true
  //   },
  //   component: _import('marketing/specialGoods/specialGoodsMag')
  // },
  {
    path: 'specialGoods/goodsMag',
    name: 'goodsManager',
    meta: {
      title: '专区商品管理',
      auth: true
    },
    component: _import('marketing/specialGoods/goodsMag')
  }, {
    path: 'specialGoods/goodsCag',
    name: 'goodsCategory',
    meta: {
      title: '专区商品分类管理',
      auth: true
    },
    component: _import('marketing/specialGoods/goodsCag')
  }, {
    path: 'specialGoods/specialActivity',
    name: 'specialActivity',
    meta: {
      title: '活动专区',
      auth: true
    },
    component: _import('marketing/specialGoods/specialActivity')
  }]
};