//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { AddObj, GetList, UpdateObj, DelObj } from './api';
import { crudOptions } from './crud';
import { d2CrudPlus } from 'd2-crud-plus';
export default {
  name: 'page4',
  components: {},
  mixins: [d2CrudPlus.crud],
  data: function data() {
    return {};
  },
  computed: {},
  methods: {
    // 搜索
    handleSearch: function handleSearch(e) {
      console.log(e);
    },
    getCrudOptions: function getCrudOptions() {
      return crudOptions;
    },
    pageRequest: function pageRequest(query) {
      return GetList(query);
    },
    addRequest: function addRequest(row) {
      return AddObj(row);
    },
    updateRequest: function updateRequest(row) {
      return UpdateObj(row);
    },
    delRequest: function delRequest(row) {
      return DelObj(row.id);
    }
  }
};