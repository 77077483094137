//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tableTemple from './table.vue';
export default {
  props: {
    detailDialogShow: {
      type: Boolean,
      required: false
    },
    detailInfo: {
      type: Object,
      default: function _default() {}
    }
  },
  components: {
    tableTemple: tableTemple
  },
  data: function data() {
    return {
      columnUser: [{
        label: '用户ID',
        prop: 'id',
        width: 80
      }, {
        label: '智慧店ID',
        prop: 'storeId',
        width: 80
      }, {
        label: '用户名称',
        prop: 'name',
        width: 180
      }, {
        label: '手机号码',
        prop: 'mobile',
        width: 150
      }, {
        label: '用户等级',
        prop: 'level',
        width: 120
      }],
      columnGoods: [{
        label: '商品ID',
        prop: 'goods_id',
        width: 80
      }, {
        label: '商品规格',
        prop: 'sku_name',
        width: 120
      }, {
        label: '商品价格',
        prop: 'price',
        width: 160
      }, {
        label: '商品供货价',
        prop: 'cost_price',
        width: 120
      }, {
        label: '活动底价',
        prop: 'reserve_price'
      }, {
        label: '商品库存',
        prop: 'inventory',
        width: 120
      }, {
        label: '集赞数量',
        prop: 'like_count',
        width: 120
      }, {
        label: '会员立省',
        prop: 'merchant_price',
        width: 120
      }, {
        label: '销售管理奖',
        prop: 'sales_bonus',
        width: 120
      }]
    };
  },
  computed: {
    isShowDetailDialog: {
      get: function get() {
        return this.detailDialogShow;
      },
      set: function set() {}
    }
  },
  watch: {},
  methods: {
    close: function close() {
      this.$emit('closeDetailDialog');
    }
  },
  mounted: function mounted() {// console.log(this.detailInfo)
  }
};