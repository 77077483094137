var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-box" }, [
    _c("div", { staticClass: "select-box flex align-center mb-30" }, [
      _c("div", { staticClass: "label color-333 font-size-9" }, [
        _vm._v("运营状态：")
      ]),
      _c(
        "div",
        { staticClass: "flex-sub" },
        [
          _c(
            "el-select",
            {
              staticClass: "input-width-100",
              attrs: { size: "mini" },
              on: { change: _vm.optionChange },
              model: {
                value: _vm.selRunStatus,
                callback: function($$v) {
                  _vm.selRunStatus = $$v
                },
                expression: "selRunStatus"
              }
            },
            _vm._l(_vm.categoryOptions, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id }
              })
            }),
            1
          )
        ],
        1
      )
    ]),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "echart-box" }, [
      _c("div", {
        staticStyle: { width: "100%", height: "100%" },
        attrs: { id: "myEchar" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }