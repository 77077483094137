var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { class: { "page-compact": _vm.crud.pageOptions.compact } },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("crud-search", {
            ref: "search",
            attrs: { options: _vm.crud.searchOptions },
            on: { submit: _vm.handleSearch }
          }),
          _c(
            "el-button-group",
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.addRow }
                },
                [_c("i", { staticClass: "el-icon-plus" }), _vm._v(" 新增")]
              )
            ],
            1
          ),
          _c("crud-toolbar", {
            attrs: {
              search: _vm.crud.searchOptions.show,
              compact: _vm.crud.pageOptions.compact,
              columns: _vm.crud.columns
            },
            on: {
              "update:search": function($event) {
                return _vm.$set(_vm.crud.searchOptions, "show", $event)
              },
              "update:compact": function($event) {
                return _vm.$set(_vm.crud.pageOptions, "compact", $event)
              },
              refresh: function($event) {
                return _vm.doRefresh()
              },
              "columns-filter-changed": _vm.handleColumnsFilterChanged
            }
          })
        ],
        1
      ),
      _c(
        "d2-crud-x",
        _vm._g(
          _vm._b(
            {
              ref: "d2Crud",
              scopedSlots: _vm._u([
                {
                  key: "goods_idFormSlot",
                  fn: function(scope) {
                    return [
                      _c(
                        "div",
                        [
                          _c("el-input", {
                            staticClass: "goodInput",
                            attrs: {
                              disabled: scope.mode === "edit",
                              placeholder: "输入商品ID"
                            },
                            on: {
                              blur: function($event) {
                                return _vm.goodInputBlur(scope.form["goods_id"])
                              }
                            },
                            model: {
                              value: scope.form["goods_id"],
                              callback: function($$v) {
                                _vm.$set(scope.form, "goods_id", $$v)
                              },
                              expression: "scope.form['goods_id']"
                            }
                          }),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                disabled: scope.mode === "edit",
                                size: "small"
                              },
                              on: { click: _vm.selPro }
                            },
                            [_vm._v("选择商品")]
                          )
                        ],
                        1
                      ),
                      _c("div", { staticStyle: { color: "#606266" } }, [
                        _vm._v("商品名称：" + _vm._s(_vm.goodsName || "暂无"))
                      ])
                    ]
                  }
                }
              ])
            },
            "d2-crud-x",
            _vm._crudProps,
            false
          ),
          _vm._crudListeners
        )
      ),
      _c("selProDialog", {
        attrs: { selProDialogShow: _vm.selProDialogShow },
        on: {
          closeSelProDialog: _vm.closeSelProDialog,
          selHrefPro: _vm.selHrefPro
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }