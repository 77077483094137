//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tableTemple from './table.vue';
export default {
  props: {
    detailDialogShow: {
      type: Boolean,
      required: false
    },
    detailInfo: {
      type: Object,
      default: function _default() {}
    }
  },
  components: {
    tableTemple: tableTemple
  },
  data: function data() {
    return {
      columnUser: [{
        label: '用户ID',
        prop: 'id',
        width: 80
      }, {
        label: '用户名称',
        prop: 'name',
        width: 180
      }, {
        label: '手机号码',
        prop: 'mobile',
        width: 150
      }, {
        label: '用户等级',
        prop: 'level',
        width: 120
      }],
      columnOrder: [{
        label: '用户ID',
        prop: 'id',
        width: 80
      }, {
        label: '用户名称',
        prop: 'name',
        width: 120
      }, {
        label: '订单号',
        prop: 'order_sn',
        width: 160
      }, {
        label: '商品ID',
        prop: 'goods_id',
        width: 120
      }, {
        label: '商品名称',
        prop: 'goods_name'
      }, {
        label: '订单金额',
        prop: 'price',
        width: 120
      }, {
        label: '订单状态',
        prop: 'status',
        width: 120
      }],
      columnRecord: [{
        label: '用户ID',
        prop: 'user_id',
        width: 80
      }, {
        label: '用户名称',
        prop: 'name',
        width: 120
      }, {
        label: '减掉金额',
        prop: 'deduct_price',
        width: 120
      }, {
        label: '剩余金额',
        prop: 'residual_price',
        width: 120
      }, {
        label: '创建时间',
        prop: 'created_at'
      }]
    };
  },
  computed: {
    isShowDetailDialog: {
      get: function get() {
        return this.detailDialogShow;
      },
      set: function set() {}
    }
  },
  watch: {},
  methods: {
    close: function close() {
      this.$emit('closeDetailDialog');
    },
    statusChange: function statusChange(status) {
      var text;

      switch (status) {
        case 0:
          text = '集赞中';
          break;

        case 1:
          text = '集赞成功';
          break;

        case 2:
          text = '集赞失败';
          break;

        case 3:
          text = '集赞取消';
          break;

        case 4:
          text = '集赞失效';
          break;

        case 5:
          text = '下单成功';
          break;
      }

      return text;
    }
  },
  mounted: function mounted() {}
};