export default {
  imageActionName: 'upload-image',
  imageUrl: 'https://upload-z2.qiniup.com/',
  imageFieldName: 'file',
  imageMaxSize: 2097152,
  imageAllowFiles: ['.png', '.jpg', '.jpeg', '.gif', '.bmp'],
  imageCompressEnable: true,
  imageCompressBorder: 1600,
  imageInsertAlign: 'none',
  imageUrlPrefix: 'https://image.v1.vodeshop.com/',
  imagePathFormat: '/uploads/image/{yyyy}/{mm}/{dd}/',
  scrawlActionName: 'upload-scrawl',
  scrawlFieldName: 'upfile',
  scrawlPathFormat: '/uploads/image/{yyyy}/{mm}/{dd}/',
  scrawlMaxSize: 2048000,
  scrawlUrlPrefix: '',
  scrawlInsertAlign: 'none',
  snapscreenActionName: 'upload-image',
  snapscreenPathFormat: '/uploads/image/{yyyy}/{mm}/{dd}/',
  snapscreenUrlPrefix: '',
  snapscreenInsertAlign: 'none',
  catcherLocalDomain: ['127.0.0.1', 'localhost', 'img.baidu.com'],
  catcherActionName: 'catch-image',
  catcherFieldName: 'source',
  catcherPathFormat: '/uploads/image/{yyyy}/{mm}/{dd}/',
  catcherUrlPrefix: '',
  catcherMaxSize: 2048000,
  catcherAllowFiles: ['.png', '.jpg', '.jpeg', '.gif', '.bmp'],
  videoActionName: 'upload-video',
  videoFieldName: 'upfile',
  videoPathFormat: '/uploads/video/{yyyy}/{mm}/{dd}/',
  videoUrlPrefix: '',
  videoMaxSize: 102400000,
  videoAllowFiles: ['.flv', '.swf', '.mkv', '.avi', '.rm', '.rmvb', '.mpeg', '.mpg', '.ogg', '.ogv', '.mov', '.wmv', '.mp4', '.webm', '.mp3', '.wav', '.mid'],
  fileActionName: 'upload-file',
  fileFieldName: 'upfile',
  filePathFormat: '/uploads/file/{yyyy}/{mm}/{dd}/',
  fileUrlPrefix: '',
  fileMaxSize: 51200000,
  fileAllowFiles: ['.png', '.jpg', '.jpeg', '.gif', '.bmp', '.flv', '.swf', '.mkv', '.avi', '.rm', '.rmvb', '.mpeg', '.mpg', '.ogg', '.ogv', '.mov', '.wmv', '.mp4', '.webm', '.mp3', '.wav', '.mid', '.rar', '.zip', '.tar', '.gz', '.7z', '.bz2', '.cab', '.iso', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.pdf', '.txt', '.md', '.xml'],
  imageManagerActionName: 'list-image',
  imageManagerListPath: '/uploads/image/',
  imageManagerListSize: 20,
  imageManagerUrlPrefix: '',
  imageManagerInsertAlign: 'none',
  imageManagerAllowFiles: ['.png', '.jpg', '.jpeg', '.gif', '.bmp'],
  fileManagerActionName: 'list-file',
  fileManagerListPath: '/uploads/file/',
  fileManagerUrlPrefix: '',
  fileManagerListSize: 20,
  fileManagerAllowFiles: ['.png', '.jpg', '.jpeg', '.gif', '.bmp', '.flv', '.swf', '.mkv', '.avi', '.rm', '.rmvb', '.mpeg', '.mpg', '.ogg', '.ogv', '.mov', '.wmv', '.mp4', '.webm', '.mp3', '.wav', '.mid', '.rar', '.zip', '.tar', '.gz', '.7z', '.bz2', '.cab', '.iso', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.pdf', '.txt', '.md', '.xml']
};