import { request } from '@/api/service';
export default {
  /**
   * 热度明细列表接口
   * http://doc.vodeshop.com/web/#/34?page_id=2960
   * @param {*} data
   */
  getHotList: function getHotList(params) {
    return request({
      url: '/svc/marketing-svc/admin/v1/give-likes-store/heat-logs',
      method: 'get',
      params: params
    });
  }
};