// 状态字典
export var DICT_STATUS = [{
  value: 1,
  label: '启用'
}, {
  value: 0,
  label: '禁用'
}];
export var LIMIT_STATUS = [{
  name: 'SAVER会员',
  id: 'mall_1'
}, {
  name: '三星',
  id: 'mall_2'
}, {
  name: '五星',
  id: 'mall_3'
}, {
  name: '七星',
  id: 'mall_4'
}];